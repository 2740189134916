@charset "utf-8";

// 「中小企業向けコラム（一覧ページ）」のスタイルです。


// ---------▼▼ 13「中小企業向けコラム（一覧ページ）」archive-column ▼▼----------

.PageArchiveColumn {

	@include sp {
	};
	@include pc {
	};

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// -----▼ 「中小企業向けコラム」一覧 ▼-----
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	.Blog__post .sectionContainer {
		@include sp {
			padding: 0 0 64px !important;
		};
		@include pc {
			padding: 0 0 80px !important;
		};
	}
	// -----▲ 「中小企業向けコラム」一覧 ▲-----

	// -----▼ 「中小企業向けコラム」のレコメンド ▼-----
	.Rec-Column {
		@include sp {
		};
		@include pc {
		};
	}

	// ※コラムのレコメンドのスタイルは「_recommend-column.scss」に記述

	// -----▲ 「中小企業向けコラム」のレコメンド ▲-----

}

// ---------▲▲ 13「中小企業向けコラム（一覧ページ）」archive-column ▲▲----------
