@charset "utf-8";

// 各種「検索フォーム」の共通パーツのスタイルです。


// ----------▼▼ 「検索フォーム」の共通パーツのスタイル ▼▼----------

// -----▼ 検索フォームの共通スタイル ▼-----
// 検索フォーム
.form-search {
	background-color: $gray_f4;
	@include sp {
		padding: 20px 12px 16px;
	};
	@include pc {
		padding: 24px 16px 16px;
	};
	// フォームのヘッダー
	.form-header {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのヘッダーのタイトル
	.form-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-title + .form-text {
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	// フォームのヘッダーの文章
	.form-text {
		@include sp {
			font-size: 1.4rem;
			line-height: 1.6;
		};
		@include pc {
			font-size: 1.4rem;
			line-height: 1.6;
		};
	}
	// フォームのセクション間のマージン
	.form-header + .form-section.-input,
	.form-header + .form-section.-submit {
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	// フォームのセクション「入力欄」
	.form-section.-input {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのセクション「送信ボタン」
	.form-section.-submit {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のリスト
	.form-list {
		@include sp {
		};
		@include pc {
		};
	}

	.form-input-list.-layer1 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer2 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer3 {
		@include sp {
		};
		@include pc {
		};
	}

	// 個々の入力項目
	.form-input-list-item {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のタイトル
	.form-list-item-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-title-label {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄
	.form-list-item-data {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-data-content {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（単文）
	.form-list-item-data-content.-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（テキストエリア）
	.form-list-item-data-content.-textarea {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（セレクトボックス）
	.form-list-item-data-content.-select {
		@include sp {
		};
		@include pc {
		};
	}
	// セレクトボックスのラッパー（アイコン配置用）
	.form-list-item-data-select-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ラジオボタン）
	.form-list-item-data-content.-radio {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（チェックボックス）
	.form-list-item-data-content.-checkbox {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスのlabel部分
	.form-list-item-data-label {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスの文言（sapn）部分
	.form-list-item-data-label-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（日付のカレンダー入力）
	.form-list-item-data-content.-calendar {
		@include sp {
		};
		@include pc {
		};
	}
	// 日付のカレンダー入力のラッパー（アイコン配置用）
	.form-list-item-data-calendar-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ファイル）
	.form-list-item-data-content.-file {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの記入例
	.form-list-item-data-example {
		@include sp {
		};
		@include pc {
		};
	}
	// フォーム内の注意書き
	.form-list-item-data-note {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン
	.form-btn {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン「検索」ボタン（検索フォーム用）
	.form-btn.-searchSubsidyAndColumn {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン「補助金・助成金を検索」ボタン（検索フォーム用）
	.form-btn.-formContact {
		@include sp {
		};
		@include pc {
		};
	}

	// ※フォームの送信ボタンそのもののスタイルは「_button.scss」に記述

}
// -----▲ 検索フォームの共通スタイル ▲-----

// -----▼ フォームの種類 ▼-----
// 検索フォーム「補助金・助成金検索」
.form.form-search.-subsidy {
	@include sp {
	};
	@include pc {
	};
}
// 検索フォーム「キーワード検索」
.form.form-search.-keyword {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ フォームの種類 ▲-----

// -----▼ 検索フォーム「補助金・助成金検索」の共通スタイル ▼-----
.form-search.-subsidy .form-section.-input {
	@include sp {
	};
	@include pc {
	};
	// 「補助金・助成金検索」では項目間をグレーの線で区切る
	.form-list.-layer1 {
		@include sp {
			border-bottom: 1px solid $gray_c;
			border-top: 1px solid $gray_c;
		};
		@include pc {
			border-bottom: 1px solid $gray_c;
			border-top: 1px solid $gray_c;
		};
	}
	.form-list.-layer1 > .form-list-item + .form-list-item {
		@include sp {
			border-top: 1px solid $gray_c;
		};
		@include pc {
			border-top: 1px solid $gray_c;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-title {
		@include sp {
			padding: 12px 0 12px;
		};
		@include pc {
			padding: 16px 0 16px;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-data {
		@include sp {
			margin-top: 0;
		};
		@include pc {
			margin-top: 0;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-data {
		@include sp {
			padding-bottom: 12px;
		};
		@include pc {
			padding-bottom: 16px;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-title.js_toggleBtn {
		display: flex;
		@include sp {
		};
		@include pc {
			cursor: pointer;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-title.js_toggleBtn {
		@include sp {
			margin: 0 -12px 0 !important;
			padding: 12px 12px 12px;
		};
		@include pc {
			margin: 0 -16px 0 !important;
			padding: 16px 16px 16px;
		};
	}
	.form-list.-layer1 > .form-list-item > .form-list-item-data.js_toggleContent {
		@include sp {
			margin: 0 -12px 0 !important;
			padding: 0 12px 12px;
		};
		@include pc {
			margin: 0 -16px 0 !important;
			padding: 0 16px 16px;
		};
	}
	// ホバー時の効果
	.form-list.-layer1 > .form-list-item > .form-list-item-title.js_toggleBtn {
		background-color: $gray_f4;
		transition: background-color .3s;
		& + .form-list-item-data {
			background-color: $gray_f4;
			transition: background-color .3s;
		}
		@include sp {
			&:active {
				background-color: $gray_e6;
				transition: background-color .1s;
				& + .form-list-item-data {
					background-color: $gray_e6;
					transition: background-color .1s;
				}
			}
		};
		@include pc {
			&:hover {
				background-color: $gray_e6;
				transition: background-color .1s;
				& + .form-list-item-data {
					background-color: $gray_e6;
					transition: background-color .1s;
				}
			}
		};
	}
	.form-list-item-title.js_toggleBtn .form-list-item-title-label {
		@include sp {
			width: calc(100% - 48px);
		};
		@include pc {
			cursor: pointer;
			width: calc(100% - 56px);
		};
	}
	.form-list-item-title.js_toggleBtn .btn-accordion {
		@include sp {
			margin-left: 16px;
			margin-right: 8px;
		};
		@include pc {
			margin-left: 24px;
			margin-right: 8px;
		};
	}
	.form-list-item.-placePrefectures .form-list-item-data-select-wrap,
	.form-list-item.-subsidyAmount .form-list-item-data-select-wrap {
		@include sp {
		};
		@include pc {
			width: calc(calc(100% - 40px) / 2);
		};
	}
	// 「公募受付期間」
	.form-list-item.-application {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-application .form-list-item-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-application .form-list-item-data {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-application .form-list.-layer2 {
		display: flex;
		flex-direction: column-reverse;
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-application .form-list.-layer2 .form-list-item-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-application .form-list.-layer2 .form-list-item-title .form-list-item-title-label {
		@include sp {
			font-size: 1.2rem;
		};
		@include pc {
			font-size: 1.2rem;
		};
	}
	.form-list-item.-application .form-list.-layer2 .form-list-item-data {
		@include sp {
		};
		@include pc {
		};
	}
	// 「締め切りを過ぎた補助金の情報も含めてすべて表示する。」と「年月日」
	.form-list-item.-searchAllSubsidy,
	.form-list-item.-applicationEndDate {
		@include sp {
		};
		@include pc {
		};
	}
	// 「締め切りを過ぎた補助金の情報も含めてすべて表示する。」
	.form-list-item.-searchAllSubsidy {
		@include sp {
			padding: 28px 0 24px;
		};
		@include pc {
			padding: 32px 0 24px;
		};
	}
	.form-list-item.-searchAllSubsidy > .form-list-item-title + .form-list-item-data {
		@include sp {
			margin-top: 0px;
		};
		@include pc {
			margin-top: 0px;
		};
	}
	// 「締め切りを過ぎた補助金の情報も含めてすべて表示する。」が内容している入力項目「日付のカレンダー入力」
	.form-list-item.-searchAllSubsidy .form-list-item-data-calendar-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 「年月日」
	.form-list-item.-applicationEndDate {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item.-applicationEndDate .form-list-item-data {
		@include sp {
		};
		@include pc {
			align-items: center;
			display: flex;
			margin-left: -24px !important;
		};
	}
	.form-list-item.-applicationEndDate .form-list-item-data .form-list-item-data-calendar-wrap,
	.form-list-item.-applicationEndDate .form-list-item-data .form-list-item-data-content-note {
		@include sp {
		};
		@include pc {
			margin-left: 24px;
			width: calc(calc(100% - 40px) / 2);
		};
	}
	// 「まで公募を受け付けている補助金を探す。」
	.form-list-item.-applicationEndDate .form-list-item-data .form-list-item-data-content-note {
		@include sp {
			font-size: 1.4rem;
			line-height: 1.6;
			margin-top: 8px;
		};
		@include pc {
		};
	}
}
// フォームの送信ボタン「補助金・助成金を検索」ボタン
.form-search.-subsidy .form-section.-submit {
	@include sp {
		margin-top: 20px;
	};
	@include pc {
		margin-top: 16px;
	};
}
// -----▲ 検索フォーム「補助金・助成金検索」の共通スタイル ▲-----

// -----▼ 検索フォーム「キーワード検索」の共通スタイル ▼-----
.form-search.-keyword {
	@include sp {
	};
	@include pc {
	};
	.form-input-and-submit {
		display: flex;
		position: relative;
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	.form-section.-input {
		@include sp {
			margin-top: 0;
			width: calc(100% - 47px);
		};
		@include pc {
			margin-top: 0;
			width: calc(100% - 79px);
		};
	}
	.form-section.-input .form-list-item.-keyword .form-list-item-data {
		@include sp {
			padding-bottom: 0;
		};
		@include pc {
			padding-bottom: 0;
		};
	}
	.form-section.-input .form-list-item.-keyword .form-list-item-data-content {
		@include sp {
			border-radius: 4px 0 0 4px;
			padding-right: 36px;
		};
		@include pc {
			border-radius: 4px 0 0 4px;
			padding-right: 36px;
		};
	}
	// 「クリアボタン（.btn-clear）」のレイアウト
	.form-section.-input .form-list-item.-keyword .form-list-item-data {
		position: relative;
		@include sp {
		};
		@include pc {
		};
	}
	.form-section.-input .form-list-item.-keyword .form-list-item-data .form-btn.-clear {
		position: absolute;
		@include sp {
			right: 10px;
			top: calc(50% - 11px);
		};
		@include pc {
			right: 8px;
			top: calc(50% - 13px);
		};
	}

	// ※フォーム「キーワード検索」用の「クリアボタン（.btn-clear）」のスタイルは「_button.scss」に記述

	.form-section.-submit {
		position: absolute;
		@include sp {
			margin-top: 0;
			right: 0;
			top: 0;
		};
		@include pc {
			margin-top: 0;
			right: 0;
			top: 0;
		};
	}
}
// -----▲ 検索フォーム「キーワード検索」の共通スタイル ▲-----

// ----------▲▲ 「検索フォーム」の共通パーツのスタイル ▲▲----------
