@charset "utf-8";

// 「バナー」のスタイルのテンプレートです。


// ---------▼▼ 「バナー」のスタイル ▼▼----------

// ----▼ 「バナー」の基本スタイル ▼-----
.bnr {
	@include hoverLight;
	display: block;
	@include sp {
		height: 100%;
		margin: 0 auto;
		width: 100%;
	};
	@include pc {
		height: 100%;
		margin: 0 auto;
		width: max-content;
	};
}
// ----▲ 「バナー」の基本スタイル ▲-----

// ----▼ 「リンクのないバナー」の基本スタイル ▼-----
.bnr.-noLink {
	pointer-events: none;
	@include sp {
	};
	@include pc {
	};
}
// ----▲ 「リンクのないバナー」の基本スタイル ▲-----

// ---------▲▲ 「バナー」のスタイル ▲▲----------
