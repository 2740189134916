@charset "utf-8";

// マルチクラスが前提の、汎用的なスタイルを定義する。
// フォントサイズやアイコンのベーススタイルなど、
// それ単体では何も形をなさない、「パーツ未満」の汎用スタイルをここに書く。


// ----------▼▼ hide ▼▼----------

// -----▼ class ▼-----
// ALL
.hideAll {display: none !important;}
// SP
@include sp {
	.hideSp {display: none !important;}
}
// PC
@include pc {
	.hidePc {display: none !important;}
}
// -----▲ class ▲-----
// ----------▲▲ hide ▲▲----------


// ----------▼▼ .visuallyhidden ▼▼----------

// 「見た目非表示」のコードスニペット。
// スクリーンリーダーや検索エンジンのロボットからはコンテンツを隠さない。
// 要素としては存在させたいが、
// ユーザーには視覚的に見えないようにしたいという場合に使用する。
// フォームのinputを隠す際に使う。

// -----▼ class ▼-----
.visuallyHidden {
	border: 0;
	clip-path: inset(50%);
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin visuallyHidden {
	border: 0;
	clip-path: inset(50%);
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
// -----▲ @mixin ▲-----

// ----------▲▲ .visuallyhidden ▲▲----------


// ----------▼▼ .telLink ▼▼----------

// 電話番号リンクのラッパークラス。
// スマホではタップして電話をかけられるが、PCではただのテキストにしたい場合に使う。
// 電話番号のa要素の親要素としてこのクラスを当てること。

// -----▼ class ▼-----
.telLink {
	display: inline-block;
	@include pc {
		cursor: text;
	};
}
// -----▲ class ▲-----

// ----------▲▲ .telLink ▲▲----------


// ----------▼▼ font-family ▼▼----------

// ----------▲▲ font-family ▲▲----------


// ----------▼▼ font-size ▼▼----------

// ----------▲▲ font-size ▲▲----------


// ----------▼▼ font-weight ▼▼----------

// -----▼ class ▼-----
.bold {font-weight: bold;}
.medium {font-weight: $medium;}
// -----▲ class ▲-----

// ----------▲▲ font-weight ▲▲----------


// ----------▼▼ font-color ▼▼----------

// -----▼ class ▼-----
.red {color: $red;}
// -----▲ class ▲-----

// -----▼ class（『Gutenberg』用の文字色指定） ▼-----
.has-black-color {color: $black !important;}
.has-gray-color {color: $gray_7;}
.has-white-color {color: $white !important;}
.has-blue-color {color: $blue;}
// -----▲ class（『Gutenberg』用の文字色指定） ▲-----

// ----------▲▲ font-color ▲▲----------


// ----------▼▼ background-image-size ▼▼----------
@mixin bgFitCov {background-position: center;background-repeat: no-repeat;background-size: cover;}
@mixin bgFitCnt {background-position: center;background-repeat: no-repeat;background-size: contain;}
// ----------▲▲ background-image-size ▲▲----------


// ----------▼▼ background-color ▼▼----------

// -----▼ class ▼-----
.bg-section {
}
.bg-section.-white {
	background-color: $white;
}
.bg-section.-gray {
	background-color: $gray_f8;
}
.bg-section.-blue {
	background-color: $blue;
}
.bg-section.-blue_12pct {
	background-color: $blue_12pct;
}
.bg-section.-whiteCutoff {
	position: relative;
	z-index: 0;
	&::after {
		background-color: $white;
		content: "";
		position: absolute;
		z-index: -1;
	}
}
.bg-section.bg-img {
	@include bgFitCov;
}
.bg-section.-imageCutoff {
	position: relative;
	z-index: 0;
	&::after {
		@include bgFitCov;
		content: "";
		position: absolute;
		z-index: -1;
	}
}
// -----▲ class ▲-----

// ----------▲▲ background-color ▲▲----------


// ----------▼▼ letter-spacing ▼▼----------

@mixin ls($ls: 0) {
	letter-spacing: $ls;
}
// -----▼ class ▼-----
// SP
@include sp {
	.lsAV50,.lsAV50Sp {@include ls($lsAV50);}
	.lsAV80,.lsAV80Sp {@include ls($lsAV80);}
	.lsAV100,.lsAV100Sp {@include ls($lsAV100);}
	.lsAV150,.lsAV150Sp {@include ls($lsAV150);}
	.lsAV200,.lsAV200Sp {@include ls($lsAV200);}
}
// PC
@include pc {
	.lsAV50,.lsAV50Pc {@include ls($lsAV50);}
	.lsAV80,.lsAV80Pc {@include ls($lsAV80);}
	.lsAV100,.lsAV100Pc {@include ls($lsAV100);}
	.lsAV150,.lsAV150Pc {@include ls($lsAV150);}
	.lsAV200,.lsAV200Pc {@include ls($lsAV200);}
}
// -----▲ class ▲-----

// ----------▲▲ letter-spacing ▲▲----------


// ----------▼▼ text-align ▼▼----------

// -----▼ class ▼-----
// common
.taL {text-align: left !important;}
.taC {text-align: center !important;}
.taR {text-align: right !important;}
// SP
@include sp {
	.taLSp {text-align: left !important;}
	.taCSp {text-align: center !important;}
	.taRSp {text-align: right !important;}
}
// PC
@include pc {
	.taLPc {text-align: left !important;}
	.taCPc {text-align: center !important;}
	.taRPc {text-align: right !important;}
}
// -----▲ class ▲-----

// ----------▲▲ text-align ▲▲----------


// ----------▼▼ cleartext ▼▼----------

// -----▼ class ▼-----
.clearText {
	display: inline-block;
	overflow: hidden;
	text-indent: 500%;
	white-space: nowrap;
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin clearText {
	display: inline-block;
	overflow: hidden;
	text-indent: 500%;
	white-space: nowrap;
}
// -----▲ @mixin ▲-----

// ----------▲▲ cleartext ▲▲----------


// ----------▼▼ icon ▼▼----------

// -----▼ class ▼-----
.icon {
	position: relative;
	&::before,
	&::after {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: "";
		position: absolute;
	}
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin icon {
	position: relative;
	&::before,
	&::after {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: "";
		position: absolute;
	}
}
// -----▲ @mixin ▲-----

// backgroundをショートハンドでまとめると
// スタイルの競合が発生してしまうので
// プロパティはすべて分けて記述する。
// 例）background-imageが効かない、など。

// ----------▲▲ icon ▲▲----------


// ----------▼▼ mark ▼▼----------

// -----▼ @mixin ▼-----
@mixin mark {
	position: relative;
	&::before,
	&::after {
		content: "";
		position: absolute;
	}
}
// -----▲ @mixin ▲-----

// ----------▲▲ mark ▲▲----------


// ----------▼▼ logo ▼▼----------

// -----▼ class ▼-----
.logo {
	@extend .icon;
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin logo {
	@include icon;
}
// -----▲ @mixin ▲-----

// ----------▲▲ logo ▲▲----------


// ----------▼▼ ilst ▼▼----------

// -----▼ class ▼-----
.ilst {
	@extend .icon;
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin ilst {
	@include icon;
}
// -----▲ @mixin ▲-----

// ----------▲▲ ilst ▲▲----------


// ----------▼▼ typo ▼▼----------

// -----▼ class ▼-----
.typ {
	@extend .icon;
}
// -----▲ class ▲-----

// -----▼ @mixin ▼-----
@mixin typ {
	@include icon;
}
// -----▲ @mixin ▲-----

// ----------▲▲ typo ▲▲----------


// ----------▼▼ drop-shadow ▼▼----------
// -----▼ @mixin ▼-----
// 影を落とす
@mixin shadow {
	@include sp {
		box-shadow: 0px 3px 10px rgba($black_shadow, .1);
	};
	@include pc {
		box-shadow: 0px 3px 10px rgba($black_shadow, .1);
	};
}
// -----▲ @mixin ▲-----
// ----------▲▲ drop-shadow ▲▲----------


// ----------▼▼ drop-shadow(card) ▼▼----------
// -----▼ @mixin ▼-----
// 影を落とす＆ホバー時に影が少し弱くなる
@mixin shadowCard {
	transition: box-shadow .3s;
	@include sp {
		box-shadow: 0px 3px 10px rgba($black_shadow, .1);
		&:active {
			box-shadow: 0px 2px 5px rgba($black_shadow, .05);
			transition: box-shadow .1s;
		}
	};
	@include pc {
		box-shadow: 0px 3px 10px rgba($black_shadow, .1);
		&:hover {
			box-shadow: 0px 2px 5px rgba($black_shadow, .05);
			transition: box-shadow .1s;
		}
	};
}
// -----▲ @mixin ▲-----
// ----------▲▲ drop-shadow(card) ▲▲----------


// ----------▼▼ hover-shadow ▼▼----------
// -----▼ @mixin ▼-----
// 影を被せる
@mixin hoverShadow {
	position: relative;
	&::before {
		background: rgba($black, 0);
		content: "";
		cursor: pointer;
		height: inherit;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: .3s;
		width: 100%;
		z-index: 1;
	}
	@include sp {
		&:active::before {
			background: rgba($black, .2);
			transition: .1s;
		}
	};
	@include pc {
		&:hover::before {
			background: rgba($black, .2);
			transition: .1s;
		}
	};
}
// -----▲ @mixin ▲-----
// ----------▲▲ hover-shadow ▲▲----------


// ----------▼▼ hover-light ▼▼----------
// -----▼ @mixin ▼-----
// 光を被せる
@mixin hoverLight {
	position: relative;
	&::before {
		background: rgba($white, 0);
		content: "";
		cursor: pointer;
		height: inherit;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: .3s;
		width: 100%;
		z-index: 1;
	}
	@include sp {
		&:active::before {
			background: rgba($white, .3);
			transition: .05s;
		}
	};
	@include pc {
		&:hover::before {
			background: rgba($white, .3);
			transition: .05s;
		}
	};
}
// -----▲ @mixin ▲-----
// ----------▲▲ hover-light ▲▲----------


// ----------▼▼ hover-thumbnail ▼▼----------
// -----▼ @mixin ▼-----
// 【ホバー時】画像にオレンジ色のレイヤーを被せる＆画像を少し拡大する
@mixin hoverBeforeThumbnail {
	overflow: hidden;
	position: relative;
	z-index: 0;
	&::before {
		background-color: $ghost;
		content: "";
		opacity: .8;
		position: absolute;
		transition: background-color .3s;
		z-index: 1;
	}
	@include sp {
		&::before {
			height: inherit;
			left: 0;
			top: 0;
			width: inherit;
		}
	};
	@include pc {
		&::before {
			height: inherit;
			left: 0;
			top: 0;
			width: inherit;
		}
	};
}
@mixin hoverBeforeThumImg {
	transform: scale(1);
	transition: transform .6s;
	@include sp {
	};
	@include pc {
	};
}
@mixin hoverAfterThumbnail {
	background-color: $orange;
	transition: background-color .3s;
}
@mixin hoverAfterThumImg {
	transform: scale(1.1);
	transition: transform .6s;
}
// -----▲ @mixin ▲-----
// ----------▲▲ hover-thumbnail ▲▲----------

// ----------▼▼ hover-ghost ▼▼----------
// -----▼ @mixin ▼-----
// 透過させる
@mixin hoverGhost {
	transition: .3s;
	@include sp {
		&:active {
			opacity: .7;
			transition: .1s;
		}
	};
	@include pc {
		&:hover {
			opacity: .7;
			transition: .1s;
		}
	};
}
// -----▲ @mixin ▲-----
// ----------▲▲ hover-ghost ▲▲----------
