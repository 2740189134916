@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 入力項目「ファイル」


// ----------▼▼ 入力項目「ファイル」のスタイル ▼▼----------

// ファイル
// 入力欄（ファイル）
.form-list-item-data-content.-file {
	@include sp {
	};
	@include pc {
	};
}
@include ie {
	*::-ms-backdrop, .form-list-item-data-content.-file {
		width: 100%;
	}
}

// ----------▲▲ 入力項目「ファイル」のスタイル ▲▲----------
