@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 「フォームの記入例」


// ----------▼▼ 「フォームの記入例」のスタイル ▼▼----------

// フォームの記入例
.form-list-item-data-example {
	color: $gray_7;
	@include sp {
		font-size: 1.2rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		line-height: 1.5;
	};
}
.form-list-item-data-example::before {
	content: "例）";
	font-size: inherit;
	@include sp {
	};
	@include pc {
	};
}

// ----------▲▲ 「フォームの記入例」のスタイル ▲▲----------
