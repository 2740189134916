@charset "utf-8";

// 「フッター」の「フッターコンテンツ」のスタイルです。


// ----------▼▼ フッターコンテンツ .Footer_content ▼▼----------

.Footer_content {
	@include sp {

	};
	@include pc {
	};
}
.Footer_content .sectionContainer {
	background-color: $black_3;
	@include sp {
		padding: 32px 0 24px;
	};
	@include pc {
		padding: 48px 0 24px;
	};
}
.FtContent__contentContent {
	@include sp {
	};
	@include pc {
	};
}

// -----▼ バナー ▼-----
.FtContent__contentBanner {
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBannerList {
	align-items: center;
	display: flex;
	justify-content: center;
	@include sp {
		flex-direction: column;
		gap: 16px;
	};
	@include pc {
		gap: 24px;
	};
}
.FtContent__contentBannerListItem {
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBannerPicture {
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBannerLink {
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBannerImg {
	@include sp {
		height: 52px;
		width: 200px;
	};
	@include pc {
		height: 52px;
		width: 200px;
	};
}
// -----▲ バナー ▲-----

// -----▼ サービスの情報 ▼-----
.FtContent__contentBanner + .FtContent__contentBrandInfo {
	@include sp {
		margin-top: 32px;
	};
	@include pc {
		margin-top: 40px;
	};
}
.FtContent__contentBrandInfo {
	@include sp {
	};
	@include pc {
	};
}
// ロゴ
.FtContent__contentBrandLogo {
	@include sp {
	};
	@include pc {
	};
}
.FtC-Ca__brandLogoLink {
	display: block;
	font-size: 0;
	@include sp {
		height: 32px;
		margin: 0 auto;
		transition: .3s;
		width: 70px;
		&:active {
			opacity: .7;
			transition: .05s;
		}
	};
	@include pc {
		height: 32px;
		margin: 0 auto;
		transition: .3s;
		width: 70px;
		&:hover {
			opacity: .7;
			transition: .05s;
		}
	};
}
.FtC-Ca__brandLogo {
	@include sp {
	};
	@include pc {
	};
}
.FtC-Ca__brandLogo .logo-melodyRecords {
	@include clearText;
	display: block;
	font-size: 0;
	@include sp {
		height: 32px;
		width: 70px;
	};
	@include pc {
		height: 32px;
		width: 70px;
	};
}
.FtC-Ca__brandLogo .logo-melodyRecords::before {
	left: 0;
	top: 0;
	@include sp {
		height: 32px;
		width: 70px;
	};
	@include pc {
		height: 32px;
		width: 70px;
	};
}
// サービス名
.FtC-Ca__brandLogoLink + .FtC-Ca__brandNameLink {
	@include sp {
		margin-top: 4px;
	};
	@include pc {
		margin-top: 4px;
	};
}
.FtC-Ca__brandNameLink {
	display: block;
	text-align: center;
	@include sp {
	};
	@include pc {
	};
}
.FtC-Ca__brandName {
	color: $white;
	@include sp {
		font-size: 1rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// 住所
.FtContent__contentBrandLogo + .FtContent__contentBrandAddress {
	@include sp {
		margin-top: 16px;
	};
	@include pc {
		margin-top: 16px;
	};
}
.FtContent__contentBrandAddress {
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBrandAddressText {
	color: $white;
	text-align: center;
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.FtContent__contentBrandAddressText + .FtContent__contentBrandAddressText {
	@include sp {
		margin-top: 2px;
	};
	@include pc {
		margin-top: 2px;
	};
}
// TEL・FAX
.FtContent__contentBrandAddress + .FtContent__contentBrandContact {
	@include sp {
		margin-top: 8px;
	};
	@include pc {
		margin-top: 8px;
	};
}
.FtContent__contentBrandContact {
	display: flex;
	justify-content: center;
	@include sp {
	};
	@include pc {
	};
}
.FtContent__contentBrandContactText {
	color: $white;
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.FtContent__contentBrandContactText + .FtContent__contentBrandContactText::before {
	content: "／";
	@include sp {
		margin: 0 6px;
	};
	@include pc {
		margin: 0 6px;
	};
}
// -----▲ サービスの情報 ▲-----

// -----▼ サイトマップ ▼-----
.FtContent__contentBrandInfo + .FtContent__contentSitemap {
	@include sp {
		margin-top: 40px;
	};
	@include pc {
		margin-top: 32px;
	};
}
.FtContent__contentSitemap {
	@include sp {
		margin-top: 40px;
	};
	@include pc {
		margin-top: 24px;
	};
}
.FtContent__contentSitemapList {
	display: flex;
	justify-content: center;
	@include sp {
		gap: 32px;
	};
	@include pc {
		gap: 32px;
	};
}
.FtContent__contentSitemapListItem {
	@include sp {
		text-align: center;
	};
	@include pc {
	};
}
.FtContent__contentSitemapLink {
	color: $white;
	@include sp {
		font-size: 1.2rem;
		&:active {
			color: $link-color;
			text-decoration: underline !important;
		}
	};
	@include pc {
		font-size: 1.2rem;
		cursor: pointer;
		&:hover {
			color: $link-color;
			text-decoration: underline !important;
		}
	};
}
// -----▲ サイトマップ ▲-----

// ----------▲▲ フッターコンテンツ .Footer_content ▲▲----------
