@charset "utf-8";

// 各種「登録フォーム」の共通パーツのスタイルです。


// ----------▼▼ 「登録フォーム」の共通パーツのスタイル ▼▼----------

// -----▼ 登録フォームの共通スタイル ▼-----
// 登録フォーム
.form-regist {
	background-color: $gray_f4;
	@include sp {
		padding: 20px 12px 20px;
	};
	@include pc {
		height: 100%;
		padding: 24px 16px 24px;
	};
	// フォームのヘッダー
	.form-header {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのヘッダーのタイトル
	.form-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-title + .form-text {
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	// フォームのヘッダーの文章
	.form-text {
		@include sp {
			font-size: 1.4rem;
			line-height: 1.6;
		};
		@include pc {
			font-size: 1.4rem;
			line-height: 1.6;
		};
	}
	// フォームのセクション間のマージン
	.form-header + .form-section.-input,
	.form-header + .form-section.-submit {
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	// フォームのセクション「入力欄」
	.form-section.-input {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのセクション「送信ボタン」
	.form-section.-submit {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のリスト
	.form-list {
		@include sp {
		};
		@include pc {
		};
	}

	.form-input-list.-layer1 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer2 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer3 {
		@include sp {
		};
		@include pc {
		};
	}

	// 個々の入力項目
	.form-input-list-item {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のタイトル
	.form-list-item-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-title-label {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄
	.form-list-item-data {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-data-content {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（単文）
	.form-list-item-data-content.-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（テキストエリア）
	.form-list-item-data-content.-textarea {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（セレクトボックス）
	.form-list-item-data-content.-select {
		@include sp {
		};
		@include pc {
		};
	}
	// セレクトボックスのラッパー（アイコン配置用）
	.form-list-item-data-select-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ラジオボタン）
	.form-list-item-data-content.-radio {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（チェックボックス）
	.form-list-item-data-content.-checkbox {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスのlabel部分
	.form-list-item-data-label {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスの文言（sapn）部分
	.form-list-item-data-label-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（日付のカレンダー入力）
	.form-list-item-data-content.-calendar {
		@include sp {
		};
		@include pc {
		};
	}
	// 日付のカレンダー入力のラッパー（アイコン配置用）
	.form-list-item-data-calendar-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ファイル）
	.form-list-item-data-content.-file {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの記入例
	.form-list-item-data-example {
		@include sp {
		};
		@include pc {
		};
	}
	// フォーム内の注意書き
	.form-list-item-data-note {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン
	.form-btn {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン「メールマガジン登録」ボタン（登録フォーム用）
	.form-btn.-registNewsletter {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン「LINE登録」ボタン（登録フォーム用）
	.form-btn.-registLine {
		@include sp {
		};
		@include pc {
		};
	}

	// ※フォームの送信ボタンそのもののスタイルは「_button.scss」に記述

}
// -----▲ 登録フォームの共通スタイル ▲-----

// -----▼ フォームの種類 ▼-----
// 登録フォーム
.form.form-regist {
	@include sp {
	};
	@include pc {
	};
}
// 登録フォーム「メールマガジン」
.form.form-regist.-newsletter {
	@include sp {
	};
	@include pc {
	};
}
// 登録フォーム「LINE」
.form.form-regist.-line {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ フォームの種類 ▲-----

// -----▼ 登録フォーム「メールマガジン」の共通スタイル ▼-----
.form-regist.-newsletter {
	@include sp {
	};
	@include pc {
	};

}
// -----▲ 登録フォーム「メールマガジン」の共通スタイル ▲-----

// -----▼ 登録フォーム「LINE」の共通スタイル ▼-----
.form-regist.-line {
	@include sp {
	};
	@include pc {
	};

}
// -----▲ 登録フォーム「LINE」の共通スタイル ▲-----

// ----------▲▲ 「登録フォーム」の共通パーツのスタイル ▲▲----------
