@charset "utf-8";

// 「ヘッダー」（トップ・下層 共通）のスタイルです。


// ----------▼▼ ヘッダー header ▼▼----------

// ヘッダー全体
.Header {
	background-color: $white;
	@include sp {
		border-bottom: 1px solid $gray_d9;
	};
	@include pc {
		border-bottom: 1px solid $gray_d9;
	};
}
// ヘッダーの中身
.Header__content {
	@include sp {
	};
	@include pc {
		display: flex;
	};
}

// -----▼ ヘッダーのサービス名ロゴ部分 .Header__brandCI ▼-----
// ヘッダーのロゴ部分全体
.Header__brandCI {
	align-items: center;
	display: flex;
	@include sp {
		justify-content: center;
	};
	@include pc {
		gap: 16px;
	};
}
// ヘッダーのサービス名
.Header__brandLogoLink {
	display: block;
	font-size: 0;
	@include sp {
		height: 28px;
		margin-top: 11px;
		transition: .3s;
		width: 60px;
		&:active {
			opacity: .7;
			transition: .05s;
		}
	};
	@include pc {
		height: 32px;
		margin-top: 2px;
		transition: .3s;
		width: 70px;
		&:hover {
			opacity: .7;
			transition: .05s;
		}
	};
}
// 「メロディーレコーズ」のロゴ
.Header__brandLogo {
	@include sp {
	};
	@include pc {
	};
}
.Header__brandLogo .logo-melodyRecords {
	@include clearText;
	display: block;
	font-size: 0;
	@include sp {
		height: 28px;
		margin: auto;
		width: 60px;
	};
	@include pc {
		height: 32px;
		width: 70px;
	};
}
.Header__brandLogo .logo-melodyRecords::before {
	left: 0;
	top: 0;
	@include sp {
		height: 28px;
		width: 60px;
	};
	@include pc {
		height: 32px;
		width: 70px;
	};
}
// ヘッダーのサービスの概要文
.Header__brandDescription {
	color: $gray_7;
	white-space: nowrap;
	@include sp {
		display: none;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// -----▲ ヘッダーのサービス名ロゴ部分 .Header__brandCI ▲-----

// -----▼ 固定ページの上の方 .PageTitle ▼-----
.PageTitle {
	@include sp {
		padding: 32px 0 32px;
	};
	@include pc {
		padding: 10px 0 10px;
	};
}
// 「補助金・助成金情報」関係のページのときのみ背景色を青に
.PageTitle.-blue {
	background-color: $blue_12pct;
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 固定ページの上の方 .PageTitle ▲-----

// ----------▲▲ ヘッダー header ▲▲----------
