@charset "utf-8";

// 「会社概要」ページのスタイルです。


// ---------▼▼ 03「会社概要」company ▼▼----------

.PageCompany {

	// -----▼ 会社概要 ▼-----
	.Company__about {
		@include sp {
		};
		@include pc {
		};
	}
	.Company__about .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.Company__aboutContent {
		@include sp {
		};
		@include pc {
		};
	}
	.Company__aboutInfo {
		@include sp {
		};
		@include pc {
		};
	}
	.Company__aboutInfoText {
		@include sp {
		};
		@include pc {
		};
	}
	.Company__aboutInfoDefList {
		@include sp {
			margin-top: 40px;
		};
		@include pc {
			margin-top: 48px;
		};
	}
	// -----▲ 会社概要 ▲-----

}

// ---------▲▲ 03「会社概要」company ▲▲----------
