@charset "utf-8";

// コンポーネントのまとめ（テンプレート）用のスタイルです。
// ※サイトのリリース時には削除します。


// ---------▼▼ 00「コンポーネントのまとめ（テンプレート）」page-template ▼▼----------

// -----▼ コンポーネント .Component ▼-----
.Component {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Component .sectionContainer {
	@include sp {
		padding: 48px 0 48px;
	};
	@include pc {
		padding: 64px 0 64px;
	};
}
.Component__content {
	@include sp {
	};
	@include pc {
	};
}
.Component__componentList {
	@include sp {
	};
	@include pc {
	};
}
.Component__componentListItem {
	@include sp {
	};
	@include pc {
	};
}
.Component__componentListItem + .Component__componentListItem {
	@include sp {
		margin-top: 64px;
	};
	@include pc {
		margin-top: 80px;
	};
}
// -----▲ コンポーネント .Component ▲-----

// -----▼ コンポーネント（カード） .Card ▼-----
.Card {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Card .sectionContainer {
	@include sp {
		padding: 48px 0 48px;
	};
	@include pc {
		padding: 64px 0 64px;
	};
}
.Card__content {
	@include sp {
	};
	@include pc {
	};
}
.Card__cardList {
	@include sp {
	};
	@include pc {
	};
}
.Card__cardListItem {
	@include sp {
	};
	@include pc {
	};
}
.Card__cardListItem + .Card__cardListItem {
	@include sp {
		margin-top: 64px;
	};
	@include pc {
		margin-top: 80px;
	};
}
// -----▲ コンポーネント（カード） .Card ▲-----

// ---------▲▲ 00「コンポーネントのまとめ（テンプレート）」page-template ▲▲----------
