@charset "utf-8";

// アイコンやロゴで使うbackground-imageは、この「_image.scss」にまとめて定義する。


// ----------▼▼ ブランドのロゴ ▼▼----------

// -----▼ メロディーレコーズ ▼-----
// オリジナル
.logo-melodyRecords::before {
	background-image: url(../image/logo/logo_melody-records_black.png);
}
// 白Ver.
.logo-melodyRecords.-white::before {
	background-image: url(../image/logo/logo_melody-records_white.png);
}
// -----▲ メロディーレコーズ ▲-----

// ----------▲▲ ブランドのロゴ ▲▲----------


// ----------▼▼ 見出しのアイコン ▼▼----------

// -----▼ 特集 ▼-----
// マイクのアイコン
.icon-title.-specialFeature.-black::before {
	background-image: url(../image/icon/icon_microphone_black.png);
}
// -----▲ 特集 ▲-----

// -----▼ メディア情報 ▼-----
// 時計のアイコン
.icon-title.-mediaInfo.-black::before {
	background-image: url(../image/icon/icon_time_black.png);
}
// -----▲ メディア情報 ▲-----

// -----▼ キャンペーン＆イベント情報 ▼-----
// メガホンのアイコン
.icon-title.-campaignEvent.-black::before {
	background-image: url(../image/icon/icon_announce_black.png);
}
// -----▲ キャンペーン＆イベント情報 ▲-----

// -----▼ 最新動画 ▼-----
// 動画のアイコン
.icon-title.-youTube.-black::before {
	background-image: url(../image/icon/icon_movie_black.png);
}
// -----▲ 最新動画 ▲-----

// -----▼ ニューリリース ▼-----
// 音楽のアイコン
.icon-title.-newRelease.-black::before {
	background-image: url(../image/icon/icon_music_black.png);
}
// -----▲ ニューリリース ▲-----

// -----▼ アーティスト ▼-----
// 人のアイコン
.icon-title.-artist.-black::before {
	background-image: url(../image/icon/icon_people_black.png);
}
// -----▲ アーティスト ▲-----

// -----▼ お問い合わせ ▼-----
// メールのアイコン
.icon-title.-contact.-black::before {
	background-image: url(../image/icon/icon_mail_black.png);
}
// -----▲ お問い合わせ ▲-----

// -----▼ プライバシーポリシー ▼-----
// 文書のアイコン
.icon-title.-privacy.-black::before {
	background-image: url(../image/icon/icon_document_black.png);
}
// -----▲ プライバシーポリシー ▲-----

// ----------▲▲ 見出しのアイコン ▲▲----------


// ----------▼▼ 各種SNSのアイコン（アーティスト） ▼▼----------

// -----▼ ホームページ ▼-----
// 白Ver.
.icon-sns.-website.-white::before {
	background-image: url(../image/icon/icon_artist_website_white.svg);
}
// -----▲ ホームページ ▲-----

// -----▼ Twitter ▼-----
// 白Ver.
.icon-sns.-twitter.-white::before {
	background-image: url(../image/icon/icon_artist_twitter_white.svg);
}
// -----▲ Twitter ▲-----

// -----▼ Facebook ▼-----
// 白Ver.
.icon-sns.-facebook.-white::before {
	background-image: url(../image/icon/icon_artist_facebook_white.svg);
}
// -----▲ Facebook ▲-----

// ----------▲▲ 各種SNSのアイコン（アーティスト） ▲▲----------


// ----------▼▼ 各種SNSのアイコン（投稿のシェア） ▼▼----------

// -----▼ リンク ▼-----
// 白Ver.
.icon-share.-copyLink.-white::before {
	background-image: url(../image/icon/icon_share_link_white.svg);
}
// -----▲ リンク ▲-----

// -----▼ Twitter ▼-----
// 白Ver.
.icon-share.-twitter.-white::before {
	background-image: url(../image/icon/icon_share_twitter_white.svg);
}
// -----▲ Twitter ▲-----

// -----▼ Facebook ▼-----
// 白Ver.
.icon-share.-facebook.-white::before {
	background-image: url(../image/icon/icon_share_facebook_white.svg);
}
// -----▲ Facebook ▲-----

// -----▼ LINE ▼-----
// 白Ver.
.icon-share.-line.-white::before {
	background-image: url(../image/icon/icon_share_line_white.svg);
}
// -----▲ LINE ▲-----

// -----▼ はてなブックマーク ▼-----
// 白Ver.
.icon-share.-hatebu.-white::before {
	background-image: url(../image/icon/icon_share_hatebu_white.svg);
}
// -----▲ はてなブックマーク ▲-----

// ----------▲▲ 各種SNSのアイコン（投稿のシェア） ▲▲----------


// ----------▼▼ 背景画像 ▼▼----------

// -----▼ 背景イメージ写真 ▼-----
// トップページのメインビジュアルの背景画像
.MainVisual__image {
	@include sp {
		background-image: url(../image/photo/photo_mv-image_sp.jpg);
	};
	@include pc {
		background-image: url(../image/photo/photo_mv-image_pc.jpg);
	};
}
// -----▲ 背景イメージ写真 ▲-----

// -----▼ ドットのパターン ▼-----

// -----▲ ドットのパターン ▲-----

// ----------▲▲ 背景画像 ▲▲----------




// メールのアイコン
.icon-mail.-white::after {
	background-image: url(../image/icon/icon_mail_white.svg);
}
.icon-mail.-blue::after {
	background-image: url(../image/icon/icon_mail_blue.svg);
}

// 虫眼鏡のアイコン
.icon-search.-white::after {
	background-image: url(../image/icon/icon_search_white.svg);
}
.form-btn.-searchSubsidyAndColumn::after {
	background-image: url(../image/icon/icon_search_white.svg);
}

// グロナビリンクの矢印マーク
.GNav__menuMainLink::after {
	background-image: url(../image/icon/icon_arrow_menu-link_gray_sp.svg);
}
.GNav__menuMainLink::after {
	background-image: url(../image/icon/icon_arrow_menu-link_gray_sp.svg), url(../image/icon/icon_arrow_menu-link_blue_sp.svg);
}
.GNav__menuMainLink {
	&::after {
		background-size: cover, 0 0;
		transition: background-size .3s;
	}
	@include sp {
		&:active::after {
			 background-size: 0 0, cover;
			 transition: background-size .1s;

		}
	};
	@include pc {
		&:hover::after {
		}
	};
}




// 「詳細を見る」ボタンの矢印マーク
.icon-arrow-btn-next.-white::after {
	background-image: url(../image/icon/icon_arrow_btn-more_white.svg);
}
.icon-arrow-btn-next.-black::after {
	background-image: url(../image/icon/icon_arrow_btn-more_black.svg);
}
.icon-arrow-btn-next.-blue::after {
	background-image: url(../image/icon/icon_arrow_btn-more_blue.svg);
}
.btn-prev.-pageSubsidy .icon-arrow-btn-next::after {
	background-image: url(../image/icon/icon_arrow_btn-more_blue.svg), url(../image/icon/icon_arrow_btn-more_white.svg);
}
.btn-prev.-pageSubsidy {
	.icon-arrow-btn-next::after {
		background-size: cover, 0 0;
		transition: background-size .3s;
	}
	@include sp {
		&:active .icon-arrow-btn-next::after {
			 background-size: 0 0, cover;
			 transition: background-size .1s;

		}
	};
	@include pc {
		&:hover .icon-arrow-btn-next::after {
			background-size: 0 0, cover;
			transition: background-size .1s;
		}
	};
}

.pager.pager-prevNext .btn-pager .icon-arrow-btn-next::after {
	background-image: url(../image/icon/icon_arrow_btn-more_black.svg), url(../image/icon/icon_arrow_btn-more_pink.svg);
}
.pager.pager-prevNext .btn-pager {
	.icon-arrow-btn-next::after {
		background-size: cover, 0 0;
		transition: background-size .3s;
	}
	@include sp {
		&:active .icon-arrow-btn-next::after {
			 background-size: 0 0, cover;
			 transition: background-size .1s;

		}
	};
	@include pc {
		&:hover .icon-arrow-btn-next::after {
			background-size: 0 0, cover;
			transition: background-size .1s;
		}
	};
}




// 「詳細を見る」ボタンの別タブリンクマーク
.-tab .icon-arrow-btn-next.-white::after {
	background-image: url(../image/icon/icon_link-external_white.svg);
}
.-tab .icon-arrow-btn-next.-blue::after {
	background-image: url(../image/icon/icon_link-external_blue.svg);
}

// noteの有料記事のマーク
.icon-note-paid-articles::after {
	background-image: url(../image/icon/icon_note_paid-articles.svg);
}

// クリップボードのアイコン
.icon-fileboard.-white::before {
	background-image: url(../image/icon/icon_fileboard_white.svg);
}




// 電話のアイコン
.icon-tel.-black::before {
	background-image: url(../image/icon/icon_tel_black.svg);
}

// メールのアイコン
.btn-contact .icon-mail::before {
	background-image: url(../image/icon/icon_mail_white.svg), url(../image/icon/icon_mail_orange.svg);
}
.btn-contact {
	.icon-mail::before {
		background-size: cover, 0 0;
	}
	@include sp {
		&:active .icon-mail::before {
			 background-size: 0 0, cover;

		}
	};
	@include pc {
		&:hover .icon-mail::before {
			background-size: 0 0, cover;
		}
	};
}
// 「時計（投稿時間）」のアイコン
.BlogSingle__postTimeData.-postingTime .BlogSingle__postTimeDataText::before {
	background-image: url(../image/icon/icon_post_posting-time.svg);
}
// 「右巻き矢印（更新時間）」のアイコン
.BlogSingle__postTimeData.-updateTime .BlogSingle__postTimeDataText::before {
	background-image: url(../image/icon/icon_post_update-time.svg);
}
// 「目次」の「▼」のアイコン
#ez-toc-container a.ez-toc-toggle::before {
	background-image: url(../image/icon/icon_arrow_table-of-contents.svg);
}
// フォームの「クリアボタン」用のバツ印のアイコン
.form-btn.-clear::before {
	background-image: url(../image/icon/icon_cross_form-clear.svg);
}
// 「虫眼鏡（キーワード検索）」のアイコン
.form-search.-subsidy .title-form .title-form-text::before {
	background-image: url(../image/icon/icon_modal-search-form_paid-articles.svg);
}
// 「お金（補助金・助成金検索）」のアイコン
.form-search.-keyword .title-form .title-form-text::before {
	background-image: url(../image/icon/icon_modal-search-form_search.svg);
}
// 「メール（メールマガジン）」のアイコン
.form-regist.-newsletter .title-form .title-form-text::before {
	background-image: url(../image/icon/icon_modal-regist-form_mail.svg);
}
// 「LINE（LINE）」のアイコン
.form-regist.-line .title-form .title-form-text::before {
	background-image: url(../image/icon/icon_modal-regist-form_line.svg);
}
