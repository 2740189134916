@charset "utf-8";

// 「カテゴリー」のスタイルのテンプレートです。


// ----------▼▼ 「カテゴリー」のスタイル ▼▼----------


// .category-general：汎用カテゴリー
// .category-general.-mediaInfoメディア情報」のカテゴリー（汎用）
// .category-general.-campaignEvent：「キャンペーン＆イベント情報」のカテゴリー（汎用）


// -----▼ 「カテゴリー」の共通スタイル ▼-----
// カテゴリーの基本のスタイル
.category {
	@include sp {
		line-height: 1;
	};
	@include pc {
		line-height: 1;
	};
}
.category-list {
	display: flex;
	flex-wrap: wrap;
	@include sp {
	};
	@include pc {
	};
}
.category-list-item {
	display: inline-block;
	@include sp {
	};
	@include pc {
	};
}
// カテゴリーのリンクのスタイル
.category-link {
	display: inline-block;
	@include sp {
	};
	@include pc {
	};
}
// ※リンクなしのカテゴリーのスタイル
.category-not-link {
	@include sp {
	};
	@include pc {
	};
}
// カテゴリーのテキストのスタイル
.category-text {
	display: block;
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「カテゴリー」の共通スタイル ▲-----

// -----▼ 汎用カテゴリー ▼-----
.category-general {
	@include sp {
	};
	@include pc {
	};
}
.category-general .category-list {
	@include sp {
		gap: 6px;
	};
	@include pc {
		gap: 8px;
	};
	.category-list-item {
		@include sp {
		};
		@include pc {
		};
	}
}
.category-general {
	.category-link {
		transition: .3s;
		@include sp {
			border-radius: 4px;
			&:active {
				transition: .1s;
				.category-text {
					transition: .1s;
				}
			}
		};
		@include pc {
			border-radius: 4px;
			&:hover {
				transition: .1s;
				.category-text {
					transition: .1s;
				}
			}
		};
	}
	.category-text {
		font-weight: $medium;
		transition: .3s;
		@include sp {
			font-size: 1.2rem;
			line-height: 1.4;
			padding: 4px 10px;
		};
		@include pc {
			font-size: 1.2rem;
			line-height: 1.4;
			padding: 4px 12px;
		};
	}
}
// ピンク色Ver.
.category-general.-pink {
	@include sp {
	};
	@include pc {
	};
	.category-link {
		background-color: $pink;
		border: 1px solid $pink;
		@include sp {
			&:active {
				background-color: $pink_hsb_b65;
				border: 1px solid $pink_hsb_b65;
			}
		};
		@include pc {
			&:hover {
				background-color: $pink_hsb_b65;
				border: 1px solid $pink_hsb_b65;
			}
		};
	}
	.category-text {
		color: $white;
		@include sp {
		};
		@include pc {
		};
	}
}
// -----▲ 汎用カテゴリー ▲-----

// -----▼ 「メディア情報」のカテゴリー（汎用） ▼-----
.category-general.-mediaInfo {
	@include sp {
	};
	@include pc {
	};
}
.category-general.-mediaInfo {
	.category-link {
		@include sp {
		};
		@include pc {
		};
	}
	.category-text {
		@include sp {
		};
		@include pc {
		};
	}
}
// -----▲ 「メディア情報」のカテゴリー（汎用） ▲-----

// -----▼ 「キャンペーン＆イベント情報」のカテゴリー（汎用） ▼-----
.category-general.-campaignEvent {
	@include sp {
	};
	@include pc {
	};
}
.category-general.-campaignEvent {
	.category-link {
		@include sp {
		};
		@include pc {
		};
	}
	.category-text {
		@include sp {
		};
		@include pc {
		};
	}
}
// -----▲ 「キャンペーン＆イベント情報」のカテゴリー（汎用） ▲-----

// ----------▲▲ 「カテゴリー」のスタイル ▲▲----------
