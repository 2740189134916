@charset "utf-8";

// 「フッター」のスタイルです。


// ----------▼▼ フッター footer ▼▼----------

.Footer {
	background-color: $gray_lightBlue;
	@include sp {
		padding: 0 0 0;
	};
	@include pc {
		padding: 0 0 0;
	};
}

// ----------▲▲ フッター footer ▲▲----------
