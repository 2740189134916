@charset "utf-8";

// 「補助金・助成金情報（個別詳細ページ）」のスタイルです。


// ---------▼▼ 10「補助金・助成金情報（個別詳細ページ）」single-subsidy ▼▼----------

.PageSingleSubsidy {

	&.pageContainer {
		background-color: $blue_12pct;
		@include sp {
		};
		@include pc {
		};
	}

	// ブログの「個別詳細ページ」の投稿記事セクション
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	.Blog__post .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	// ブログの「個別詳細ページ」の投稿記事の内容（レイアウト）
	.Blog__postContent {
		@include sp {
		};
		@include pc {
		};
	}
	// ブログの「個別詳細ページ」の記事コンテンツのカラム
	.Blog__postContentItem {
		@include sp {
		};
		@include pc {
		};
	}
	// メインカラム
	.Blog__postContentItem.-main {
		@include sp {
		};
		@include pc {
		};
	}

}


// -----▼ ブログの「個別詳細ページ」だけに必要なスタイル ▼-----

.PageSingleSubsidy.BlogSingle {

	// 「個別詳細ページ」固有の内容
	.BlogSingle__post {
		background-color: $white;
		@include sp {
			border-radius: 6px;
			box-shadow: 0 3px 6px rgba($component-shadow, .2);
			padding: 16px 16px 24px;
		};
		@include pc {
			border-radius: 8px;
			box-shadow: 0 3px 6px rgba($component-shadow, .2);
			padding: 40px 40px 40px;
		};
	}

	// --▼ 記事のヘッダー .BlogSingle__postHeader ▼--
	// 記事のヘッダー全体
	.BlogSingle__postHeader {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postHeaderContent {
		@include sp {
		};
		@include pc {
		};
	}
	// 記事のタイトル
	.BlogSingle__postTitle {
		@include sp {
		};
		@include pc {
		};
	}
	// 「目的」のタグ
	.BlogSingle__postTag {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	// --▲ 記事のヘッダー .BlogSingle__postHeader ▲--

	// --▼ 記事の本文 .BlogSingle__postContent ▼--
	// 記事の本文全体
	.BlogSingle__postContent {
		@include sp {
			margin-top: 40px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	// 記事の本文（執筆部分）
	.BlogSingle__postContentBody {
		@include sp {
		};
		@include pc {
		};
	}

	// ※ブログ本文の要素のスタイルは「_wysiwyg-component.scss」に記述

	// 補助金についての説明文
	.BlogSingle__postDescription {
		@include sp {
		};
		@include pc {
		};
	}
	// 補助金の情報いろいろ
	.BlogSingle__postInfo {
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	// --▲ 記事の本文 .BlogSingle__postContent ▲--

	// --▼ 記事のフッター .BlogSingle__postFooter ▼--
	// 記事のフッター全体
	.BlogSingle__postFooter {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	.BlogSingle__postFooterContent {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postFooterBtnContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postFooterBtn {
		@include sp {
		};
		@include pc {
		};
	}




	// 「記事のSNSシェア」ボタン
	.BlogSingle__postSnsBtn {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postSnsBtn .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postSnsBtnContent {
		@include sp {
		};
		@include pc {
		};
	}
	// 他の記事のレコメンド
	.BlogSingle__postOtherPost {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postOtherPost .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postOtherPostContent {
		@include sp {
		};
		@include pc {
		};
	}
	// --▲ 記事のフッター .BlogSingle__postFooter ▲--

	// --▼ 記事の後のCTA .Blog__postCTA ▼--
	// 記事の後のCTA全体
	.Blog__postCTA {
		@include sp {
			margin-top: 40px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	// 「メルマガ・LINE登録」
	.Blog__postCTAregist {
		@include sp {
		};
		@include pc {
		};
	}
	// --▲ 記事の後のCTA .Blog__postCTA ▲--

	// --▼ 「一覧へ戻る」ボタン .Blog__postPageNav ▼--
	.Blog__postPageNav {
		@include sp {
			margin-top: 40px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	.Blog__postPageNavBackBtnContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.Blog__postPageNavBackBtn {
		@include sp {
		};
		@include pc {
			margin-left: 0;
		};
	}
	// --▲ 「一覧へ戻る」ボタン .Blog__postPageNav ▲--

	// --▼ 「公募受付期間（終了日）」の日時を元にした表示の切り替え .-expired ▼--
	// 補助金のタイトル前の表示
	.BlogSingle__postExpired {
		background-color: $red_8pct;
		@include sp {
			border-radius: 4px;
			border: 1px solid $red;
			padding: 22px 16px 22px;
		};
		@include pc {
			border-radius: 6px;
			border: 1px solid $red;
			padding: 32px 24px 32px;
		};
	}
	.BlogSingle__postExpiredText {
		color: $red;
		font-weight: bold;
		text-align: center;
		@include sp {
			font-size: 1.6rem;
			line-height: 1.5;
		};
		@include pc {
			font-size: 1.8rem;
			line-height: 1.5;
		};
	}
	.BlogSingle__postExpired + .BlogSingle__postTitle {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	// 「公募期間」の表示
	.BlogSingle__postInfo del {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postInfo ins {
		text-decoration: none;
		@include sp {
		};
		@include pc {
		};
	}
	// --▲ 「公募受付期間（終了日）」の日時を元にした表示の切り替え .-expired ▲--

}

// -----▲ ブログの「個別詳細ページ」だけに必要なスタイル ▲-----


// ---------▲▲ 10「補助金・助成金情報（個別詳細ページ）」single-subsidy ▲▲----------
