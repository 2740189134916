@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 「入力項目のタイトル」


// ----------▼▼ 「入力項目のタイトル」のスタイル ▼▼----------

// 入力項目のタイトル（基本）
.form-list-item-title {
	font-weight: bold;
	@include sp {
		font-size: 1.5rem;
		line-height: 1.4;
	};
	@include pc {
		font-size: 1.6rem;
		line-height: 1.4;
	};
}
.form-list-item-title-label {
	white-space: nowrap;
	@include sp {
		letter-spacing: $lsAV80;
	};
	@include pc {
		cursor: text;
		letter-spacing: $lsAV80;
	};
}
// 入力項目のタイトル
// （「必須」か「任意」が付くやつ）
.form-list-item.-req > .form-list-item-title > .form-list-item-title-label,
.form-list-item.-any > .form-list-item-title > .form-list-item-title-label {
	position: relative;
	@include sp {
		padding-left: calc(40px + 12px);
	};
	@include pc {
		padding-left: calc(46px + 16px);
	};
}
// 「必須」か「任意」か
.form-list-item.-req > .form-list-item-title > .form-list-item-title-label::before,
.form-list-item.-any > .form-list-item-title > .form-list-item-title-label::before {
	border-radius: 4px;
	font-weight: bold;
	left: 0;
	letter-spacing: $lsAV100;
	line-height: 1;
	position: absolute;
	white-space: nowrap;
	@include sp {
		font-size: 1.2rem;
		padding: 4px 7px;
		top: calc(50% - 10px);
	};
	@include pc {
		cursor: default;
		font-size: 1.2rem;
		padding: 4px 10px;
		top: calc(50% - 11px);
	};
}
// 「必須」のタグ
.form-list-item.-req > .form-list-item-title > .form-list-item-title-label::before {
	background-color: $red;
	color: $white;
	content: "必須";
}
// 「任意」のタグ
.form-list-item.-any > .form-list-item-title > .form-list-item-title-label::before {
	background-color: $gray_ea;
	color: $black;
	content: "任意";
}

// ----------▲▲ 「入力項目のタイトル」のスタイル ▲▲----------
