@charset "utf-8";

// 「グローバルナビゲーション」のスタイルです。


// ----------▼▼ グローバルナビゲーション gnavi ▼▼----------

// ヘッダーのナビゲーション部分全体
.GNav {
	@include sp {
		position: fixed;
		right: 0;
		top: 49px;
		width: 100%;
	};
	@include pc {
		margin-left: 64px;
		width: 100%;
	};
}

// -----▼ ハンバーガーメニューボタンのスタイル ▼-----
// ハンバーガーメニューのボタン
.GNav__menuBtn {
	z-index: 20;
	@include sp {
		height: 48px;
		padding-top: 30px;
		position: fixed;
		right: 0;
		top: 0;
		width: 48px;
	};
	@include pc {
		display: none;
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字
.GNav__menuBtnTitle {
	color: $white;
	font-weight: bold;
	text-align: center;
	@include sp {
		font-size: 1.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.3;
	};
	@include pc {
		display: none;
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字（開く前）
.GNav__menuBtn.-close .GNav__menuBtnTitle {
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字（開いた前）
.GNav__menuBtn.-open .GNav__menuBtnTitle {
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューのアイコン
.GNav__menuBtnIcon {
	@include sp {
	};
	@include pc {
	};
}
// メニュー（ハンバーガー・バツマーク共通）
.GNav__menuBtnIcon span {
	display: block;
	position: absolute;
	@include sp {
		border-radius: 50px;
		height: 2px;
		width: 20px;
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
	@include pc {
		border-radius: 50px;
		height: 2px;
		width: 20px;
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
}
// メニュー（ハンバーガーの挙動）
.GNav__menuBtn.-close .GNav__menuBtnIcon span {
	@include sp {
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
	@include pc {
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
}
// メニュー（バツマークの挙動）
.GNav__menuBtn.-open .GNav__menuBtnIcon span {
	&:nth-child(1) {
	}
	&:nth-child(2) {
	}
	&:nth-child(3) {
	}
	@include sp {
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
	@include pc {
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
}
// -----▲ ハンバーガーメニューボタンのスタイル ▲-----

// -----▼ グロナビメニューの中身 ▼-----
// グロナビのメニュー内容全体
.GNav__content {
	background-color: $white;
	@include sp {
		-webkit-overflow-scrolling: touch;
		height: 100%;
		overflow-y: scroll;
		overflow: auto;
		position: fixed;
		width: 100%;
	};
	@include pc {
		height: auto;
		width: 100%;
	};
}
.GNav__menuBtn.-open + .GNav__content {
	@include sp {
	};
	@include pc {
	};
}
// グロナビのメニュー内容３種類のラッパー
.GNav__menu {
	@include sp {
		padding-top: 24px;
		height: 100%;
	};
	@include pc {
		align-items: center;
		display: flex;
		justify-content: space-between;
		height: 100%;
	};
}
// -----▲ グロナビメニューの中身 ▲-----

// -----▼ グロナビメニューのメインのリンク ▼-----
.GNav__menuMain {
	@include sp {
	};
	@include pc {
		width: 100%;
	};
}
.GNav__menuMainList {
	@include sp {
		border-bottom: 1px solid $gray_c;
		border-top: 1px solid $gray_c;
	};
	@include pc {
		align-items: center;
		display: flex;
		justify-content: flex-end;
	};
}
.GNav__menuMainListItem {
	@include sp {
		text-align: center;
	};
	@include pc {
	};
}
.GNav__menuMainListItem + .GNav__menuMainListItem {
	@include sp {
		border-top: 1px solid $gray_c;
	};
	@include pc {
	};
}
.GNav__menuMainLink {
	color: $black;
	display: block;
	font-weight: bold;
	text-align: center;
	transition:
		color .3s,
		background-color .3s;
	white-space: nowrap;
	@include sp {
		font-size: 1.4rem;
		line-height: 1.4;
		padding: 16px 24px 16px 24px;
		&:active {
			background-color: $gray_f8;
			color: $pink;
			transition:
				color .1s,
				background-color .1s;
		}
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.4;
		padding: 22px 16px 21px;
		&:hover {
			background-color: $gray_f8;
			color: $pink;
			transition:
				color .1s,
				background-color .1s;
		}
	};
}
// 矢印マーク
.GNav__menuMainLink {
	@include icon;
	@include sp {
	};
	@include pc {
	};
}
.GNav__menuMainLink::after {
	@include sp {
		height: 12px;
		right: 8px;
		top: calc(50% - 6px);
		width: 6px;
	};
	@include pc {
	};
}
// -----▲ グロナビメニューのメインのリンク ▲-----

// -----▼ グロナビメニューのCTAリンク ▼-----
.GNav__menuMain + .GNav__menuCTA {
	@include mark;
	@include sp {
		margin-top: 32px;
	};
	@include pc {
		margin-left: 9px;
	};
}
.GNav__menuMain + .GNav__menuCTA::before {
	background-color: $gray_e4;
	@include sp {
	};
	@include pc {
		height: 32px;
		left: -5px;
		top: calc(50% - calc(32px / 2));
		width: 1px;
	};
}
.GNav__menuCTA {
	@include sp {
	};
	@include pc {
	};
}
.GNav__menuCTAList {
	@include sp {
	};
	@include pc {
		display: flex;
		position: relative;
	};
}
.GNav__menuCTAListItem {
	@include sp {
	};
	@include pc {
	};
}
.GNav__menuCTAListItem + .GNav__menuCTAListItem {
	@include sp {
		margin-top: 16px;
	};
	@include pc {
		margin-left: 16px;
	};
}
// 「お問い合わせ」リンク
// 共通のスタイル
.GNav__menuCTALink {
	color: $black;
	display: block;
	font-weight: bold;
	text-align: center;
	transition:
		color .3s,
		background-color .3s;
	white-space: nowrap;
	@include sp {
		font-size: 1.4rem;
		line-height: 1.4;
		padding: 16px 24px 16px 24px;
		&:active {
			background-color: $gray_f8;
			color: $pink;
			transition:
				color .1s,
				background-color .1s;
		}
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.4;
		padding: 22px 0 21px 16px;
		&:hover {
			background-color: $gray_f8;
			color: $pink;
			transition:
				color .1s,
				background-color .1s;
		}
	};
}
// 「お問い合わせ」リンク
.GNav__menuCTAListItem.-contact {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ グロナビメニューのCTAリンク ▲-----

// ----------▲▲ グローバルナビゲーション gnavi ▲▲----------
