@charset "utf-8";

// 各種「メールフォーム」の共通パーツのスタイルです。


// ----------▼▼ 「メールフォーム」の共通パーツのスタイル ▼▼----------

// -----▼ メールフォームの共通スタイル ▼-----
// メールフォーム
.form-mail {
	@include sp {
	};
	@include pc {
	};
	// フォームのセクション
	.form-section {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのセクション「入力欄」
	.form-section.-input {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのセクション「プライバシーポリシー」
	.form-section.-privacyPolicy {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームのセクション「送信ボタン」
	.form-section.-submit {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のリスト
	.form-list {
		@include sp {
		};
		@include pc {
		};
	}

	.form-input-list.-layer1 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer2 {
		@include sp {
		};
		@include pc {
		};
	}
	.form-input-list.-layer3 {
		@include sp {
		};
		@include pc {
		};
	}

	// 個々の入力項目
	.form-input-list-item {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力項目のタイトル
	.form-list-item-title {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-title-label {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄
	.form-list-item-data {
		@include sp {
		};
		@include pc {
		};
	}
	.form-list-item-data-content {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（単文）
	.form-list-item-data-content.-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（テキストエリア）
	.form-list-item-data-content.-textarea {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（セレクトボックス）
	.form-list-item-data-content.-select {
		@include sp {
		};
		@include pc {
		};
	}
	// セレクトボックスのラッパー（アイコン配置用）
	.form-list-item-data-select-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ラジオボタン）
	.form-list-item-data-content.-radio {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（チェックボックス）
	.form-list-item-data-content.-checkbox {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスのlabel部分
	.form-list-item-data-label {
		@include sp {
		};
		@include pc {
		};
	}
	// ラジオボタンやチェックボックスの文言（sapn）部分
	.form-list-item-data-label-text {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（日付のカレンダー入力）
	.form-list-item-data-content.-calendar {
		@include sp {
		};
		@include pc {
		};
	}
	// 日付のカレンダー入力のラッパー（アイコン配置用）
	.form-list-item-data-calendar-wrap {
		@include sp {
		};
		@include pc {
		};
	}
	// 入力欄（ファイル）
	.form-list-item-data-content.-file {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの記入例
	.form-list-item-data-example {
		@include sp {
		};
		@include pc {
		};
	}
	// フォーム内の注意書き
	.form-list-item-data-note {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン
	.form-btn {
		@include sp {
		};
		@include pc {
		};
	}
	// フォームの送信ボタン「送信する」ボタン（メールフォーム用）
	.form-btn.-formContact {
		@include sp {
		};
		@include pc {
		};
	}

	// ※フォームの送信ボタンそのもののスタイルは「_button.scss」に記述

}
// -----▲ メールフォームの共通スタイル ▲-----

// -----▼ フォームの種類 ▼-----
// メールフォーム
.form.form-mail {
	@include sp {
	};
	@include pc {
	};
}
// メールフォーム「お問い合わせ」
.form.form-mail.-contact {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ フォームの種類 ▲-----

// -----▼ メールフォーム「お問い合わせ」の共通スタイル ▼-----
.form-mail.-contact  {
	@include sp {
	};
	@include pc {
	};

}
// -----▲ メールフォーム「お問い合わせ」の共通スタイル ▲-----






// -----▼ 入力項目（共通） ▼-----
span.wpcf7-list-item {
	margin: 0 !important;
}
// -----▲ 入力項目（共通） ▲-----

// -----▼ 入力項目（チェックボックス「プライバシーポリシー」） ▼-----
// プライバシーポリシー全体
.form-privacy-policy {
	@include sp {
	};
	@include pc {
	};
}
// 説明文「プライバシーポリシーをご確認・ご同意の上〜」
.form-privacy-policy-text {
	@include sp {
		font-size: 1.4rem;
		line-height: 1.8;
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.8;
	};
}
// 「プライバシーポリシーに同意する」
.form-privacy-policy .form-list {
	@include sp {
	};
	@include pc {
	};
}
.form-privacy-policy .form-list-item {
	@include sp {
	};
	@include pc {
	};
}
.form-privacy-policy .form-list-item-title {
	@include sp {
	};
	@include pc {
	};
}
.form-privacy-policy .form-list-item-title-label {
	display: block !important;
	padding-left: 0 !important;
	@include sp {
		height: 20px !important;
		width: 40px !important;
	};
	@include pc {
		height: 20px !important;
		width: 46px !important;
	};
}
.form-privacy-policy .form-list-item-data {
	@include sp {
		margin-left: 20px !important;
	};
	@include pc {
		margin-left: 24px !important;
	};
}
.form-privacy-policy .form-list-item-data-content.-checkbox {
	@include sp {
	};
	@include pc {
	};
}
.form-privacy-policy .form-list-item-data-label {
	@include sp {
	};
	@include pc {
	};
}
// 「プライバシーポリシー」のチェックボックスとそれに続く文言
.form-privacy-policy .form-list-item-data-label-text {
	line-height: 1.5 !important;
	@include sp {
		font-size: 1.4rem !important;
		padding: 6px 0 5px 32px !important;
	};
	@include pc {
		font-size: 1.6rem !important;
		padding: 5px 0 5px 32px !important;
	};
}
// 「プライバシーポリシー」のバリデーションの文言
.form-privacy-policy .wpcf7-not-valid-tip {
	@include sp {
	};
	@include pc {
		text-align: center;
	};
}
// -----▲ 入力項目（チェックボックス「プライバシーポリシー」） ▲-----

// -----▼ 送信アラート（バリデーション） ▼-----
// エラー時の入力項目の表示
.form-list-item.-req.-error .form-list-item-data-content.-text,
.form-list-item.-req.-error .form-list-item-data-content.-textarea,
.form-list-item.-req.-error .form-list-item-data-content.-select {
	background-color: $red_6pct;
	@include sp {
		border: 1px solid $red;
	};
	@include pc {
		border: 1px solid $red;
	};
}
// 個々の入力項目に付加されるバリデーション
.form-list-item span.wpcf7-not-valid-tip {
	@include mark;
	background-color: $red;
	border: 1px solid $red;
	color: $white;
	font-weight: $medium;
	letter-spacing: $lsAV80;
	@include sp {
		border-radius: 4px;
		font-size: 1.2rem;
		margin-top: 14px;
		padding: 8px 12px;
	};
	@include pc {
		border-radius: 4px;
		font-size: 1.4rem;
		margin-top: 16px;
		padding: 8px 16px;
	};
}
.form-list-item span.wpcf7-not-valid-tip::before,
.form-list-item span.wpcf7-not-valid-tip::after {
	@include sp {
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		left: 8px;
	};
	@include pc {
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		left: 12px;
	};
}
.form-list-item span.wpcf7-not-valid-tip::before {
	z-index: -1;
	@include sp {
		border-bottom: 17px solid $red;
		top: -12px;
	};
	@include pc {
		border-bottom: 17px solid $red;
		top: -13px;
	};
}
.form-list-item span.wpcf7-not-valid-tip::after {
	@include sp {
		border-bottom: 17px solid $red;
		top: -10px;
	};
	@include pc {
		border-bottom: 17px solid $red;
		top: -11px;
	};
}
// 「送信する」ボタンの下に付加されるバリデーション
.form-mail div.wpcf7-response-output {
	background-color: $red_6pct;
	color: $red;
	font-weight: $medium;
	@include sp {
		border-radius: 4px;
		border: 1px solid $red !important;
		font-size: 1.6rem;
		letter-spacing: $lsAV80;
		line-height: 1.5;
		margin: 32px 0 0 0;
		padding: 12px 14px 12px;
	};
	@include pc {
		border-radius: 6px;
		border: 1px solid $red !important;
		font-size: 1.6rem;
		letter-spacing: $lsAV80;
		line-height: 1.5;
		margin: 48px 0 0 0;
		padding: 16px 14px 16px;
		text-align: center;
	};
}
.form-mail div.wpcf7-response-output.wpcf7-mail-sent-ok {
	background-color: $red_6pct;
	border: 1px solid $red;
	color: $red;
}
// 「送信する」ボタンを押したときのロードアイコン
div.wpcf7 .form-mail .ajax-loader {
	background-image: url(../image/icon/icon_arrow-load_white.svg);
	position: absolute;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	transform: rotateZ(45deg);
	@include sp {
		bottom: 22px;
		height: 16px;
		left: calc(50% - 70px);
		width: 16px;
	};
	@include pc {
		bottom: 22px;
		height: 16px;
		left: calc(50% - 70px);
		width: 16px;
	};
}
.ajax-loader {
	animation: rotate-anime 1.2s linear infinite;
	@keyframes rotate-anime {
	 	0% {transform: rotate(0);}
	 	100% {transform: rotate(360deg);}
	}
}
// -----▲ 送信アラート（バリデーション） ▲-----

// ----------▲▲ 「メールフォーム」の共通パーツのスタイル ▲▲----------
