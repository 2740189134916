@charset "utf-8";

// 「アーティスト」の詳細ページのスタイルです。


// ---------▼▼ 11「アーティスト（詳細ページ）」single-artist ▼▼----------

.PageSingleArtist {

	@include sp {
	};
	@include pc {
	};

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// -----▼ 「アーティスト」詳細 ▼-----
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postContent {
		@include sp {
			padding-bottom: 24px;
		};
		@include pc {
			padding-bottom: 24px;
		};
	}
	// -----▲ 「アーティスト」詳細 ▲-----

	// -----▼ 「アーティスト」コンテンツ ▼-----
	// アーティストの内容全体
	.Artist__artist {
		@include sp {
		};
		@include pc {
			display: flex;
			flex-direction: row-reverse;
			gap: 40px;
		};
	}
	// アーティストの写真
	.Artist__artistPhoto {
		@include sp {
		};
		@include pc {
		};
	}
	.Artist__artistPhotoImg {
		@include sp {
			border-radius: 8px;
			height: 104px;
			width: 104px;
		};
		@include pc {
			border-radius: 10px;
			height: 200px;
			width: 200px;
		};
	}
	// アーティストの情報
	// 「アーティスト名」＋「プロフィール」＋「関連リンク」
	.Artist__artistPhoto + .Artist__artistInfo {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
		};
	}
	.Artist__artistInfo {
		@include sp {
		};
		@include pc {
			width: calc(100% - calc(200px + 40px));
		};
	}
	// アーティスト名
	.Artist__artistName {
		@include sp {
			text-align: center;
		};
		@include pc {
		};
	}
	.Artist__artistNameMain {
		color: $black;
		font-weight: bold;
		@include sp {
			font-size: 2.4rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
		@include pc {
			font-size: 2.0rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
	}
	.Artist__artistNameMain + .Artist__artistNameKana {
		@include sp {
			margin-top: 4px;
		};
		@include pc {
			margin-top: 4px;
		};
	}
	.Artist__artistNameKana {
		color: $gray_7;
		@include sp {
			font-size: 1.4rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
		@include pc {
			font-size: 1.2rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
	}
	// プロフィール
	.Artist__artistProfile {
		@include sp {
		};
		@include pc {
		};
	}
	.Artist__artistProfile p {
		@include sp {
			line-height: 1.5;
		};
		@include pc {
			line-height: 1.5;
		};
	}
	// アーティストの関連リンク
	.BlogSingle__postContentBody + .BlogSingle__postContentArtistLink {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 20px;
		};
	}
	.BlogSingle__postContentArtistLink {
		@include sp {
		};
		@include pc {
		};
	}
	// フッター
	.BlogSingle__postFooter {
		@include sp {
			border-top: 1px solid $gray_e4;
			padding-top: 24px;
		};
		@include pc {
			border-top: 1px solid $gray_e4;
			padding-top: 24px;
		};
	}
	// ◯◯◯の作品
	.BlogSingle__postRecommend {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postShareTitle {
		font-weight: bold;
		@include sp {
			font-size: 1.6rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
		@include pc {
			font-size: 1.6rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
		};
	}
	.BlogSingle__postShareTitle + .BlogSingle__postSnsBtn {
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	// -----▲ 「アーティスト」コンテンツ ▲-----

}

// ---------▲▲ 11「アーティスト（詳細ページ）」archive-news ▲▲----------
