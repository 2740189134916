@charset "utf-8";

// 「キャンペーン＆イベント情報（詳細ページ）」のスタイルです。


// ---------▼▼ 05「キャンペーン＆イベント情報（詳細ページ）」single-campaignEvent ▼▼----------

.PageSingleCampaign {

	// -----▼ 記事のヘッダー ▼-----
	.BlogSingle__postHeader {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postHeaderContent {
		@include sp {
		};
		@include pc {
		};
	}
	// 記事の投稿時間・更新時間
	.BlogSingle__postTime {
		display: flex;
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData + .BlogSingle__postTimeData {
		@include sp {
			margin-left: 20px;
		};
		@include pc {
			margin-left: 28px;
		};
	}
	.BlogSingle__postTimeDataText {
		color: $gray_5;
		@include sp {
		};
		@include pc {
		};
	}
	// 「時計」のアイコン・「更新」のアイコン
	.BlogSingle__postTimeDataText {
		@include icon;
		@include sp {
			padding-left: 16px;
		};
		@include pc {
			padding-left: 22px;
		};
	}
	.BlogSingle__postTimeDataText::before {
		@include sp {
			height: 14px;
			left: 0;
			top: calc(50% - 7px);
			width: 14px;
		};
		@include pc {
			height: 18px;
			left: 0;
			top: calc(50% - 9px);
			width: 18px;
		};
	}
	.BlogSingle__postTimeData.-postingTime .BlogSingle__postTimeDataText::before {
		@include sp {
			top: calc(50% - 6px);
		};
		@include pc {
		};
	}
	// 投稿時間
	.BlogSingle__postTimeData.-postingTime {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData.-postingTime .BlogSingle__postTimeDataText::before {
		@include sp {
		};
		@include pc {
		};
	}
	// 更新時間
	.BlogSingle__postTimeData.-updateTime {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData.-updateTime .BlogSingle__postTimeDataText::before {
		@include sp {
		};
		@include pc {
		};
	}
	// 記事のタイトル
	.BlogSingle__postTime + .BlogSingle__postTitle {
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	.BlogSingle__postTitle {
		font-weight: $medium;
		@include sp {
			border-bottom: 2px solid $main-color;
			font-size: 2.2rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
			padding-bottom: 18px;
		};
		@include pc {
			border-bottom: 2px solid $main-color;
			font-size: 2.4rem;
			letter-spacing: $lsAV80;
			line-height: 1.5;
			padding-bottom: 24px;
		};
	}
	.BlogSingle__postTitle + .BlogSingle__postCategoryAndTag {
		@include sp {
			margin-top: 18px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	.BlogSingle__postCategory {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postCategory + .BlogSingle__postTag {
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	.BlogSingle__postTag {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTagList {
		@include sp {
			margin-left: 24px;
		};
		@include pc {
			margin-left: 24px;
		};
	}
	.BlogSingle__postHeader + .BlogSingle__postContent.-mediaInfo {
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	.BlogSingle__postContent.-mediaInfo {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 記事のヘッダー ▲-----

	// -----▼ 記事のフッター ▼-----
	.BlogSingle__postContent + .BlogSingle__postFooter {
		@include sp {
			margin-top: 56px;
		};
		@include pc {
			margin-top: 64px;
		};
	}
	.BlogSingle__postFooter {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postShareTitle {
		@include sp {
			border-bottom: none;
		};
		@include pc {
			border-bottom: none;
		};
	}
	.BlogSingle__postShareTitle + .BlogSingle__postShareBtn {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	.BlogSingle__postShareBtn {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 記事のフッター ▲-----

	// -----▼ 記事のナビゲーション ▼-----
	.BlogSingle__post + .Blog__postPageNav {
		@include sp {
			margin-top: 56px;
		};
		@include pc {
			margin-top: 64px;
		};
	}
	.Blog__postPageNav {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 記事のナビゲーション ▲-----

}

// ---------▲▲ 05「キャンペーン＆イベント情報（詳細ページ）」single-campaignEvent ▲▲----------
