@charset "utf-8";

// 「404 Page not Found　お探しのページが見つかりません」ページのスタイルです。


/*----------▼▼ 21「お探しのページが見つかりません」404.php ▼▼----------*/

.Page404 {

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// -----▼ お探しのページが見つかりません ▼-----
	.Page404__pageNotFound {
		@include sp {
		};
		@include pc {
		};
	}
	.Page404__pageNotFound .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.Page404__pageNotFoundContent {
		@include sp {
		};
		@include pc {
		};
	}
	.Page404__message {
		@include sp {
		};
		@include pc {
		};
	}
	// 404ページの見出し
	.Page404__messageTitle {
		@include sp {
		};
		@include pc {
		};
	}
	// 404ページの説明書き
	.Page404__messageText {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	// 「トップページへ戻る」ボタン
	.Page404__topLink {
		@include sp {
			margin-top: 40px;
		};
		@include pc {
			margin-top: 56px;
		};
	}
	.Page404__topLinkBtn.btn-top-page {
		@include sp {
			width: 80%;
		};
		@include pc {
			width: 240px;
		};
	}

	// ※「トップページへ戻る」ボタンのスタイルは「_button.scss」に記述。

	// -----▲ お探しのページが見つかりません ▲-----

}
/*----------▲▲ 21「お探しのページが見つかりません」404.php ▲▲----------*/
