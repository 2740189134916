@charset "utf-8";

// 「はじめての方へ」ページのスタイルです。


// ---------▼▼ 02「はじめての方へ」about ▼▼----------

.PageAbout {

	// -----▼ このサイトについて ▼-----
	.About__thisSite {
		@include sp {
		};
		@include pc {
		};
	}
	.About__thisSite .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.About__thisSiteContent {
		@include sp {
		};
		@include pc {
		};
	}
	.About__thisSiteDescription {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ このサイトについて ▲-----

	// -----▼ サイトで提供する情報 ▼-----
	.About__service {
		@include sp {
		};
		@include pc {
		};
	}
	.About__service .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.About__serviceContent {
		@include sp {
		};
		@include pc {
		};
	}
	.About__serviceInfo {
		@include sp {
		};
		@include pc {
		};
	}
	.About__serviceInfoList {
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	.About__serviceInfoListItem {
		@include sp {
		};
		@include pc {
		};
	}
	.About__serviceInfoListItem + .About__serviceInfoListItem {
		@include sp {
			margin-top: 56px;
		};
		@include pc {
			margin-top: 56px;
		};
	}
	// -----▲ サイトで提供する情報 ▲-----

	// -----▼ メルマガ・LINE会員登録について ▼-----
	.About__regist {
		@include sp {
		};
		@include pc {
		};
	}
	.About__regist .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.About__registContent {
		@include sp {
		};
		@include pc {
		};
	}
	.About__registAbout {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ メルマガ・LINE会員登録について ▲-----

}

// ---------▲▲ 02「はじめての方へ」about ▲▲----------
