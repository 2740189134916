@charset "utf-8";

// 「中小企業向けコラム（個別詳細ページ）」のスタイルです。


// ---------▼▼ 16「中小企業向けコラム（一覧ページ）」single-column ▼▼----------

.PageSingleColumn {

	@include sp {
	};
	@include pc {
	};

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// ブログの「個別詳細ページ」の投稿記事セクション
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	.Blog__post .sectionContainer {
		@include sp {
			padding: 0 0 64px !important;
		};
		@include pc {
			padding: 0 0 80px !important;
		};
	}
	// ブログの「個別詳細ページ」の投稿記事の内容（レイアウト）
	.Blog__postContent {
		@include sp {
		};
		@include pc {
		};
	}
	// ブログの「個別詳細ページ」の記事コンテンツのカラム
	.Blog__postContentItem {
		@include sp {
		};
		@include pc {
		};
	}
	// メインカラム
	.Blog__postContentItem.-main {
		@include sp {
		};
		@include pc {
		};
	}

}


// -----▼ ブログの「個別詳細ページ」だけに必要なスタイル ▼-----

.PageSingleColumn.BlogSingle {

	// 「個別詳細ページ」固有の内容
	.BlogSingle__post {
		@include sp {
		};
		@include pc {
		};
	}

	// --▼ 記事のヘッダー .BlogSingle__postHeader ▼--
	// 記事のヘッダー全体
	.BlogSingle__postHeader {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postHeaderContent {
		@include sp {
		};
		@include pc {
		};
	}
	// 記事の投稿時間・更新時間
	.BlogSingle__postTime {
		display: flex;
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData + .BlogSingle__postTimeData {
		@include sp {
			margin-left: 20px;
		};
		@include pc {
			margin-left: 28px;
		};
	}
	.BlogSingle__postTimeDataText {
		color: $gray_5;
		font-family: $font-barlow;
		@include sp {
		};
		@include pc {
		};
	}
	// 「時計」のアイコン・「更新」のアイコン
	.BlogSingle__postTimeDataText {
		@include icon;
		@include sp {
			padding-left: 16px;
		};
		@include pc {
			padding-left: 22px;
		};
	}
	.BlogSingle__postTimeDataText::before {
		@include sp {
			height: 14px;
			left: 0;
			top: calc(50% - 7px);
			width: 14px;
		};
		@include pc {
			height: 18px;
			left: 0;
			top: calc(50% - 9px);
			width: 18px;
		};
	}
	.BlogSingle__postTimeData.-postingTime .BlogSingle__postTimeDataText::before {
		@include sp {
			top: calc(50% - 6px);
		};
		@include pc {
			top: calc(50% - 8px);
		};
	}
	// 投稿時間
	.BlogSingle__postTimeData.-postingTime {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData.-postingTime .BlogSingle__postTimeDataText::before {
		@include sp {
		};
		@include pc {
		};
	}
	// 更新時間
	.BlogSingle__postTimeData.-updateTime {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeData.-updateTime .BlogSingle__postTimeDataText::before {
		@include sp {
		};
		@include pc {
		};
	}
	// 記事のタイトル
	.BlogSingle__postTitle {
		@include sp {
			margin-top: 10px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	.BlogSingle__postTitle.icon-note-paid-articles {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTitle.icon-note-paid-articles::after {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: "";
		display: inline-block;
		@include sp {
			height: 18px;
			margin-left: 4px;
			vertical-align: -2px;
			width: 18px;
		};
		@include pc {
			height: 24px;
			margin-left: 6px;
			vertical-align: -3px;
			width: 24px;
		};
	}
	// 「中小企業向けコラム」のカテゴリーとタグ
	.BlogSingle__postCategoryAndTag {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	// 「中小企業向けコラム」のカテゴリー
	.BlogSingle__postCategory {
		@include sp {
		};
		@include pc {
		};
	}
	// 「中小企業向けコラム」のタグ
	.BlogSingle__postTag {
		@include sp {
			margin-top: 10px;
		};
		@include pc {
			margin-top: 12px;
		};
	}
	// 「noteの有料記事」の注意書き
	.BlogSingle__postCategoryAndTag + .BlogSingle__postNotePaidArticles {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	.BlogSingle__postNotePaidArticles {
		background-color: $note_8percent;
		@include sp {
			border-left: 4px solid $note;
			padding: 22px 16px 22px;
		};
		@include pc {
			border-left: 6px solid $note;
			padding: 32px 24px 32px;
		};
	}
	.BlogSingle__postNotePaidArticlesText {
		color: $gray_5;
		@include sp {
			font-size: 1.3rem;
			line-height: 1.6;
		};
		@include pc {
			font-size: 1.4rem;
			line-height: 1.8;
		};
	}
	// --▲ 記事のヘッダー .BlogSingle__postHeader ▲--

	// --▼ 記事の本文 .BlogSingle__postContent ▼--
	// 記事の本文全体
	.BlogSingle__postContent {
		@include sp {
			margin-top: 48px;
		};
		@include pc {
			margin-top: 56px;
		};
	}
	// 記事の本文（執筆部分）
	.BlogSingle__postContentBody {
		@include sp {
		};
		@include pc {
		};
	}

	// ※ブログ本文の要素のスタイルは「_wysiwyg-component.scss」に記述

	// 補助金についての説明文
	.BlogSingle__postDescription {
		@include sp {
		};
		@include pc {
		};
	}

	// 補助金の情報いろいろ
	.BlogSingle__postInfo {
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 40px;
		};
	}

	// --▲ 記事の本文 .BlogSingle__postContent ▲--

	// --▼ 記事のフッター .BlogSingle__postFooter ▼--
	// 記事のフッター全体
	.BlogSingle__postFooter {
		@include sp {
			margin-top: 64px;
		};
		@include pc {
			margin-top: 64px;
		};
	}
	.BlogSingle__postFooterContent {
		@include sp {
		};
		@include pc {
		};
	}

	// 「このコラムをシェアする」
	.BlogSingle__postShare {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postShareTitle {
		@include sp {
			font-size: 2.0rem;
		};
		@include pc {
			font-size: 2.4rem;
		};
	}
	.BlogSingle__postShareBtn {
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}

	// ※各種SNSのシェアボタンのスタイルは「_button.scss」に記述

	// --▲ 記事のフッター .BlogSingle__postFooter ▲--

	// --▼ 記事の後のCTA .Blog__postCTA ▼--
	// 記事の後のCTA全体
	.Blog__postCTA {
		@include sp {
			margin-top: 48px;
		};
		@include pc {
			margin-top: 80px;
		};
	}
	// 「メルマガ・LINE登録」
	.Blog__postCTAregist {
		@include sp {
		};
		@include pc {
		};
	}
	// 「メルマガ・LINE登録」カード
	.Blog__postCTAregistCard {
		@include sp {
		};
		@include pc {
		};
	}

	// ※「メルマガ・LINE登録」カードのスタイルは「_card.scss」に記述

	// --▲ 記事の後のCTA .Blog__postCTA ▲--

	// --▼ 「一覧へ戻る」ボタン .Blog__postPageNav ▼--
	.Blog__postPageNav {
		@include sp {
			margin-top: 64px;
		};
		@include pc {
			margin-top: 80px;
		};
	}
	.Blog__postPager {
		@include sp {
		};
		@include pc {
		};
	}
	// --▲ 「一覧へ戻る」ボタン .Blog__postPageNav ▲--

}

// -----▲ ブログの「個別詳細ページ」だけに必要なスタイル ▲-----

// ---------▲▲ 16「中小企業向けコラム（一覧ページ）」single-column ▲▲----------
