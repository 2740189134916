@charset "utf-8";

// 「フッター」の「フッターCTA」のスタイルです。


// ----------▼▼ フッターCTA .Footer_cta ▼▼----------

// -----▼ CTA「メルマガ・LINEに登録する」 ▼-----
.Footer_cta {
	@include sp {
	};
	@include pc {
	};
}
.Footer_cta .sectionContainer {
	@include sp {
		padding: 40px 0 48px;
	};
	@include pc {
		padding: 64px 0 80px;
	};
}
.Footer_ctaContent {
	@include sp {
	};
	@include pc {
	};
}
.Footer_ctaAbout {
	color: $white;
	text-align: center;
	@include sp {
	};
	@include pc {
	};
}
.Footer_ctaAboutTitle {
	font-weight: bold;
	@include sp {
		font-size: 2.0rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 2.4rem;
		line-height: 1.5;
	};
}
.Footer_ctaAboutText {
	@include sp {
		font-size: 1.4rem;
		line-height: 1.6;
		margin-top: 16px;
	};
	@include pc {
		margin-top: 20px;
	};
}
.Footer_ctaRegistFormBtnContainer {
	@include sp {
		margin-top: 24px;
	};
	@include pc {
		margin-top: 40px;
	};
}
.Footer_ctaRegistFormBtn {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ CTA「メルマガ・LINEに登録する」 ▲-----

// ----------▲▲ フッターCTA .Footer_cta ▲▲----------
