@charset "utf-8";

// 「ボタン」のスタイルのテンプレートです。


// ----------▼▼ 「ボタン」のスタイル ▼▼----------

// .btn：すべてのボタンのベース

// .btn.-normal：基本のボタン｜ノーマル

// .btn-general：普通のボタン（汎用）
// .btn-general.-formContact：普通のボタン（「お問い合わせ」）
// .btn-general.-modalSearchForm：普通のボタン（「補助金情報」検索のモーダル）
// .btn-general.-modalRegistForm：普通のボタン（メルマガ・LINEのモーダル）
// .btn-close：「閉じる」ボタン
// .btn-close.-modal：「閉じる」ボタン（モーダル用）
// .btn-more：「詳細を見る」ボタン（汎用）
// .btn-more.-cardSubsidy：「詳細を見る」ボタン（「補助金・助成金情報」のカード）
// .btn-more.-topPage：「詳細を見る」ボタン（「トップページへ戻る」）
// .btn-more.-cardNews：「詳細を見る」ボタン（「中小企業向けニュース」のカード・内部リンク）
// .btn-more.-tab.-cardNews：「詳細を見る」ボタン（「中小企業向けニュース」のカード・外部リンク）
// .btn-more.-cardKeywordSearch：「詳細を見る」ボタン（「補助金・助成金情報、コラム（検索結果ページ）」のカード）
// .btn-searchSubsidy：「補助金・助成金情報を検索」ボタン（汎用）
// .btn-searchSubsidy.-header：「補助金・助成金情報を検索」ボタン（ヘッダー）
// .btn-searchSubsidy.-mainVisual：「補助金・助成金情報を検索」ボタン（メインビジュアル）
// .btn-searchSubsidy.-page：「補助金・助成金情報を検索」ボタン（ページ内）
// .btn-searchSubsidy.-footer：「補助金・助成金情報を検索」ボタン（フッター）
// .btn-searchSubsidyAndColumn.-formKeywordSearch：「補助金・助成金情報を検索」ボタン（「キーワード検索」フォーム）
// .btn-mail：メール送信ボタン（汎用）（汎用）
// .btn-mail.-pageSubsidy：「相談する」ボタン（「補助金・助成金情報」の個別詳細ページ）
// .btn-regist：「登録する」ボタン（汎用）
// .btn-regist.-header：「登録する」ボタン（ヘッダー）
// .btn-regist.-cardRegist：「登録する」ボタン（「メルマガ・LINE登録」のカード）
// .btn-regist.-footer：「登録する」ボタン（フッター）
// .btn-accordion：アコーディオンの開閉ボタン（汎用）
// .btn-accordion.-formSearch：アコーディオンの開閉ボタン（「補助金・助成金検索」フォーム）
// .btn-sort：並び替えボタン（汎用）
// .btn-sort.-searchResult.-subsidy：並び替えボタン（「補助金・助成金情報」の検索結果）
// .btn-share：シェアボタン
// .btn-prev.-pageSubsidy：「一覧へ戻る」ボタン（「補助金・助成金情報」の個別詳細ページ）
// .pager-prevNext：「前／次」のページャー
// .btn-clear：フォームのクリアボタン

// .btn-aiueo：50音順（あいうえお順）のボタン


// -----▼ すべてのボタンのベース ▼-----
// ボタン
.btn {
	display: block;
	height: auto;
	margin: auto;
	position: relative;    // ※アイコン付きボタン用
	text-align: center;
	@include sp {
		border-radius: 6px;
		box-shadow: 0 3px 6px rgba($component-shadow, .2);
		padding: 16px 0;
	};
	@include pc {
		border-radius: 6px;
		box-shadow: 0 3px 6px rgba($component-shadow, .2);
		cursor: pointer;
		padding: 18px 0;
	};
}
// アイコン付きボタン
.btn .icon {
	position: static;
	@include sp {
	};
	@include pc {
	};
}
// ボタン（タップ時やホバー時のアニメーション）
.btn {
	transition: .3s;
	@include sp {
		&:active {
			transition: .1s;
		}
	};
	@include pc {
		&:hover {
			transition: .1s;
		}
	};
}
// ボタンのテキスト
.btn-text {
	font-weight: $medium;
	@include sp {
		font-size: 1.4rem;
		letter-spacing: $lsAV50;
		line-height: 1.6;
	};
	@include pc {
		font-size: 1.6rem;
		letter-spacing: $lsAV50;
		line-height: 1.6;
	};
}
// ボタンのテキスト（タップ時やホバー時のアニメーション）
.btn {
	.btn-text {
		transition: .3s;
	}
	@include sp {
		&:active .btn-text {
			transition: .1s;
		}
	};
	@include pc {
		&:hover .btn-text {
			transition: .1s;
		}
	};
}
// ボタンのアイコン（タップ時やホバー時のアニメーション）
.btn {
	@include sp {
	};
	@include pc {
		.icon {
			&::before,
			&::after {
				transition: .3s;
			}
		}
		&:hover .icon::before,
		&:hover .icon::after {
			transition: .1s;
		}
	};
}
// -----▲ すべてのボタンのベース ▲-----

// -----▼ 基本のボタン｜ノーマル ▼-----
.btn.-normal {
	@include sp {
	};
	@include pc {
	};
}
.btn.-normal .btn-text,
.btn.-normal.btn-text {
	@include sp {
	};
	@include pc {
	};
}
// 青Ver.
.btn.-normal.-blue {
	background-color: $blue;
	border: 1px solid $blue;
	@include sp {
	};
	@include pc {
	};
}
.btn.-normal.-blue .btn-text,
.btn.-normal.-blue.btn-text {
	color: $white;
	@include sp {
	};
	@include pc {
	};
}
// 青Ver.のホバー時
.btn.-normal.-blue {
	@include sp {
		&:active {
			background-color: $blue_hover;
			border: 1px solid $blue_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $blue_hover;
			border: 1px solid $blue_hover;
		}
	};
}
// 緑Ver.
.btn.-normal.-line {
	background-color: $line;
	border: 1px solid $line;
	@include sp {
	};
	@include pc {
	};
}
.btn.-normal.-line .btn-text,
.btn.-normal.-line.btn-text {
	color: $white;
	@include sp {
	};
	@include pc {
	};
}
// 緑Ver.のホバー時
.btn.-normal.-line {
	@include sp {
		&:active {
			background-color: $line_hover;
			border: 1px solid $line_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $line_hover;
			border: 1px solid $line_hover;
		}
	};
}
// 白Ver.
.btn.-normal.-white {
	background-color: $white;
	border: 1px solid $white;
	@include sp {
	};
	@include pc {
	};
}
.btn.-normal.-white .btn-text,
.btn.-normal.-white.btn-text {
	color: $blue;
	@include sp {
	};
	@include pc {
	};
}
// 白Ver.のホバー時
.btn.-normal.-white {
	@include sp {
		&:active {
			background-color: $blue_12pct;
			border: 1px solid $blue_12pct;
		}
	};
	@include pc {
		&:hover {
			background-color: $blue_12pct;
			border: 1px solid $blue_12pct;
		}
	};
}
// -----▲ 基本のボタン｜ノーマル ▲-----

// -----▼ 普通のボタン（汎用） ▼-----
.btn-general {
	@include sp {
		width: 80%;
	};
	@include pc {
		width: 320px;
	};
}
// -----▲ 普通のボタン（汎用） ▲-----

// -----▼ 普通のボタン（「お問い合わせ」） ▼-----
.btn-general.-formContact {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 普通のボタン（「お問い合わせ」） ▲-----

// -----▼ 普通のボタン（「補助金情報」検索のモーダル） ▼-----
.btn-general.-modalSearchForm {
	box-shadow: none;
	@include sp {
		padding: 12px 0;
	};
	@include pc {
		padding: 14px 0;
		width: 240px;
	};
}
// -----▲ 普通のボタン（「補助金情報」検索のモーダル） ▲-----

// -----▼ 普通のボタン（メルマガ・LINEのモーダル） ▼-----
.btn-general.-modalRegistForm {
	box-shadow: none;
	@include sp {
		padding: 12px 0;
	};
	@include pc {
		padding: 14px 0;
		width: 240px;
	};
}
// -----▲ 普通のボタン（メルマガ・LINEのモーダル） ▲-----

// -----▼ 「閉じる」ボタン ▼-----
.btn-close {
	@include mark;
	background-color: $white;
	border-radius: 0;
	border: 1px solid $gray_c;
	box-shadow: none;
	display: block;
	@include sp {
	};
	@include pc {
		cursor: pointer;
	};
}
.btn-close::before,
.btn-close::after {
	background-color: $black;
	@include sp {
	};
	@include pc {
	};
}
.btn-close::before {
	transform: rotate(315deg);
	@include sp {
	};
	@include pc {
	};
}
.btn-close::after {
	transform: rotate(-315deg);
	@include sp {
	};
	@include pc {
	};
}
.btn-close .btn-text {
	@include sp {
		font-size: 0;
	};
	@include pc {
		font-size: 0;
	};
}
// ホバー時の効果a
.btn-close {
	@include sp {
		&:active {
			opacity: .6;
		}
	};
	@include pc {
		&:hover {
			opacity: .6;
		}
	};
}
// -----▲ 「閉じる」ボタン ▲-----

// -----▼ 「閉じる」ボタン（モーダル用） ▼-----
.btn-close.-modal {
	position: absolute;
	@include sp {
		height: 32px !important;
		padding: 0 !important;
		right: 0px;
		top: 0px;
		width: 32px !important;
	};
	@include pc {
		height: 40px !important;
		padding: 0 !important;
		right: 0px;
		top: 0px;
		width: 40px !important;
	};
}
.btn-close.-modal::before,
.btn-close.-modal::after {
	@include sp {
		height: 1px;
		width: 16px;
	};
	@include pc {
		height: 2px;
		width: 24px;
	};
}
.btn-close.-modal::before {
	@include sp {
		left: calc(50% - 8px);
		top: calc(50% - .5px);
	};
	@include pc {
		left: calc(50% - 12px);
		top: calc(50% - 1px);
	};
}
.btn-close.-modal::after {
	@include sp {
		left: calc(50% - 8px);
		top: calc(50% - .5px);
	};
	@include pc {
		left: calc(50% - 12px);
		top: calc(50% - 1px);
	};
}
.btn-close.-modal .btn-text {
	@include sp {
		font-size: 0;
	};
	@include pc {
		font-size: 0;
	};
}
// -----▲ 「閉じる」ボタン（モーダル用） ▲-----

// -----▼ 「詳細を見る」ボタン（汎用） ▼-----
.btn-more {
	@include sp {
		padding: 16px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 40px;
		width: 400px;
	};
}
.btn-more .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-more .icon-arrow-btn-next {
	@include sp {
	};
	@include pc {
	};
}
.btn-more .icon-arrow-btn-next::after {
	@include sp {
		height: 12px;
		right: 12px;
		top: calc(50% - 6px);
		width: 12px;
	};
	@include pc {
		height: 14px;
		right: 16px;
		top: calc(50% - 7px);
		width: 14px;
	};
}
// -----▲ 「詳細を見る」ボタン（汎用） ▲-----

// -----▼ 「詳細を見る」ボタン（「補助金・助成金情報」のカード） ▼-----
.btn-more.-cardSubsidy {
	@include sp {
		padding: 8px 32px;
		width: 80%;
	};
	@include pc {
		padding: 10px 40px;
		width: 240px;
	};
}
.btn-more.-cardSubsidy .btn-text {
	@include sp {
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// -----▲ 「詳細を見る」ボタン（「補助金・助成金情報」のカード） ▲-----

// -----▼ 「詳細を見る」ボタン（「トップページへ戻る」） ▼-----
.btn-more.-topPage {
	@include sp {
		padding: 14px 32px;
		width: 80%;
	};
	@include pc {
		padding: 14px 40px;
		width: 320px;
	};
}
.btn-more.-topPage .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「詳細を見る」ボタン（「トップページへ戻る」） ▲-----

// -----▼ 「詳細を見る（▶︎）」ボタン（「中小企業向けニュース」のカード・内部リンク） ▼-----
.btn-more.-cardNews {
	box-shadow: none;
	@include sp {
		border-radius: 0 6px 6px 0;
		height: 100%;
		padding: 0;
		width: 28px;
	};
	@include pc {
		border-radius: 0 8px 8px 0;
		height: 100%;
		padding: 0;
		width: 36px;
	};
}
.btn-more.-cardNews .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-cardNews .icon-arrow-btn-next {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-cardNews .icon-arrow-btn-next::after {
	@include sp {
		height: 14px;
		left: calc(50% - 7px);
		top: calc(50% - 7px);
		width: 14px;
	};
	@include pc {
		height: 16px;
		left: calc(50% - 8px);
		top: calc(50% - 8px);
		width: 16px;
	};
}
// -----▲ 「詳細を見る（▶︎）」ボタン（「中小企業向けニュース」のカード・内部リンク） ▲-----

// -----▼ 「詳細を見る」ボタン（「中小企業向けニュース」のカード・外部リンク） ▼-----
.btn-more.-tab.-cardNews {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-tab.-cardNews .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-tab.-cardNews .icon-arrow-btn-next {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-tab.-cardNews .icon-arrow-btn-next::after {
	@include sp {
		height: 14px;
		left: calc(50% - 7px);
		top: calc(50% - 7px);
		width: 14px;
	};
	@include pc {
		height: 16px;
		left: calc(50% - 8px);
		top: calc(50% - 8px);
		width: 16px;
	};
}
// -----▲ 「詳細を見る」ボタン（「中小企業向けニュース」のカード・外部リンク） ▲-----

// -----▼ 「詳細を見る」ボタン（「補助金・助成金情報、コラム（検索結果ページ）」のカード） ▼-----
.btn-more.-cardKeywordSearch {
	box-shadow: none;
	@include sp {
		border-radius: 0 6px 6px 0;
		height: 100%;
		padding: 0;
		width: 28px;
	};
	@include pc {
		border-radius: 0 8px 8px 0;
		height: 100%;
		padding: 0;
		width: 36px;
	};
}
.btn-more.-cardKeywordSearch .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-cardKeywordSearch .icon-arrow-btn-next {
	@include sp {
	};
	@include pc {
	};
}
.btn-more.-cardKeywordSearch .icon-arrow-btn-next::after {
	@include sp {
		height: 14px;
		left: calc(50% - 7px);
		top: calc(50% - 7px);
		width: 14px;
	};
	@include pc {
		height: 16px;
		left: calc(50% - 8px);
		top: calc(50% - 8px);
		width: 16px;
	};
}
// -----▲ 「詳細を見る」ボタン（「補助金・助成金情報、コラム（検索結果ページ）」のカード） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（汎用） ▼-----
.btn-searchSubsidy {
	@include sp {
		padding: 16px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 40px;
		width: 400px;
	};
}
.btn-searchSubsidy .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-searchSubsidy .icon-search {
	@include sp {
	};
	@include pc {
	};
}
.btn-searchSubsidy .icon-search::after {
	@include sp {
		height: 20px;
		right: 12px;
		top: calc(50% - 9px);
		width: 20px;
	};
	@include pc {
		height: 20px;
		right: 16px;
		top: calc(50% - 9px);
		width: 20px;
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（汎用） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（ヘッダー） ▼-----
.btn-searchSubsidy.-header {
	box-shadow: none !important;
	@include sp {
		padding: 14px 32px;
		width: 80%;
	};
	@include pc {
		padding: 14px 36px;
		width: 240px;
	};
}
.btn-searchSubsidy.-header .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
.btn-searchSubsidy.-header .icon-search::after {
	@include sp {
		right: 8px;
	};
	@include pc {
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（ヘッダー） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（メインビジュアル） ▼-----
.btn-searchSubsidy.-mainVisual {
	@include sp {
		width: 280px;
	};
	@include pc {
		width: 280px;
	};
}
.btn-searchSubsidy.-mainVisual .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（メインビジュアル） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（ページ内） ▼-----
.btn-searchSubsidy.-page,
.form-btn.-searchSubsidy .btn {
	@include sp {
		padding: 16px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 40px;
		width: 400px;
	};
}
.btn-searchSubsidy.-page .btn-text,
.form-btn.-searchSubsidy .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（ページ内） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（フッター） ▼-----
.btn-searchSubsidy.-footer {
	box-shadow: none;
	@include sp {
		padding: 14px 24px;
		width: 80%;
	};
	@include pc {
		padding: 14px 24px;
		width: 240px;
	};
}
.btn-searchSubsidy.-footer .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（フッター） ▲-----

// -----▼ 「補助金・助成金情報を検索」ボタン（「サイト内検索」フォーム） ▼-----
.btn-searchSubsidyAndColumn.-formKeywordSearch,
.form.form-search.-keyword .form-section.-submit .btn {
	box-shadow: none;
	@include sp {
		border-radius: 0 4px 4px 0 !important;
		height: 48px;
		padding: 0;
		width: 48px;
	};
	@include pc {
		border-radius: 0 4px 4px 0 !important;
		height: 48px;
		padding: 0;
		width: 80px;
	};
}
.btn-searchSubsidyAndColumn.-formKeywordSearch .btn-text,
.form.form-search.-keyword .form-section.-submit .btn {
	@include sp {
		font-size: 0;
	};
	@include pc {
		font-size: 0;
	};
}
.btn-searchSubsidyAndColumn .icon-search {
	@include sp {
	};
	@include pc {
	};
}
.btn-searchSubsidyAndColumn .icon-search::after {
	@include sp {
		height: 20px;
		right: calc(50% - 10px);
		top: calc(50% - 9px);
		width: 20px;
	};
	@include pc {
		height: 20px;
		right: calc(50% - 10px);
		top: calc(50% - 9px);
		width: 20px;
	};
}
.form-btn.-searchSubsidyAndColumn {
	@include icon;
	@include sp {
	};
	@include pc {
	};
}
.form-btn.-searchSubsidyAndColumn::after {
	pointer-events: none;
	@include sp {
		height: 20px;
		right: calc(50% - 10px);
		top: calc(50% - 9px);
		width: 20px;
	};
	@include pc {
		height: 20px;
		right: calc(50% - 10px);
		top: calc(50% - 9px);
		width: 20px;
	};
}
// -----▲ 「補助金・助成金情報を検索」ボタン（「サイト内検索」フォーム） ▲-----

// -----▼ メール送信ボタン（汎用） ▼-----
.btn-mail {
	@include sp {
		padding: 16px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 40px;
		width: 400px;
	};
}
.btn-mail .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-mail .icon-mail {
	@include sp {
	};
	@include pc {
	};
}
.btn-mail .icon-mail::after {
	@include sp {
		height: 14px;
		right: 12px;
		top: calc(50% - 7px);
		width: 14px;
	};
	@include pc {
		height: 16px;
		right: 16px;
		top: calc(50% - 8px);
		width: 16px;
	};
}
// -----▲ メール送信ボタン（汎用） ▲-----

// -----▼ 「相談する」ボタン（「補助金・助成金情報」の個別詳細ページ） ▼-----
.btn-mail.-pageSubsidy {
	@include sp {
		width: 88%;
	};
	@include pc {
	};
}
.btn-mail.-pageSubsidy .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
	};
}
// -----▲ 「相談する」ボタン（「補助金・助成金情報」の個別詳細ページ） ▲-----

// -----▼ 「登録する」ボタン（汎用） ▼-----
.btn-regist {
	@include sp {
		padding: 16px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 40px;
		width: 400px;
	};
}
.btn-regist .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-regist .icon-mail {
	@include sp {
	};
	@include pc {
	};
}
.btn-regist .icon-mail::after {
	@include sp {
		height: 14px;
		right: 12px;
		top: calc(50% - 7px);
		width: 14px;
	};
	@include pc {
		height: 16px;
		right: 16px;
		top: calc(50% - 8px);
		width: 16px;
	};
}
// -----▲ 「登録する」ボタン（汎用） ▲-----

// -----▼ 「登録する」ボタン（ヘッダー） ▼-----
.btn-regist.-header {
	box-shadow: none !important;
	@include sp {
		padding: 14px 32px;
		width: 80%;
	};
	@include pc {
		padding: 14px 36px;
		width: 240px;
	};
}
.btn-regist.-header .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// -----▲ 「登録する」ボタン（ヘッダー） ▲-----

// -----▼ 「登録する」ボタン（「メルマガ・LINE登録」のカード） ▼-----
.btn-regist.-cardRegist {
	@include sp {
		width: 100%;
	};
	@include pc {
		width: 320px;
	};
}
.btn-regist.-cardRegist .btn-text {
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
	};
}
// -----▲ 「登録する」ボタン（「メルマガ・LINE登録」のカード） ▲-----

// -----▼ 「登録する」ボタン（フッター） ▼-----
.btn-regist.-footer {
	@include sp {
		padding: 14px 32px;
		width: 80%;
	};
	@include pc {
		padding: 18px 36px;
		width: 400px;
	};
}
.btn-regist.-footer .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「登録する」ボタン（フッター） ▲-----

// -----▼ アコーディオンの開閉ボタン（汎用） ▼-----
.btn-accordion {
	@include mark;
	@include sp {
		border-radius: 4px !important;
	};
	@include pc {
		border-radius: 4px !important;
	};
}
// 「＋」マークと「ー」マーク
.btn-accordion::before,
.btn-accordion::after {
	background-color: $white;
	@include sp {
		height: 2px;
		width: 14px;
	};
	@include pc {
		height: 2px;
		width: 14px;
	};
}
// 「＋」マークと「ー」マーク（ボタン押下時のアニメーション）
.btn-accordion {
	@include sp {
	};
	@include pc {
	};
	&::before,
	&::after {
		transition: transform .2s;
		@include sp {
		};
		@include pc {
		};
	}
	&::before {
		@include sp {
			left: calc(50% - 7px);
			top: calc(50% - 1px);
		};
		@include pc {
			left: calc(50% - 7px);
			top: calc(50% - 1px);
		};
	}
	&::after {
		transform: rotate(90deg);
		@include sp {
			left: calc(50% - 7px);
			top: calc(50% - 1px);
		};
		@include pc {
			left: calc(50% - 7px);
			top: calc(50% - 1px);
		};
	}
}
.-active .btn-accordion::after {
	transform: rotate(0deg);
	@include sp {
	};
	@include pc {
	};
}
// -----▲ アコーディオンの開閉ボタン（汎用） ▲-----

// -----▼ アコーディオンの開閉ボタン（「補助金・助成金検索」フォーム） ▼-----
.btn-accordion.-formSearch {
	box-shadow: none !important;
	@include sp {
		height: 24px;
		padding: 0;
		width: 24px;
	};
	@include pc {
		height: 24px;
		padding: 0;
		width: 24px;
	};
}
// -----▲ アコーディオンの開閉ボタン（「補助金・助成金検索」フォーム） ▲-----

// -----▼ 並び替えボタン（レイアウト） ▼-----
.sort {
	@include sp {
	};
	@include pc {
	};
}
.sort-list {
	display: flex;
	@include sp {
		margin-left: 1px;
	};
	@include pc {
		margin-left: 1px;
	};
}
.sort-list-item {
	@include sp {
		margin-left: -1px;
	};
	@include pc {
		margin-left: -1px;
	};
}
// 「新着順」ボタン
.sort-list-item.-orderNew {
	@include sp {
	};
	@include pc {
	};
}
.sort-list-item.-orderNew .feas-sl-1-up {
	display: none;
	@include sp {
	};
	@include pc {
	};
}
// 「金額順」ボタン
.sort-list-item.-orderAmountOfMoney {
	@include sp {
	};
	@include pc {
	};
}
.sort-list-item.-orderAmountOfMoney .feas-sl-2-up {
	display: none;
	@include sp {
	};
	@include pc {
	};
}
// 「締切順」ボタン
.sort-list-item.-orderDeadline {
	@include sp {
	};
	@include pc {
	};
}
.sort-list-item.-orderDeadline .feas-sl-3-down {
	display: none;
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 並び替えボタン（レイアウト） ▲-----

// -----▼ 並び替えボタン（汎用） ▼-----
.btn-sort {
	background-color: $gray_f5;
	border-radius: 0 !important;
	border: 1px solid $gray_c;
	box-shadow: none !important;
	@include sp {
		padding: 7px 10px;
		width: 100%;
	};
	@include pc {
		cursor: pointer;
		padding: 8px 16px;
		width: 100%;
	};
}
.sort-list-item:first-of-type .btn-sort {
	@include sp {
		border-radius: 4px 0 0 4px !important;
	};
	@include pc {
		border-radius: 4px 0 0 4px !important;
	};
}
.sort-list-item:last-of-type .btn-sort {
	@include sp {
		border-radius: 0 4px 4px 0 !important;
	};
	@include pc {
		border-radius: 0 4px 4px 0 !important;
	};
}
.btn-sort {
	@include sp {
		&:active {
			background-color: $blue;
			border: 1px solid $blue;
			.btn-text {
				color: $white;
			}
		}
	};
	@include pc {
		&:hover {
			background-color: $blue;
			border: 1px solid $blue;
			.btn-text {
				color: $white;
			}
		}
	};
}
.btn-sort .btn-text {
	color: $gray_5;
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
		font-size: 1.4rem;
	};
}
// 並び替えボタンのアクティブ時のスタイル
.btn-sort.-active {
	background-color: $blue;
	border: 1px solid $blue;
	@include sp {
	};
	@include pc {
	};
	.btn-text {
		color: $white;
		@include sp {
		};
		@include pc {
		};
	}
}
// -----▲ 並び替えボタン（汎用） ▲-----

// -----▼ 並び替えボタン（「補助金・助成金情報」の検索結果） ▼-----
.sort.sort-searchResult.-subsidy {
	@include sp {
	};
	@include pc {
	};
}
.sort.sort-searchResult.-subsidy .sort-list {
	@include sp {
	};
	@include pc {
	};
}
.sort.sort-searchResult.-subsidy .sort-list-item {
	@include sp {
		width: flexBox(3, -1px);
	};
	@include pc {
	};
}
.btn-sort.-searchResult.-subsidy {
	@include sp {
	};
	@include pc {
	};
}
.btn-sort.-searchResult.-subsidy .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 並び替えボタン（「補助金・助成金情報」の検索結果） ▲-----

// -----▼ SNSボタン（レイアウト） ▼-----
.sns {
	@include sp {
	};
	@include pc {
	};
}
.sns-list {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		gap: 24px;
		justify-content: center;
	};
	@include pc {
		gap: 18px;
	};
}
.sns-list-item {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ SNSボタン（レイアウト） ▲-----

// -----▼ SNSボタン（汎用） ▼-----
.btn-sns {
	box-shadow: none !important;
	@include sp {
		border-radius: 50%;
		height: 32px;
		padding: 0 !important;
		width: 32px;
	};
	@include pc {
		border-radius: 50%;
		cursor: pointer;
		height: 32px;
		padding: 0 !important;
		width: 32px;
	};
}
.btn-sns {
	@include sp {
		&:active {
		}
	};
	@include pc {
		&:hover {
		}
	};
}
.btn-sns .btn-text {
	@include sp {
		font-size: 0;
	};
	@include pc {
		font-size: 0;
	};
}
.btn-sns .btn-text::before {
	@include sp {
		height: 32px;
		left: calc(50% - calc(32px /2));
		top: calc(50% - calc(32px /2));
		width: 32px;
	};
	@include pc {
		height: 32px;
		left: calc(50% - calc(32px /2));
		top: calc(50% - calc(32px /2));
		width: 32px;
	};
}
// -----▲ SNSボタン（汎用） ▲-----

// -----▼ SNSボタン（「公式サイト」） ▼-----
.btn-sns.-website {
	background-color: $gray_5;
	border: 1px solid $gray_5;
	@include sp {
	};
	@include pc {
	};
}
.btn-sns.-website {
	@include sp {
		&:active {
			background-color: $black;
			border: 1px solid $black;
		}
	};
	@include pc {
		&:hover {
			background-color: $black;
			border: 1px solid $black;
		}
	};
}
.btn-sns.-website .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ SNSボタン（「公式サイト」） ▲-----

// -----▼ SNSボタン（「Twitterで共有する」） ▼-----
.btn-sns.-twitter {
	background-color: $twitter;
	border: 1px solid $twitter;
	@include sp {
	};
	@include pc {
	};
}
.btn-sns.-twitter {
	@include sp {
		&:active {
			background-color: $twitter_hover;
			border: 1px solid $twitter_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $twitter_hover;
			border: 1px solid $twitter_hover;
		}
	};
}
.btn-sns.-twitter .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ SNSボタン（「Twitterで共有する」） ▲-----

// -----▼ SNSボタン（「Facebookで共有する」） ▼-----
.btn-sns.-facebook {
	background-color: $facebook;
	border: 1px solid $facebook;
	@include sp {
	};
	@include pc {
	};
}
.btn-sns.-facebook {
	@include sp {
		&:active {
			background-color: $facebook_hover;
			border: 1px solid $facebook_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $facebook_hover;
			border: 1px solid $facebook_hover;
		}
	};
}
.btn-sns.-facebook .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ SNSボタン（「Facebookで共有する」） ▲-----

// -----▼ シェアボタン（レイアウト） ▼-----
.share {
	@include sp {
	};
	@include pc {
	};
}
.share-list {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		gap: 18px;
	};
	@include pc {
		gap: 24px;
	};
}
.share-list-item {
	@include sp {
		width: 100%;
	};
	@include pc {
		width: calc(calc(100% - calc(24px * 4)) / 5);
	};
}
// -----▲ シェアボタン（レイアウト） ▲-----

// -----▼ シェアボタン（汎用） ▼-----
.btn-share {
	box-shadow: none !important;
	@include sp {
		border-radius: 6px;
		height: 32px;
		width: 100%;
	};
	@include pc {
		border-radius: 6px;
		cursor: pointer;
		height: 40px;
		width: 100%;
	};
}
.btn-share {
	@include sp {
		&:active {
		}
	};
	@include pc {
		&:hover {
		}
	};
}
.btn-share .btn-text {
	@include sp {
		font-size: 0;
	};
	@include pc {
		font-size: 0;
	};
}
.btn-share .btn-text::before {
	@include sp {
		height: 24px;
		left: calc(50% - calc(24px /2));
		top: calc(50% - calc(24px /2));
		width: 24px;
	};
	@include pc {
		height: 24px;
		left: calc(50% - calc(24px /2));
		top: calc(50% - calc(24px /2));
		width: 24px;
	};
}
// -----▲ シェアボタン（汎用） ▲-----

// -----▼ シェアボタン（「中小企業コラム」） ▼-----
.share.share-sns.-column {
	@include sp {
	};
	@include pc {
	};
}
.share.share-sns.-column .share-list {
	@include sp {
	};
	@include pc {
	};
}
.share.share-sns.-column .share-list-item {
	@include sp {
	};
	@include pc {
	};
}
.share.share-sns.-column .btn-share {
	@include sp {
	};
	@include pc {
	};
}
.share.share-sns.-column .btn-share .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ シェアボタン（「中小企業コラム」） ▲-----

// -----▼ シェアボタン（「リンクをコピーする」） ▼-----
.btn-share.-copyLink {
	background-color: $gray_5;
	border: 1px solid $gray_5;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-copyLink {
	@include sp {
		&:active {
			background-color: $black;
			border: 1px solid $black;
		}
	};
	@include pc {
		&:hover {
			background-color: $black;
			border: 1px solid $black;
		}
	};
}
.btn-share.-copyLink .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// ボタンクリック後に表示するツールチップ
.btn-share.-copyLink.-copied {
	@include mark;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-copyLink.-copied::before,
.btn-share.-copyLink.-copied::after {
	z-index: 1;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-copyLink.-copied::before {
	@include sp {
		border-bottom: 6px solid $pink;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		bottom: -11px;
		left: calc(50% - 6px);
	};
	@include pc {
		border-bottom: 6px solid $pink;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		bottom: -11px;
		left: calc(50% - 6px);
	};
}
.btn-share.-copyLink.-copied::after {
	background-color: $pink;
	color: $white;
	content: 'URLをコピーしました';
	white-space: nowrap;
	@include sp {
		border-radius: 3px;
		bottom: -32px;
		font-size: 1.0rem;
		left: calc(50% - 57.5px);
		padding: 2px 8px 2px;
	};
	@include pc {
		border-radius: 3px;
		bottom: -32px;
		font-size: 1.0rem;
		left: calc(50% - 57.5px);
		padding: 2px 8px 2px;
	};
}
// 「リンクをコピーする」ボタンのツールチップのアニメーション
.btn-share.-copyLink.-copied::before,
.btn-share.-copyLink.-copied::after {
	animation: fade-tooltip .5s 1.6s 1 forwards;
	@include sp {
	};
	@include pc {
	};
}
@keyframes fade-tooltip {
	to { opacity: 0; }
}
// -----▲ シェアボタン（「リンクをコピーする」） ▲-----

// -----▼ シェアボタン（「Twitterで共有する」） ▼-----
.btn-share.-twitter {
	background-color: $twitter;
	border: 1px solid $twitter;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-twitter {
	@include sp {
		&:active {
			background-color: $twitter_hover;
			border: 1px solid $twitter_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $twitter_hover;
			border: 1px solid $twitter_hover;
		}
	};
}
.btn-share.-twitter .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ シェアボタン（「Twitterで共有する」） ▲-----

// -----▼ シェアボタン（「Facebookで共有する」） ▼-----
.btn-share.-facebook {
	background-color: $facebook;
	border: 1px solid $facebook;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-facebook {
	@include sp {
		&:active {
			background-color: $facebook_hover;
			border: 1px solid $facebook_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $facebook_hover;
			border: 1px solid $facebook_hover;
		}
	};
}
.btn-share.-facebook .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ シェアボタン（「Facebookで共有する」） ▲-----

// -----▼ シェアボタン（「LINEで共有する」） ▼-----
.btn-share.-line {
	background-color: $line;
	border: 1px solid $line;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-line {
	@include sp {
		&:active {
			background-color: $line_hover;
			border: 1px solid $line_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $line_hover;
			border: 1px solid $line_hover;
		}
	};
}
.btn-share.-line .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ シェアボタン（「LINEで共有する」） ▲-----

// -----▼ シェアボタン（「はてなブックマークで共有する」） ▼-----
.btn-share.-hatebu {
	background-color: $hatebu;
	border: 1px solid $hatebu;
	@include sp {
	};
	@include pc {
	};
}
.btn-share.-hatebu {
	@include sp {
		&:active {
			background-color: $hatebu_hover;
			border: 1px solid $hatebu_hover;
		}
	};
	@include pc {
		&:hover {
			background-color: $hatebu_hover;
			border: 1px solid $hatebu_hover;
		}
	};
}
.btn-share.-hatebu .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ シェアボタン（「はてなブックマークで共有する」） ▲-----

// -----▼ 「一覧へ戻る」ボタン（「補助金・助成金情報」の個別詳細ページ） ▼-----
.btn-prev.-pageSubsidy {
	@include sp {
		padding: 14px 32px;
		width: 80%;
	};
	@include pc {
		padding: 14px 40px;
		width: 240px;
	};
}
.btn-prev.-pageSubsidy .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.btn-prev.-pageSubsidy .icon-arrow-btn-next {
	@include sp {
	};
	@include pc {
	};
}
.btn-prev.-pageSubsidy .icon-arrow-btn-next::after {
	transform: scale(-1, 1);
	@include sp {
		height: 12px;
		left: 12px;
		top: calc(50% - 6px);
		width: 12px;
	};
	@include pc {
		height: 14px;
		left: 16px;
		top: calc(50% - 7px);
		width: 14px;
	};
}
// ホバー時の効果
.btn-prev.-pageSubsidy {
	@include sp {
		&:active {
			background-color: $blue !important;
			border: 1px solid $blue !important;
			.btn-text {
				color: $white !important;
				@include sp {
				};
				@include pc {
				};
			}
		}
	};
	@include pc {
		&:hover {
			background-color: $blue !important;
			border: 1px solid $blue !important;
			.btn-text {
				color: $white !important;
				@include sp {
				};
				@include pc {
				};
			}
		}
	};
}
// -----▲ 「一覧へ戻る」ボタン（「補助金・助成金情報」の個別詳細ページ） ▲-----

// -----▼ 「前／次」のページャー（レイアウト） ▼-----
.pager.pager-prevNext {
	@include sp {
		margin: 0 -5vw;
	};
	@include pc {
	};
}
.pager.pager-prevNext .pager-list {
	border-bottom: 1px solid $gray_c;
	border-top: 1px solid $gray_c;
	display: flex;
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext .pager-list-item {
	@include sp {
		width: 50%;
	};
	@include pc {
		width: 50%;
	};
}
.pager.pager-prevNext .pager-list-item + .pager-list-item {
	@include mark;
	z-index: 0;
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext .pager-list-item + .pager-list-item::before {
	background-color: $gray_c;
	z-index: 1;
	@include sp {
		height: calc(100% - calc(12px * 2));
		left: -0.5px;
		top: 12px;
		width: 1px;
	};
	@include pc {
		height: calc(100% - calc(16px * 2));
		left: -0.5px;
		top: 16px;
		width: 1px;
	};
}
// -----▲ 「前／次」のページャー（レイアウト） ▲-----

// -----▼ 「前／次」のページャー（汎用） ▼-----
.pager.pager-prevNext .btn-pager {
	align-items: center;
	border-radius: 0 !important;
	box-shadow: none !important;
	display: flex;
	@include sp {
		height: 100%;
	};
	@include pc {
		cursor: pointer;
		height: 100%;
	};
}
.pager.pager-prevNext .btn-pager {
	text-align: left;
	@include sp {
	};
	@include pc {
	};
}
// ホバー時の効果
.pager.pager-prevNext .btn-pager {
	@include sp {
		&:active {
			background-color: $pink_hover;
			transition: background-color .1s;
			.btn-text {
				color: $main-color;
			}
		}
	};
	@include pc {
		&:hover {
			background-color: $pink_hover;
			transition: background-color .1s;
			.btn-text {
				color: $main-color;
			}
		}
	};
}
.pager.pager-prevNext .btn-prev {
	@include sp {
	padding: 16px 12px 16px 0;
	padding-left: 5vw;
	};
	@include pc {
		padding: 24px 20px 24px 38px;
	};
}
.pager.pager-prevNext .btn-next {
	@include sp {
		padding: 16px 0 16px 12px;
		padding-right: 5vw;
	};
	@include pc {
		padding: 24px 38px 24px 20px;
	};
}
.pager.pager-prevNext .btn-pager .btn-text {
	color: $black;
	display: inline-block;
	@include sp {
		font-size: 1.4rem;
	};
	@include pc {
	};
}
.pager.pager-prevNext .btn-prev .btn-text {
	@include sp {
		padding-left: 12px;
	};
	@include pc {
	};
}
.pager.pager-prevNext .btn-next .btn-text {
	@include sp {
		padding-right: 12px;
	};
	@include pc {
	};
}
.pager.pager-prevNext .btn-pager .btn-text::after {
	@include sp {
		height: 12px;
		top: calc(50% - 6px);
		width: 12px;
	};
	@include pc {
		height: 12px;
		top: calc(50% - 6px);
		width: 12px;
	};
}
.pager.pager-prevNext .btn-prev .btn-text::after {
	transform: scale(-1, 1);
	@include sp {
		left: 5%;
	};
	@include pc {
		left: 8px;
	};
}
.pager.pager-prevNext .btn-next .btn-text::after {
	@include sp {
		right: 5%;
	};
	@include pc {
		right: 8px;
	};
}
// -----▲ 「前／次」のページャー（汎用） ▲-----

// -----▼ 「前／次」のページャー（「中小企業コラム」） ▼-----
.pager.pager-prevNext.-column {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .pager-list {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .pager-list-item {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .btn-pager {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .btn-prev {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .btn-prev .btn-text {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .btn-next {
	@include sp {
	};
	@include pc {
	};
}
.pager.pager-prevNext.-column .btn-next .btn-text {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「前／次」のページャー（「中小企業コラム」） ▲-----

// -----▼ フォームのクリアボタン ▼-----
.form-btn.-clear {
	@include icon;
	opacity: 0;
	transition: opacity .1s;
	z-index: 0;
	@include sp {
	};
	@include pc {
	};
}
.form-list-item-data-content.-text:focus ~ .form-btn.-clear {
	opacity: 1;
	@include sp {
	};
	@include pc {
	};
}
.form-btn.-clear::before {
	pointer-events: none;
	z-index: 1;
	@include sp {
		height: 16px;
		left: calc(50% - 8px);
		top: calc(50% - 8px);
		width: 16px;
	};
	@include pc {
		height: 20px;
		left: calc(50% - 10px);
		top: calc(50% - 10px);
		width: 20px;
	};
}
.btn-clear {
	border-radius: 50% !important;
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
	@include sp {
		background-color: $gray_e6;
		height: 22px;
		width: 22px;
	};
	@include pc {
		background-color: transparent;
		cursor: pointer;
		height: 26px;
		width: 26px;
	};
}
// ホバー時の効果
.btn-clear {
	@include sp {
		&:active {
			background-color: $gray_e6;
			transition: background-color .1s;
		}
	};
	@include pc {
		&:hover {
			background-color: $gray_e6;
			transition: background-color .1s;
		}
	};
}
.btn-clear:focus:not(:focus-visible) {
	outline: none;
	@include sp {
	};
	@include pc {
	};
}
// -----▲ フォームのクリアボタン ▲-----

// .btn-aiueo：50音順（あいうえお順）のボタン

// -----▼ 50音順（あいうえお順）のボタン ▼-----
.BlogArchive__postSearchBtnList {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		border: 1px solid $pink;
		border-radius: 6px;
		border-width: 1px 0 0 1px;
	};
	@include pc {
		border: 1px solid $pink;
		border-radius: 6px;
		border-width: 1px 0 0 1px;
	};
}
.BlogArchive__postSearchBtnListItem {
	@include sp {
		border: 1px solid $pink;
	};
	@include pc {
		border: 1px solid $pink;
		border-width: 0 1px 1px 0;
		width: flexBox(5, 0px);
	};
}
.BlogArchive__postSearchBtnLink {
	display: block;
	@include sp {
		padding: 12px 8px;
	};
	@include pc {
		padding: 12px 8px;
	};
}
.BlogArchive__postSearchBtnLinkText {
	color: $pink;
	text-align: center;
	@include sp {
		font-size: 1.4rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.6rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// 左上のボタン
.BlogArchive__postSearchBtnListItem:first-of-type {
	@include sp {
	};
	@include pc {
		border-radius: 6px 0 0 0;
	};
}
// 右上のボタン
.BlogArchive__postSearchBtnListItem:nth-of-type(5n) {
	@include sp {
	};
	@include pc {
		border-radius: 0 6px 0 0;
	};
}
// 左下のボタン
.BlogArchive__postSearchBtnListItem:nth-of-type(6n) {
	@include sp {
	};
	@include pc {
		border-radius: 0 0 0 6px;
	};
}
// 右下のボタン
.BlogArchive__postSearchBtnListItem:last-of-type {
	@include sp {
	};
	@include pc {
		border-radius: 0 0 6px 0;
	};
}
// -----▲ 50音順（あいうえお順）のボタン ▲-----

// ----------▲▲ 「ボタン」のスタイル ▲▲----------
