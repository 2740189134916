@charset "utf-8";

// サイト全体の構造を制御するためのスタイルは、この「_layout.scssに」まとめる。


// ----------▼▼ base ▼▼----------

body {
	overscroll-behavior-y: none;
	position: relative;
}

// ヘッダー全体
.Header {
	position: fixed;
	z-index: 10;
	@include sp {
		height: 49px;
		left: 0;
		top: 0;
		width: 100%;
	};
	@include pc {
		height: 64px;
		left: 0;
		top: 0;
		width: 100%;
	};
}

// フッター全体
.Footer {
	@include sp {
	};
	@include pc {
		margin-top: auto;
	};
}

// ----------▲▲ base ▲▲----------
