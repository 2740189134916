@charset "utf-8";

// 「プライバシーポリシー」ページのスタイルです。


// ---------▼▼ 20「プライバシーポリシー」privacy ▼▼----------

.PagePrivacy {

	// -----▼ このサイトについて ▼-----
	.About__thisSite {
		@include sp {
		};
		@include pc {
		};
	}
	.About__thisSite .sectionContainer {
		@include sp {
		};
		@include pc {
		};
	}
	.Privacy__policyContent {
		@include sp {
		};
		@include pc {
		};
	}
	// プライバシーポリシーについての説明文
	.Privacy__policyAbout {
		@include sp {
		};
		@include pc {
		};
	}
	.Privacy__policyAboutText {
		@include sp {
		};
		@include pc {
		};
	}
	// プライバシーポリシーの条文
	.Privacy__policyAbout + .Privacy__policyArticle {
		@include sp {
			margin-top: 48px;
		};
		@include pc {
			margin-top: 64px;
		};
	}
	.Privacy__policyArticle {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ このサイトについて ▲-----

}

// ---------▲▲ 20「プライバシーポリシー」privacy ▲▲----------
