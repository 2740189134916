@charset "utf-8";

// 「お知らせ（個別詳細ページ）」のスタイルです。


// ---------▼▼ 12「お知らせ（個別詳細ページ）」single-news ▼▼----------

.PageSingleNews {

	// -----▼ 記事のヘッダー ▼-----
	.BlogSingle__postHeader {
		border-bottom: 1px solid $gray_c;
		@include sp {
			padding-bottom: 32px;
		};
		@include pc {
			padding-bottom: 40px;
		};
	}
	.BlogSingle__postHeaderContent {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTimeAndTag {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTime {
		@include sp {
		};
		@include pc {
		};
	}
	.BlogSingle__postTagList {
		@include sp {
			margin-left: 24px;
		};
		@include pc {
			margin-left: 24px;
		};
	}
	.BlogSingle__postTitle {
		font-weight: $medium;
		@include sp {
			font-size: 2.2rem;
			letter-spacing: $lsAV50;
			line-height: 1.5;
			margin-top: 24px;
		};
		@include pc {
			font-size: 3.2rem;
			letter-spacing: $lsAV80;
			line-height: 1.5;
			margin-top: 32px;
		};
	}
	.BlogSingle__postContent.-news {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 記事のヘッダー ▲-----

}

// ---------▲▲ 12「お知らせ（個別詳細ページ）」single-news ▲▲----------
