@charset "utf-8";

// 「補助金・助成金情報、コラム（検索結果ページ）」のスタイルです。


// ---------▼▼ 17「補助金・助成金情報、コラム（検索結果ページ）」search ▼▼----------

.PageArchiveSubsidyAndColumn {

	background-color: $blue_12pct;
	@include sp {
	};
	@include pc {
	};

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// -----▼ 補助金・助成金情報、コラム（検索フォーム） ▼-----
	.FormSearch {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 補助金・助成金情報、コラム（検索フォーム） ▲-----

	// -----▼ 「補助金・助成金情報、コラム」一覧 ▼-----
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 「補助金・助成金情報、コラム」一覧 ▲-----

}

// ---------▲▲ 17「補助金・助成金情報、コラム（検索結果ページ）」search ▲▲----------
