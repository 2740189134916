@charset "utf-8";

// サイトのデザインの基礎となる、汎用的な値を変数としてこのファイルにすべて定義する。

$sp: 767px;  // スマホ
$tb: 768px;  // タブレット
$pc: 768px;  // パソコン
$retina: 2;  // Retinaディスプレイ（画像用）
$iPhone5: 320px;  // スマホ
@mixin sp {
	@media screen and (max-width: ($sp)) {
		@content;
	}
}
@mixin tb {
	@media screen and (min-width: ($tb)) {
		@content;
	}
}
@mixin pc {
	@media screen and (min-width: ($pc)) {
		@content;
	}
}
@mixin retina {
	@media (-webkit-device-pixel-ratio: ($retina)) {
		@content;
	}
}
// 「iPhone5（横幅320px）」用の修正スタイル
@mixin iPhone5 {
	@media screen and (max-width: ($iPhone5)) {
		@content;
	}
}
// 「IE11」用の修正スタイル
@mixin ie {
	@media all and (-ms-high-contrast: none) {
		@content;
	}
}
// 画面の横幅が767px以上かつ1200px未満のとき
@mixin pcContentWidth {
	@media screen and (min-width: ($pc)) and (max-width: 1199px) {
		@content;
	}
}


// ----------▼▼ font-family ▼▼----------

// -----▼ Noto Sans JP ▼-----
// レギュラー、ミディアム、ボールドの3種類
// @import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@font-face {
	font-display: swap;
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	src:
		url(../font/NotoSansJP/NotoSansCJKjp-Regular.eot),
		url(../font/NotoSansJP/NotoSansCJKjp-Regular.woff);
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	src:
		url(../font/NotoSansJP/NotoSansCJKjp-Medium.eot),
		url(../font/NotoSansJP/NotoSansCJKjp-Medium.woff);
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	src:
		url(../font/NotoSansJP/NotoSansCJKjp-Bold.eot),
		url(../font/NotoSansJP/NotoSansCJKjp-Bold.woff);
}
$font-noto-sans-jp: 'Noto Sans JP', sans-serif;
.font-noto-sans-jp {
	font-family: 'Noto Sans JP', sans-serif;
}
// -----▲ Noto Sans JP ▲-----

// -----▼ Barlow ▼-----
// レギュラー、ミディアム、セミボールド、ボールドの4種類
// @import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap);
@font-face {
	font-display: swap;
	font-family: 'Barlow', sans-serif;
	font-style: normal;
	font-weight: 400;
	src: url(../font/Barlow/Barlow-Regular.ttf);
}
@font-face {
	font-display: swap;
	font-family: 'Barlow', sans-serif;
	font-style: normal;
	font-weight: 500;
	src: url(../font/Barlow/Barlow-Medium.ttf);
}
@font-face {
	font-display: swap;
	font-family: 'Barlow', sans-serif;
	font-style: normal;
	font-weight: 600;
	src: url(../font/Barlow/Barlow-SemiBold.ttf);
}
@font-face {
	font-display: swap;
	font-family: 'Barlow', sans-serif;
	font-style: normal;
	font-weight: 700;
	src: url(../font/Barlow/Barlow-Bold.ttf);
}
$font-barlow: 'Barlow', sans-serif;
.font-barlow {
	font-family: 'Barlow', sans-serif;
}
// -----▲ Barlow ▲-----

// 代替フォントの適用
html {font-display: swap;}

// ----------▲▲ font-family ▲▲----------


// ----------▼▼ font-size ▼▼----------

html {
	// ルートのフォントサイズ。この数値は変えない！
	// 主要ブラウザのデフォルトのフォントサイズは 16px = 100% = 1rem 。
	// rem計算を容易（ 1rem = 10px ）にするために
	// デフォルトフォントサイズ = 62.5% に変更している
	// （ 16pxの62.5% = 10px = 1rem ）。
	// デフォルトフォントサイズを「6.25%」にしないのは、
	// ブラウザ側で10px未満の最小フォントサイズが設定されている場合に
	// その最小フォントサイズの数値がすべてのrem指定の数値に影響してしまうから
	// （通常「6.25%」は「1px」となるが、最小フォントサイズが設定されていると
	// 1rem = 最小フォントサイズの数値 となってしまう）。
	font-size: 62.5%;
}
body {
	// こっちの数値がサイトのデフォルトのフォントサイズになる。
	// なので本文の基準フォントサイズによって書き変えること！
	// まあでも大体は1.4emか1.6emの2択になると思う。
	// 単位が「em」なのはChromeのバグ対策のため
	// （bodyにremを指定していると、Chromeはbodyをルートとして解釈してしまう）。
	font-size: 1.6em !important;
}
body {
	// フォント設定をいろいろ追加...
	// -moz-osx-font-smoothing: grayscale;
	// -webkit-font-smoothing: antialiased;
	// font-feature-settings: "kern";
	// font-kerning: normal;
}

// -----▼ var ▼-----

// -----▲ var ▲-----

// ----------▲▲ font-size ▲▲----------


// ----------▼▼ font-weight ▼▼----------

// -----▼ var ▼-----
$medium: 500;
$semibold: 600;
// -----▲ var ▲-----

// ----------▲▲ font-weight ▲▲----------


// ----------▼▼ color ▼▼----------

// -----▼ var ▼-----

$pink: #e4007f;
$pink_12pct: #fce0ef;
$pink_hover: $pink_12pct;
$pink_hsb_b65: #a6005b;

$blue: #0076dd;
$blue_hsb_b63: #0056a1;
$blue_hsb_b24: #00213d;
$blue_hover: $blue_hsb_b63;
$blue_12pct: #e0eefb;
$blue_8pct: #ebf4fd;

$charcoal: #5b5e6d;

$twitter: #1d9bf0;
$twitter_hsb_b70: #1574b3;
$twitter_hover: $twitter_hsb_b70;

$facebook: #1877f2;
$facebook_hsb_b71: #1259b5;
$facebook_hover: $facebook_hsb_b71;

$line: #00b900;
$line_hsb_b49: #007d00;
$line_hover: $line_hsb_b49;

$hatebu: #00a4de;
$hatebu_hsb_b63: #0076a1;
$hatebu_hover: $hatebu_hsb_b63;

$note: #2cb696;
$note_8percent: #eef9f7;



$red: #d40000;

$red_hsb_bm16: #a80a12;
$red_8percent: #fcecec;
$red_12percent: #fae1e3;
$red_hover: $red_hsb_bm16;


$black_0: #000;
$black_2: #222;
$black_3: #333;
$black_shadow: #000;
$black_5: #555;
$black_6: #666;
$black_7: #777;
$black_8: #888;
$black: $black_2;
$black_bg: $black_3;
$black_rgb: 0, 0, 0;
$white: #fff;
$white_demi: #fafafa;

$gray_lightBlue: #f6f7f7;
$gray_fa: #fafafa;
$gray_f8: #f8f8f8;
$gray_f5: #f5f5f5;
$gray_f4: #f4f4f4;
$gray_f1: #f1f1f1;
$gray_e: #eee;
$gray_ea: #eaeaea;
$gray_e6: #e6e6e6;
$gray_e4: #e4e4e4;
$gray_d: #ddd;
$gray_d9: #d9d9d9;
$gray_c: #ccc;
$gray_b: #bbb;
$gray_a: #aaa;
$gray_9: #999;
$gray_8: #888;
$gray_7: #777;
$gray_5: #555;
$gray_4: #444;

$ghost: rgba(0, 0, 0, 0);

$red: #d40000;
$red_text: $red;
$red_6pct: #fdf0f0;
$red_8pct: #fcebeb;
$red_price: #de2020;

$yellow_marker: #FFFA7C;

// 各種ベースカラー
$body-text-color: $black;  // body全体の文字色
$body-bg-color: $white;  // body全体の背景色
$form-placeholder-color: $gray_b;  // フォームのプレースホルダーの文字色

// メインカラーのコンポーネントへの反映
$main-color: $pink;  //// メインカラー
$link-color: $pink;  // リンクの色
$link-hover-color: $main-color;  // リンクのホバー時の色
$tag-color: $gray_c;  // タグの色
$list-mark-color: $main-color;  // リストのマークの色
$table-color: $main-color;  // テーブルの色
$breadcrumb-text-color: $black;  // パンくずリストの非リンクテキストの文字色
$breadcrumb-text-hover-color: $black;  // パンくずリストのリンクテキストのホバー時の文字色
$component-shadow: $black_0;  // コンポーネントの影の色

// -----▲ var ▲-----

// ----------▲▲ color ▲▲----------


// ----------▼▼ letter-spacing ▼▼----------

// -----▼ var ▼-----
$lsAV0: 0;
$lsAV25: .025em;
$lsAV50: .05em;
$lsAV75: .075em;
$lsAV80: .08em;
$lsAV100: .1em;
$lsAV120: .12em;
$lsAV150: .15em;
$lsAV200: .2em;
$lsAV250: .25em;
$lsAV300: .3em;
$lsAV350: .35em;
$lsAV400: .4em;
$lsAV450: .45em;
$lsAV500: .5em;
// -----▲ var ▲-----

// ----------▲▲ letter-spacing ▲▲----------
