@charset "utf-8";

// 「お問い合わせ（フォーム）」ページのスタイルです。


// ---------▼▼ 18「お問い合わせ（フォーム）」form-contact ▼▼----------

.PageFormContact {

	// -----▼ お問い合わせフォーム ▼-----
	.FormContact__mail {
		@include sp {
		};
		@include pc {
		};
	}
	.FormContact__mail .sectionContainer {
		@include sp {
			padding-bottom: 32px;
		};
		@include pc {
			padding-bottom: 40px;
		};
	}
	.FormContact__mailContent {
		@include sp {
		};
		@include pc {
		};
	}
	.FormContact__mailInfo {
		@include sp {
		};
		@include pc {
		};
	}
	.FormContact__mailInfoTitle {
		@include sp {
		};
		@include pc {
		};
	}
	.FormContact__mailInfoText {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 24px;
		};
	}

	// メールフォーム
	.FormContact__mailForm {
		@include sp {
			margin-top: 64px;
		};
		@include pc {
			margin-top: 80px;
		};
	}

	// ※メールフォームのスタイルは以下のscssファイルに分割して記述
	// 「_form-component.scss」
	// 「_form-layout.scss」
	// 「_form-mail.scss」

	// -----▲ お問い合わせフォーム ▲-----

}

// ---------▲▲ 18「お問い合わせ（フォーム）」form-contact ▲▲----------
