@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 入力項目「セレクトボックス」


// ----------▼▼ 入力項目「セレクトボックス」のスタイル ▼▼----------

// セレクトボックス
// セレクトボックスのアイコン
.form-list-item-data-select-wrap {
	@include icon;
}
.form-list-item-data-select-wrap::before,
.form-list-item-data-select-wrap::after {
	pointer-events: none;
	z-index: 1;
}
// 上下の矢印マーク
.form-list-item-data-select-wrap::after {
	background-image: url(../image/icon/icon_arrow_form-select.svg);
	@include sp {
		height: 10px;
		right: 8px;
		top: calc(50% - 4px);
		top: 17px;
		width: 12px;
	};
	@include pc {
		height: 10px;
		right: 16px;
		top: calc(50% - 4px);
		width: 12px;
	};
	@include iPhone5 {
		height: 10px;
		right: 8px;
		top: calc(50% - 4px);
		width: 12px;
	};
}
.form-list-item-data-select-wrap .form-list-item-data-content.-select {
	margin-top: 0;
}
// 入力欄（セレクトボックス）
.form-list-item-data-content.-select {
	appearance: none;
	@include sp {
		height: 40px;
		padding: 0 32px 0 12px;
	};
	@include pc {
		cursor: pointer;
		height: 50px;
		padding: 0 48px 0 16px;
	};
	@include iPhone5 {
		padding: 0 10px;
	};
}

// ----------▲▲ 入力項目「セレクトボックス」のスタイル ▲▲----------
