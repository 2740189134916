@charset "utf-8";

// 「補助金・助成金情報（一覧ページ）」のスタイルです。


// ---------▼▼ 04「補助金・助成金情報（一覧ページ）」archive-subsidy ▼▼----------

.PageArchiveSubsidy {

	background-color: $blue_12pct;
	@include sp {
	};
	@include pc {
	};

	&.pageContainer {
		@include sp {
		};
		@include pc {
		};
	}

	// -----▼ 補助金・助成金情報（検索フォーム） ▼-----
	.FormSearch {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 補助金・助成金情報（検索フォーム） ▲-----

	// -----▼ 「補助金・助成金情報」一覧 ▼-----
	.Blog__post {
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 「補助金・助成金情報」一覧 ▲-----

}

// ---------▲▲ 04「補助金・助成金情報（一覧ページ）」archive-subsidy ▲▲----------
