@charset "utf-8";

// 「フッター」の「コピーライト」のスタイルです。


// ----------▼▼ コピーライト .FtCopyright ▼▼----------

// コピーライト 全体
.FtCopyright {
	background-color: $black_3;
	@include sp {
	};
	@include pc {
	};
}
.FtCopyright__content {
	position: relative;
	@include sp {
	};
	@include pc {
	};
}

// -----▼ コピーライト ▼-----
// コピーライト
.FtCr__copyright {
	@include sp {
		padding: 8px 0;
	};
	@include pc {
		padding: 10px 0;
	};
}
.FtCr__copyrightText {
	color: $white;
	text-align: center;
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
	};
}
// -----▲ コピーライト ▲-----

// ----------▲▲ コピーライト .FtCopyright ▲▲----------
