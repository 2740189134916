@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 入力項目「日付のカレンダー入力」


// ----------▼▼ 入力項目「日付のカレンダー入力」のスタイル ▼▼----------

// 日付のカレンダー入力
// カレンダーのアイコン
.form-list-item-data-calendar-wrap {
	@include icon;
	@include sp {
	};
	@include pc {
	};
}
.form-list-item-data-calendar-wrap::before,
.form-list-item-data-calendar-wrap::after {
	pointer-events: none;
	z-index: 1;
}
.form-list-item-data-calendar-wrap::after {
	background-image: url(../image/icon/icon_calendar_gray.svg);
	@include sp {
		height: 24px;
		right: 8px;
		top: calc(50% - 12px);
		width: 24px;
	};
	@include pc {
		height: 24px;
		left: 16px;
		top: calc(50% - 12px);
		width: 24px;
	};
	@include iPhone5 {
		height: 20px;
		right: 8px;
		top: calc(50% - 10px);
		width: 20px;
	};
}
.form-list-item-data-calendar-wrap .form-list-item-data-content.-calendar {
	margin-top: 0;
	@include sp {
	};
	@include pc {
	};
}
.form-list-item-data-calendar-wrap .form-list-item-data-content.-text {
	@include sp {
	};
	@include pc {
		cursor: pointer;
		text-align: right;
	};
}
// 入力項目全体（日付）
.form-list-item-data-content.-calendar {
	@include sp {
		height: 40px;
		padding: 0 32px 0 12px;
	};
	@include pc {
		cursor: pointer;
		height: 50px;
		padding: 0 48px 0 16px;
	};
}
// フォーカス時
.form-list-item-data-content.-calendar:focus {
	@include sp {
		padding: 7px 11px 7px 11px;
	};
	@include pc {
		padding: 7px 15px 7px 15px;
	};
}
// ブラウザデフォルトのカレンダーのアイコンを無効化
input[type=date] {
	position: relative;
	@include sp {
	};
	@include pc {
	};
}
input[type=date]::-webkit-calendar-picker-indicator {
	opacity: 0;
	position: absolute;
	@include sp {
		height: 100%;
		width: 100%;
	};
	@include pc {
		height: 100%;
		width: 100%;
	};
}

// ----------▲▲ 入力項目「日付のカレンダー入力」のスタイル ▲▲----------
