@charset "utf-8";

// 要素の位置関係を制御するための汎用スタイルは、この「_base.scssに」まとめる。


// ----------▼▼ wrapper ▼▼----------

// -----▼ class ▼-----
.wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
	@include sp {
	};
	@include pc {
		min-height: 100%;
		min-width: 1200px;
	};
}
// -----▲ class ▲-----

// ----------▲▲ wrapper ▲▲----------


// ----------▼▼ .pageContainer ▼▼----------
// 固定ページのコンテンツ部分、最上部・最下部の余白
.pageContainer {
	@include sp {
		padding: 49px 0 64px;
	};
	@include pc {
		padding: 64px 0 80px;
	};
}
.pageContainer.Blog.BlogArchive {
	@include sp {
		padding: 32px 0 64px;
	};
	@include pc {
		padding: 56px 0 120px;
	};
}
.pageContainer.Blog.BlogSingle {
	@include sp {
		padding: calc(49px + 40px) 0 64px;
	};
	@include pc {
		padding: calc(64px + 48px) 0 120px;
	};
}
.pageContainer.-thanks {
	@include sp {
		padding: 32px 0 64px;
	};
	@include pc {
		padding: 32px 0 120px;
	};
}
// ----------▲▲ .pageContainer ▲▲----------


// ----------▼▼ .sectionContainer ▼▼----------
// ページ内のセクション全体の最上部・最下部の余白
.pageContainer .section:first-of-type .sectionContainer {
	@include sp {
		padding: 0 0 24px;
	};
	@include pc {
		padding: 0 0 40px;
	};
}
.pageContainer .section + .section .sectionContainer {
	@include sp {
		padding: 24px 0 24px;
	};
	@include pc {
		padding: 40px 0 40px;
	};
}
.pageContainer .section:last-of-type .sectionContainer {
	@include sp {
		padding: 24px 0 0;
	};
	@include pc {
		padding: 40px 0 0;
	};
}
.pageContainer .section:only-of-type .sectionContainer {
	@include sp {
		padding: 0 0 0;
	};
	@include pc {
		padding: 0 0 0;
	};
}
// ----------▲▲ .sectionContainer ▲▲----------


// ----------▼▼ .anchorMargin ▼▼----------
// アンカーリンクがあるときは、飛び先（idを付けている項目）にこのスタイルを当てて
// ヘッダーメニューとコンテンツが被らないようにする。
.anchorMargin::before {
	content: "";
	display: block;
	visibility: hidden;
	@include sp {
		height: calc(50px + 16px);
		margin-top: calc(calc(50px + 16px) * -1);
	};
	@include pc {
		height: calc(80px + 24px);
		margin-top: calc(calc(80px + 24px) * -1);
	};
}
// ----------▲▲ .anchorMargin ▲▲----------


// ----------▼▼ content-size ▼▼----------

// -----▼ class ▼-----
//  SP
@include sp {
	.contentBox,
	.contentBoxSp,
	.contentBoxXS,
	.contentBoxXSSp,
	.contentBoxS,
	.contentBoxSSp,
	.contentBoxM,
	.contentBoxMSp,
	.contentBoxL,
	.contentBoxLSp {
		margin-left: 5% !important;
		margin-right: 5% !important;
	}
}
//  PC
@include pc {
	.contentBox,
	.contentBoxPc,
	.contentBoxXS,
	.contentBoxXSPc,
	.contentBoxS,
	.contentBoxSPc,
	.contentBoxM,
	.contentBoxMPc,
	.contentBoxL,
	.contentBoxLPc {
		margin-left: auto !important;
		margin-right: auto !important;
		padding: 0;
	}
	.contentBox,
	.contentBoxPc {
		width: 1200px;
	}
	.contentBoxXS,
	.contentBoxSxPc {
		width: 400px;
	}
	.contentBoxS,
	.contentBoxSPc {
		width: 640px;
	}
	.contentBoxM,
	.contentBoxMPc {
		width: 800px;
	}
	.contentBoxL,
	.contentBoxLPc {
		width: 1000px;
	}
}
// -----▲ class ▲-----

// ----------▲▲ content-size ▲▲----------


/*-----▼ flexBox ▼-----*/
// カラム数（$column）とカラム間のマージン（$margin）を入力するだけで
// カラムひとつあたりの横幅を自動計算してくれる関数。
// 例）カラム数を4、カラム間のマージンを40pxにしたい場合のwidthの指定方法
// → width: flexbox(4, 40px);

// -----▼ function ▼-----
@function flexBox($column, $margin) {
	$marginAll: $margin * $column;
	$columnWidth: calc(calc(100% - #{$marginAll}) / #{$column});
	@return $columnWidth;
};
// -----▲ function ▲-----

/*-----▲ flexBox ▲-----*/
