@charset "utf-8";

// トップページのスタイルです。


// ---------▼▼ 01「トップページ」front-page.php ▼▼----------

// -----▼ トップページのコンテンツ .MainVisual 〜 .Top-Workshop ▼-----
// 上下の余白
.pageContainer.-top {
	@include sp {
		padding: 49px 0 80px;
	};
	@include pc {
		padding: 64px 0 120px;
	};
}
// -----▲ トップページのコンテンツ .MainVisual 〜 .Top-Workshop ▲-----

// -----▼ メインビジュアル .MainVisual ▼-----
.MainVisual {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .MainVisual .sectionContainer {
	@include sp {
		padding: 0 0 12px;
	};
	@include pc {
		padding: 0 0 28px;
	};
}
.MainVisual__content {
	@include sp {
	};
	@include pc {
	};
}
// スライダー（CDジャケットの画像）
.MainVisual__slider {
	@include sp {
		margin-top: 24px;
	};
	@include pc {
		margin-top: 32px;
	};
}
// -----▲ メインビジュアル .MainVisual ▲-----

// -----▼ 「特集」 .Top-SpecialFeature ▼-----
.Top-SpecialFeature {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Top-SpecialFeature .sectionContainer {
	@include sp {
		padding: 12px 0 24px;
	};
	@include pc {
		padding: 28px 0 32px;
	};
}
.Top-SpecialFeature__content {
	@include sp {
	};
	@include pc {
	};
}
.Top-SpecialFeature__specialFeature {
	@include sp {
	};
	@include pc {
	};
}
.Top-SpecialFeature__specialFeatureTitle {
	@include sp {
	};
	@include pc {
	};
}
.Top-SpecialFeature__specialFeatureTitle + .Top-SpecialFeature__specialFeatureArticles {
	@include sp {
		margin-top: 20px;
	};
	@include pc {
		margin-top: 22px;
	};
}
.Top-SpecialFeature__specialFeatureArticles {
	@include sp {
	};
	@include pc {
	};
}
.card-specialFeature-list {
	@include sp {
	};
	@include pc {
	};
}
// 新聞のPDF
.Top-SpecialFeature__specialFeatureArticles + .Top-SpecialFeature__specialFeatureSubContent {
	@include sp {
		margin-top: 24px;
	};
	@include pc {
		margin-top: 32px;
	};
}
.Top-SpecialFeature__specialFeatureSubContent {
	@include sp {
	};
	@include pc {
		text-align: center;
	};
}
.Top-SpecialFeature__specialFeatureSubContentLink {
	font-weight: bold;
	@include sp {
		font-size: 1.8rem;
	};
	@include pc {
		font-size: 2.0rem;
	};
}
// -----▲ 「特集」 .Top-SpecialFeature ▲-----

// -----▼ 「メディア情報」 .Top-MediaInfo ▼-----
.Top-MediaInfo {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Top-MediaInfo .sectionContainer {
	@include sp {
		padding: 24px 0 24px;
	};
	@include pc {
		padding: 36px 0 36px;
	};
}
.Top-MediaInfo__content {
	@include sp {
	};
	@include pc {
	};
}
.Top-MediaInfo__mediaInfo {
	@include sp {
	};
	@include pc {
	};
}
.Top-MediaInfo__mediaInfoTitle {
	@include sp {
	};
	@include pc {
	};
}
.Top-MediaInfo__mediaInfoTitle + .Top-MediaInfo__mediaInfoArticles {
	@include sp {
		margin-top: 20px;
	};
	@include pc {
		margin-top: 22px;
	};
}
.Top-MediaInfo__mediaInfoArticles {
	@include sp {
	};
	@include pc {
	};
}
.card-news-list {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「メディア情報」 .Top-MediaInfo ▲-----

// -----▼ 「キャンペーン＆イベント情報」 .Top-CampaignEvent ▼-----
.Top-CampaignEvent {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Top-CampaignEvent .sectionContainer {
	@include sp {
		padding: 24px 0 24px;
	};
	@include pc {
		padding: 36px 0 48px;
	};
}
.Top-CampaignEvent__content {
	@include sp {
	};
	@include pc {
	};
}
.Top-CampaignEvent__campaignEvent {
	@include sp {
	};
	@include pc {
	};
}
.Top-CampaignEvent__campaignEventTitle {
	@include sp {
	};
	@include pc {
	};
}
.Top-CampaignEvent__campaignEventTitle + .Top-CampaignEvent__campaignEventArticles {
	@include sp {
		margin-top: 20px;
	};
	@include pc {
		margin-top: 22px;
	};
}
.Top-CampaignEvent__campaignEventArticles {
	@include sp {
	};
	@include pc {
	};
}
.card-campaignEvent-list {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「キャンペーン＆イベント情報」 .Top-CampaignEvent ▲-----

// -----▼ 「最新動画」 .Top-YouTube ▼-----
.Top-YouTube {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Top-YouTube .sectionContainer {
	@include sp {
		padding: 24px 0 40px;
	};
	@include pc {
		padding: 40px 0 64px;
	};
}
.Top-YouTube__content {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTube {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTubeTitle {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTubeTitle + .Top-YouTube__youTubeArticles {
	@include sp {
		margin-top: 20px;
	};
	@include pc {
		margin-top: 22px;
	};
}
.Top-YouTube__youTubeArticles {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-list {
	@include sp {
	};
	@include pc {
	};
}
// メロディーレコーズのYouTubeチャンネル
.Top-YouTube__youTubeArticles + .Top-YouTube__youTubeChannel {
	@include sp {
		margin-top: 32px;
	};
	@include pc {
		margin-top: 56px;
	};
}
.Top-YouTube__youTubeChannel {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTubeChannelBanner {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTubeChannelBannerLink {
	@include sp {
	};
	@include pc {
	};
}
.Top-YouTube__youTubeChannelBannerImg {
	@include sp {
		aspect-ratio: 4.12 / 1;
		box-shadow: 0 4px 12px rgba($component-shadow, .12);
		height: 100%;
		width: 100%;
	};
	@include pc {
		aspect-ratio: 4.12 / 1;
		box-shadow: 0 4px 12px rgba($component-shadow, .12);
		height: 136px;
		width: 560px;
	};
}
// -----▲ 「最新動画」 .Top-YouTube ▲-----

// -----▼ 「中小企業向けコラム」 .Top-Column ▼-----
.Top-Column {
	@include sp {
	};
	@include pc {
	};
}
.pageContainer.-top .Top-Column .sectionContainer {
	@include sp {
		padding: 32px 0 0;
	};
	@include pc {
		padding: 48px 0 0;
	};
}
.Top-Column__content {
	@include sp {
	};
	@include pc {
	};
}
.Top-Column__column {
	@include sp {
	};
	@include pc {
	};
}
.Top-Column__columnTitle {
	@include sp {
	};
	@include pc {
	};
}
.Top-Column__columnArticles {
	@include sp {
		margin-top: 24px;
	};
	@include pc {
		margin-top: 40px;
	};
}
.Top-Column__columnMoreLinkBtnContainer {
	@include sp {
		margin-top: 32px;
	};
	@include pc {
		margin-top: 48px;
	};
}
.Top-Column__columnMoreLinkBtn {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「中小企業向けコラム」 .Top-Column ▲-----

// ---------▲▲ 01「トップページ」front-page.php ▲▲----------
