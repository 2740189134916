@charset "utf-8";

// ブログの記事本文に当てるスタイルのテンプレートです。


// ↓wysiwygエディタで入力させたい項目にこのclassを当てること。
.wysiwyg {

	// 本文の最初の要素の上マージンをリセット
	& > *:first-child {
		margin-top: 0;
	}
	// 本文の最後の要素の下マージンをリセット
	& > *:last-child {
		margin-bottom: 0;
	}

	// ---------▼▼ 「見出し」のスタイル ▼▼----------
	// ----▼ ブログの見出し 2〜6 ▼-----
	// 見出し2
	h2 {
		@include title-2;
		@include sp {
			margin-top: 56px;
		};
		@include pc {
			margin-top: 56px;
		};
	}
	// 見出し3
	h3 {
		@include title-3;
		@include sp {
			margin-top: 48px;
		};
		@include pc {
			margin-top: 40px;
		};
	}
	h3::before {
		@include title-3-mark;
		@include sp {
		};
		@include pc {
		};
	}
	// 見出し4
	h4 {
		@include title-4;
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	h4::before {
		@include title-4-mark;
		@include sp {
		};
		@include pc {
		};
	}
	// 見出し5
	h5 {
		@include title-5;
		@include sp {
			margin-top: 32px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	// 見出し6
	h6 {
		@include title-6;
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	// ----▲ ブログの見出し 1〜6 ▲-----

	// ---------▲▲ 「見出し」のスタイル ▲▲----------


	// ---------▼▼ 「文章」のスタイル ▼▼----------

	// ----▼ 通常の文章 ▼-----
	p {
		@include text;
		@include sp {
			margin-top: 30px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	p + p {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	h2 + p {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	h3 + p {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 24px;
		};
	}
	h4 + p {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	h5 + p,
	h6 + p {
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	ul + p,
	ol + p,
	ul.list-note + p,
	ol.list-note.-number + p {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 20px;
		};
	}
	// ----▲ 通常の文章 ▲-----

	// ----▼ テキストリンク（内部リンク） ▼-----
	p a {
		@include text-link;
		@include sp {
		};
		@include pc {
		};
	}
	p a {
		margin: 0 6px;
	}
	p a.-start {
		padding-left: 0;
	}
	// ----▲ テキストリンク（内部リンク） ▲-----

	// ----▼ テキストリンク（外部リンク） ▼-----
	p a.-tab {
		@include text-link-tab;
		@include sp {
		};
		@include pc {
		};
	}
	// ----▲ テキストリンク（外部リンク） ▲-----

	// ----▼ テキストの強調 ▼-----
	p strong {
		@include text-strong;
		@include sp {
		};
		@include pc {
		};
	}
	// ----▲ テキストの強調 ▲-----

	// ---------▲▲ 「文章」のスタイル ▲▲----------


	// ----------▼▼ 「リスト」のスタイル ▼▼----------

	// -----▼ 普通のリスト・連番のリスト・注釈 共通スタイル ▼-----
	ul,
	ol {
		@include sp {
			margin-top: 20px;
		};
		@include pc {
			margin-top: 20px;
		};
	}
	ul + ul,
	ul + ol,
	ol + ol,
	ol + ul {
		@include sp {
		};
		@include pc {
		};
	}
	ul > li,
	ol > li {
		@include list-text;
		@include sp {
		};
		@include pc {
			font-size: 1.6rem !important;
		};
	}
	ul > li,
	ul.-note:not(.-number) > li {
		@include ul-list-mark-padding;
		@include sp {
		};
		@include pc {
		};
	}
	ul > li + li,
	ol > li:not(:last-of-type)::after {
		@include list-item-margin-top-layer1;
		@include sp {
		};
		@include pc {
		};
	}
	ul > li ul,
	ul > li ol,
	ol > li ol,
	ol > li ul,
	ul > li ul > li + li,
	ul > li ol > li + li,
	ol > li ol > li + li,
	ol > li ul > li + li,
	ol > li ol> li:not(:last-of-type)::after {
		@include list-item-margin-top-layer2;
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 普通のリスト・連番のリスト・注釈 共通スタイル ▲-----

	// -----▼ 普通のリスト ▼-----
	ul > li::before {
		@include list-normal-mark;
		@include sp {
		};
		@include pc {
			top: calc(0.8em - 4px) !important;
		};
	}
	// -----▲ 普通のリスト ▲-----

	// -----▼ 連番付きリスト ▼-----
	ol {
		counter-reset: list-number;
		display: table;
		@include sp {
			margin-top: 30px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	ol > li {
		display: table-row;
		@include sp {
		};
		@include pc {
		};
	}
	ol > li::before {
		color: $list-mark-color;
		content: counter(list-number)".";
		counter-increment: list-number;
		display: table-cell;
		font-weight: bold;
		text-align: right;
		@include sp {
			font-size: inherit;
			padding-right: 8px;
		};
		@include pc {
			font-size: inherit;
			padding-right: 8px;
		};
	}
	ol > li::after {
		content: "";
		display: block;
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 連番付きリスト ▲-----

	// -----▼ 注釈（※印だけ） ▼-----
	// 注釈のコメ印
	ul {
		@include sp {
			margin-top: 30px;
		};
		@include pc {
			margin-top: 32px;
		};
	}
	ul.list-note > li::before {
		@include list-note-mark;
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 注釈（※印だけ） ▲-----

	// -----▼ 注釈（連番付き※印） ▼-----
	// 注釈の説明書き
	ol.list-note.-number {
		counter-reset: list-numNote;
	}
	// 注釈のコメ印
	ol.list-note.-number > li::before {
		@include list-note-number-mark;
		@include sp {
		};
		@include pc {
		};
	}
	// -----▲ 注釈（連番付き※印） ▲-----

	// ----------▲▲ 「リスト」のスタイル ▲▲----------


	// ----------▼▼ 「画像」のスタイル ▼▼----------
	figure.wp-block-image {
		@include sp {
			margin-top: 40px !important;
		};
		@include pc {
			margin-top: 48px !important;
		};
	}
	// ----------▲▲ 「画像」のスタイル ▲▲----------


	// ----------▼▼ 「動画」のスタイル ▼▼----------
	figure.wp-block-video {
		@include sp {
			margin-top: 40px !important;
		};
		@include pc {
			margin-top: 48px !important;
		};
	}
	figure.wp-block-video > video {
		display: block;
		margin: 0 auto;
		@include sp {
		};
		@include pc {
		};
	}
	// ----------▲▲ 「動画」のスタイル ▲▲----------


	// ----------▼▼ 「引用文」のスタイル ▼▼----------
	// 引用文全体
	blockquote {
		@include blockquote;
		@include sp {
		};
		@include pc {
		};
	}
	// 引用文の文章そのもの
	blockquote p {
		@include blockquote-text;
		@include sp {
		};
		@include pc {
			font-size: 1.6rem !important;
		};
	}
	// 引用元の情報
	cite {
		@include blockquote-cite;
	}
	// 「引用文全体」＋「引用元の情報」
	blockquote + cite {
		@include blockquote-and-blockquote-cite;
	}
	// ----------▲▲ 「引用文」のスタイル ▲▲----------


	// ----------▼▼ 「テーブル」のスタイル ▼▼----------

	// -----▼ 全ての表の共通スタイル ▼-----
	table {
		@include table;
		@include sp {
		};
		@include pc {
		};
	}
	// 表の行
	tr + tr {
		border-top: 1px dashed rgba($table-color, 1);
	}
	// 表の見出し
	th {
		@include table-heading;
		@include sp {
		};
		@include pc {
			font-size: 1.6rem !important;
		};
	}
	// 表のデータ
	td {
		@include table-data;
		@include sp {
		};
		@include pc {
			font-size: 1.6rem !important;
		};
	}
	th + td {
		border-left: 1px dashed rgba($table-color, 1);
	}
	// -----▲ 全ての表の共通スタイル ▲-----

	// -----▼ 表（見出し1列・データ1列） ▼-----
	.table-one-col {
		margin: 40px 0;
		@include sp {
		};
		@include pc {
		};
	}
	// 「表（見出し1列・データ1列）」の表の見出し
	.table-one-col th {
		@include sp {
			width: 20%;
		};
		@include pc {
			width: 30%;
		};
	}
	// 「表（見出し1列・データ1列）」の表のデータ
	.table-one-col td {
		@include sp {
			width: 80%;
		};
		@include pc {
			width: 70%;
		};
	}
	// -----▲ 表（見出し1列・データ1列） ▲-----

	// -----▼ 表（見出し1行・データ複数列） ▼-----
	// 「表（見出し1行・データ複数列）」の見出し行とデータ行の間
	thead + tbody {
		border-top: 2px solid rgba($table-color, 1);
	}
	// 「表（見出し1行・データ複数列）」の表の見出し
	.table-mult-col th {
		text-align: center;
	}
	// 「表（見出し1行・データ複数列）」の表の見出しと見出しの間
	th + th {
		border-left: 1px dashed rgba($table-color, 1);
	}
	// 「表（見出し1行・データ複数列）」の表のデータとデータの間
	td + td {
		border-left: 1px dashed rgba($table-color, 1);
	}
	// -----▼ スマホ版で横にスクロールする表にするときに使う ▼-----
	// スクロールする表の領域全体
	.table-scroll {
		@include sp {
		};
	}
	// -----▲ スマホ版で横にスクロールする表にするときに使う ▲-----
	// -----▲ 表（見出し1行・データ複数列） ▲-----

	// -----▼ 表（1行目と1列目が見出しで、残りのセルにデータ） ▼-----
	// -----▲ 表（1行目と1列目が見出しで、残りのセルにデータ） ▲-----

	// ----------▲▲ 「テーブル」のスタイル ▲▲----------


	// ----------▼▼ 「キャプション」のスタイル ▼▼----------
	// キャプション
	figcaption {
		@include figcaption;
		margin-bottom: 0;
		@include sp {
			margin-top: 12px;
		};
		@include pc {
			margin-top: 16px;
		};
	}
	// 「テーブル」直下のキャプション
	table + figcaption {
		text-align: center;
		@include sp {
		};
		@include pc {
		};
	}
	// 「画像」直下のキャプション
	figure.wp-block-image > figcaption {
		text-align: center;
		@include sp {
		};
		@include pc {
		};
	}
	// 埋め込みコンテンツ直下のキャプション
	figure.wp-block-embed > figcaption {
		text-align: center;
		@include sp {
		};
		@include pc {
		};
	}
	// ----------▲▲ 「キャプション」のスタイル ▲▲----------


	// ----------▼▼ 各種埋め込みコンテンツのスタイル ▼▼----------
	// 共通のスタイル
	figure.wp-block-embed {
		@include sp {
			margin-top: 40px !important;
		};
		@include pc {
			margin-top: 48px !important;
		};
	}
	// Twitter
	figure > .wp-block-embed__wrapper > .twitter-tweet {
		margin: 0 auto;
		@include sp {
		};
		@include pc {
		};
	}
	// YouTubeの動画
	figure.wp-block-embed-youtube > .wp-block-embed__wrapper > iframe {
		display: block;
		margin: 0 auto;
		@include sp {
		};
		@include pc {
		};
	}
	// Facebook
	figure.wp-block-embed-facebook > .wp-block-embed__wrapper {
		text-align: center;
		@include sp {
		};
		@include pc {
		};
	}
	figure.wp-block-embed-facebook > .wp-block-embed__wrapper > .fb-post.fb_iframe_widget > span {
		max-width: 100% !important;
		@include sp {
		};
		@include pc {
		};
	}
	// Instagram
	figure.wp-block-embed-instagram > .wp-block-embed__wrapper > iframe {
		margin: 0 auto !important;
		width: calc(100% - 1px) !important;
		@include sp {
		};
		@include pc {
		};
	}
	// WordPressで作成した記事
	figure.wp-block-embed-wordpress > .wp-block-embed__wrapper > iframe {
		display: block;
		margin: 0 auto !important;
		@include sp {
		};
		@include pc {
		};
	}
	// 「カスタムHTML」による「埋め込みコード（iframe）」
	iframe {
		@include sp {
			margin: 24px 0;
		};
		@include pc {
			margin: 40px 0;
		};
	}
	// ----------▲▲ 各種埋め込みコンテンツのスタイル ▲▲----------

}
