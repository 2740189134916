@charset "utf-8";

// 「パンくずリスト」のスタイルのテンプレートです。


// ----------▼▼ 「パンくずリスト」のスタイル ▼▼----------

// 「パンくずリスト」全体の背景
.breadcrumb {
	@include sp {
		margin-top: 50px;
	};
	@include pc {
		margin-top: 80px;
	};
}
// 「補助金・助成金情報」関係のページのときのみ背景色を青に
.breadcrumb.-blue {
	background-color: $blue_12pct;
	@include sp {
	};
	@include pc {
	};
}
.breadcrumbContainer {
	@include sp {
		display: none;
		line-height: 1.5;
		padding: 8px 0 8px;
	};
	@include pc {
		line-height: 1.5;
		padding: 10px 0 10px;
	};
}
// 「パンくずリスト」のリンク部分一つひとつ
.breadcrumb .breadcrumbContainer > span {
	position: relative;
	@include sp {
	};
	@include pc {
	};
}
.breadcrumb .breadcrumbContainer span {
	@include sp {
		font-size: 1.2rem;
	};
	@include pc {
		font-size: 1.2rem;
	};
}
// 「パンくずリスト」のテキスト
.breadcrumb .breadcrumbContainer span a span {
	word-break: break-all;
	@include sp {
	};
	@include pc {
	};
}
// リンク
.breadcrumb .breadcrumbContainer span a {
	color: $breadcrumb-text-color;
	text-decoration: underline;
	@include sp {
	};
	@include pc {
	};
}
// リンクのホバー時
.breadcrumb .breadcrumbContainer span a {
	@include sp {
		&:active span{
			opacity: .7;
			text-decoration: none;
		}
	};
	@include pc {
		&:hover span{
			opacity: .7;
			text-decoration: none;
		}
	};
}
.breadcrumb .breadcrumbContainer span:first-of-type a,
.breadcrumb .breadcrumbContainer > span + span {
	@include icon;
	@include sp {
	};
	@include pc {
	};
}
// 二番目以降のパンくずリンク
.breadcrumb .breadcrumbContainer > span + span {
	color: $breadcrumb-text-color;
	@include sp {
		margin-left: 12px;
		padding-left: 18px;
	};
	@include pc {
		margin-left: 12px;
		padding-left: 18px;
	};
}
.breadcrumb .breadcrumbContainer > span + span::before {
	background-image: url(../image/icon/icon_arrow_breadcrumb.svg);
	@include sp {
		height: 10px;
		left: 0;
		top: calc(0.75em - 6px);
		width: 6px;
	};
	@include pc {
		height: 10px;
		left: 0;
		top: calc(0.75em - 6px);
		width: 6px;
	};
}
// タイトルの改行を非表示にする
.breadcrumb br {
	display: none;
	@include sp {
	};
	@include pc {
	};
}

// ----------▲▲ 「パンくずリスト」のスタイル ▲▲----------
