@charset "utf-8";

// 【全フォーム共通】フォームの各種パーツのスタイル集です。
// 入力項目「テキスト」


// ----------▼▼ 入力項目「テキスト」のスタイル ▼▼----------

// テキスト
// 入力欄（単文）
.form-list-item-data-content.-text {
	@include sp {
		height: 48px;
		padding: 9px 12px;
	};
	@include pc {
		height: 48px;
		padding: 9px 16px;
	};
}
@include ie {
	*::-ms-backdrop, .form-list-item-data-content.-text {
		padding: 10px 24px 16px 24px;
	}
}
// 入力欄（文章）
.form-list-item-data-content.-textarea {
	display: block;
	@include sp {
		height: 200px;
		padding: 9px 12px;
	};
	@include pc {
		height: 200px;
		max-height: 400px;
		min-height: 200px;
		padding: 9px 16px;
	};
}
@include ie {
	*::-ms-backdrop, .form-list-item-data-content.-textarea {
		padding: 10px 24px 16px 24px;
	}
}

// ----------▲▲ 入力項目「テキスト」のスタイル ▲▲----------
