@charset "utf-8";

// 要素間のマージンのスタイルまとめです。
// ひととおり制作が済み、マージンのルールが見えてきたところで
// 各クラスにマージンを持たせるよう修正しましょう。


// ---------▼▼ 要素間のマージン ▼▼----------

	.title-2 + .text {
		@include sp {
			margin-top: 24px;
		};
		@include pc {
			margin-top: 32px;
		};
	}

	.text + .list,
	.list + .text {
		@include sp {
			margin-top: 16px;
		};
		@include pc {
			margin-top: 16px;
		};
	}

// ---------▲▲ 要素間のマージン ▲▲----------
