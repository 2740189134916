@charset "utf-8";

// 「グローバルナビゲーション」のアニメーション処理のスタイルです。


// ----------▼▼ グローバルナビゲーション gnavi ▼▼----------

// ヘッダーのナビゲーション部分全体
.GNav {
	@include sp {
	};
	@include pc {
	};
}

// -----▼ ハンバーガーメニューボタンのスタイル ▼-----
// ハンバーガーメニューのボタン
.GNav__menuBtn {
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューのボタン（ホバー時）
.GNav__menuBtn {
	@include sp {
		&:active .GNav__menuBtnTitle {
			color: $black;
			transition: color .1s;
		}
		&:active .GNav__menuBtnIcon span {
			background-color: $black;
			transition: background-color .1s, top .3s, transform .3s;
		}
	};
	@include pc {
		&:hover .GNav__menuBtnTitle {
		}
		&:hover .GNav__menuBtnIcon span {
		}
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字
.GNav__menuBtnTitle {
	transition: color .3s;
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字（開く前）
.GNav__menuBtn.-close .GNav__menuBtnTitle {
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューの「MENU」ボタンの「MENU」の文字（開いた前）
.GNav__menuBtn.-open .GNav__menuBtnTitle {
	@include sp {
	};
	@include pc {
	};
}
// ハンバーガーメニューのアイコン
.GNav__menuBtnIcon {
	@include sp {
	};
	@include pc {
	};
}
// メニュー（ハンバーガー・バツマーク共通）
.GNav__menuBtnIcon span {
	@include sp {
		left: calc(50% - 12px);
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
	@include pc {
		right: 0;
		&:nth-child(1) {
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
		}
	};
}
// メニュー（ハンバーガーの挙動）
.GNav__menuBtn.-close .GNav__menuBtnIcon span {
	background-color: $black;
	transition: transform .3s;
	@include sp {
		&:nth-child(1) {
			top: 16px;
		}
		&:nth-child(2) {
			top: 23.5px;
		}
		&:nth-child(3) {
			top: 31px;
		}
	};
	@include pc {
		&:nth-child(1) {
			top: 5px;
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
			top: 13px;
		}
	};
}
// メニュー（バツマークの挙動）
.GNav__menuBtn.-open .GNav__menuBtnIcon span {
	background-color: $black;
	transition: transform .3s;
	&:nth-child(1) {
		transform: rotate(315deg);
	}
	&:nth-child(2) {
	}
	&:nth-child(3) {
		transform: rotate(-315deg);
	}
	@include sp {
		&:nth-child(1) {
			top: 23.5px;
		}
		&:nth-child(2) {
			left: 50%;
			width: 0;
		}
		&:nth-child(3) {
			top: 23.5px;
		}
	};
	@include pc {
		&:nth-child(1) {
			top: 9px;
		}
		&:nth-child(2) {
		}
		&:nth-child(3) {
			top: 9px;
		}
	};
}
// -----▲ ハンバーガーメニューボタンのスタイル ▲-----

// -----▼ ハンバーガーメニュー（共通） ▼-----
.GNav__menuBtn + .GNav__content {
	// グロナビメニューのサイトマップ・グロナビのリンク
	.GNav__siteMapUnit {
		animation-delay: .4s;
		animation-duration: 1.2s;
		animation-fill-mode: both;
		animation-timing-function: cubic-bezier(0, .56, .44, 1);
		@include sp {
		};
		@include pc {
		};
	}
}
// -----▲ ハンバーガーメニュー（共通） ▲-----

// -----▼ ハンバーガーメニュー（開いたときのアニメーション） ▼-----

// グロナビメニューのSNSリンクのアニメーション（上方向にフェードイン）
@keyframes GNav__sns-fadeinUpSp {
	0% {
		opacity: 0;
		transform: translate3D(0, 15px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}
@keyframes GNav__sns-fadeinUpPc {
	0% {
		opacity: 0;
		transform: translate3D(0, 30px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}

// コピーライトのオレンジの線のアニメーション（線の生成）
@keyframes GNav__copyright-orangeLine-fadein {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

// グロナビメニューの背景画像のアニメーション（上方向にフェードイン）
@keyframes GNav__bgPhoto-fadeinUpSp {
	0% {
		opacity: 0;
		transform: translate3D(0, 15px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}
@keyframes GNav__bgPhoto-fadeinUpPc {
	0% {
		opacity: 0;
		transform: translate3D(0, 30px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}

// グロナビメニューの背景画像のアニメーション（上方向にフェードイン）
@keyframes GNav__siteMapLink-fadeinUpSp {
	0% {
		opacity: 0;
		transform: translate3D(0, 15px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}
@keyframes GNav__siteMapLink-fadeinUpPc {
	0% {
		opacity: 0;
		transform: translate3D(0, 30px, 0)
	}
	100% {
		opacity: 1;
		transform: translate3D(0, 0, 0)
	}
}

.GNav__menuBtn.-open + .GNav__content {
	// グロナビメニューのSNSリンク
	.GNav__snsList {
		@include sp {
			animation-name: GNav__sns-fadeinUpSp;
		};
		@include pc {
			animation-name: GNav__sns-fadeinUpPc;
		};
	}
	// コピーライト
	.GNav__copyright {
		@include sp {
		};
		@include pc {
		};
		&::after {
			animation-name: GNav__copyright-orangeLine-fadein;
			@include sp {
			};
			@include pc {
			};
		}
	}
	// グロナビメニューの背景画像
	.GNav__bgPhoto {
		@include sp {
			animation-name: GNav__bgPhoto-fadeinUpSp;
		};
		@include pc {
			animation-name: GNav__bgPhoto-fadeinUpPc;
		};
	}
	// グロナビメニューのサイトマップ・グロナビのリンク
	.GNav__siteMapUnit {
		@include sp {
			animation-name: GNav__siteMapLink-fadeinUpSp;
			&:nth-of-type(1) {
				animation-delay: .4s;
			}
			&:nth-of-type(2) {
				animation-delay: .42s;
			}
			&:nth-of-type(3) {
				animation-delay: .44s
			}
			&:nth-of-type(4) {
				animation-delay: .46s
			}
			&:nth-of-type(5) {
				animation-delay: .48s
			}
			&:nth-of-type(6) {
				animation-delay: .5s
			}
			&:nth-of-type(7) {
				animation-delay: .52s
			}
		};
		@include pc {
			animation-name: GNav__siteMapLink-fadeinUpPc;
			&:nth-of-type(1) {
				animation-delay: .4s;
			}
			&:nth-of-type(2) {
				animation-delay: .44s;
			}
			&:nth-of-type(3) {
				animation-delay: .48s
			}
			&:nth-of-type(4) {
				animation-delay: .52s
			}
			&:nth-of-type(5) {
				animation-delay: .56s
			}
			&:nth-of-type(6) {
				animation-delay: .6s
			}
			&:nth-of-type(7) {
				animation-delay: .64s
			}
		};
	}
}
// -----▲ ハンバーガーメニュー（開いたときのアニメーション） ▲-----

// -----▼ グロナビメニューの中身 ▼-----
// グロナビのメニュー内容全体
.GNav__content {
	@include sp {
		opacity: 0;
		transition: opacity .4s ease, visibility .4s ease;
		visibility: hidden;
	};
	@include pc {
	};
}
.GNav__menuBtn.-open + .GNav__content {
	@include sp {
		opacity: 1;
		visibility: visible;
	};
	@include pc {
	};
}
// グロナビのメニュー内容３種類のラッパー
.GNav__menu {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ グロナビメニューの中身 ▲-----

// -----▼ 「グロナビメニューのSNSリンク」＋「グロナビメニューの背景画像」 ▼-----
.GNav__snsAndBgPhoto {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「グロナビメニューのSNSリンク」＋「グロナビメニューの背景画像」 ▲-----

// -----▼ グロナビメニューのSNSリンク ▼-----
.GNav__sns {
	@include sp {
	};
	@include pc {
	};
}
.GNav__snsList {
	@include sp {
	};
	@include pc {
	};
}
.GNav__snsListItem {
	@include sp {
	};
	@include pc {
	};
}
.GNav__snsListItem + .GNav__snsListItem {
	@include sp {
	};
	@include pc {
	};
}
.GNav__snsLink {
	@include sp {
		&:active {
		}
	};
	@include pc {
		&:hover {
		}
	};
}
// -----▲ グロナビメニューのSNSリンク ▲-----

// -----▼ グロナビメニューのサイトマップ ▼-----
.GNav__siteMap {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapContainer {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit.-business,
.GNav__siteMapUnit.-news {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit + .GNav__siteMapUnit {
	@include sp {
	};
	@include pc {
	};
}
// グロナビのリンクのまとまり
.GNav__siteMapUnitList {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnitListItem {
	@include sp {
	};
	@include pc {
	};
}
// グロナビのリンク
.GNav__siteMapLink,
.GNav__siteMapTitle {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapLink {
	@include sp {
		&:active {
		}
	};
	@include pc {
		&:hover {
		}
	};
}
.GNav__siteMapTitle {
	@include sp {
	};
	@include pc {
	};
}

// ---▼ グロナビのリンク（1階層目） ▼---
// グロナビのリンクのまとまり
.GNav__siteMapUnitList.-layer1 {
	@include sp {
	};
	@include pc {
	};
}
// グロナビのリンク
.GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink,
.GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle {
	@include sp {
	};
	@include pc {
	};
}
// オレンジの線のアニメーション（共通）
.GNav__siteMapUnit > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	animation-direction: alternate;
	animation-duration: .2s;
	animation-timing-function: cubic-bezier(0, .56, .44, 1);
	transform-origin: right;
	@include sp {
	};
	@include pc {
	};
}
// オレンジの線のアニメーション（線の消滅）
@keyframes GNav__siteMapUnit-orangeLine-fadeout {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}
.GNav__siteMapUnit:not(.-currentPageCategory) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit:not(.-currentPageCategory) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before,
.GNav__siteMapUnit:not(.-active) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit:not(.-active) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	animation-name: GNav__siteMapUnit-orangeLine-fadeout;
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit:not(.-currentPageCategory) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit:not(.-currentPageCategory) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	transform: scaleX(1);
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit:not(.-active) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit:not(.-active) > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	transform: scaleX(0);
	@include sp {
	};
	@include pc {
	};
}
// オレンジの線のアニメーション（線の生成）
@keyframes GNav__siteMapUnit-orangeLine-fadein {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}
.GNav__siteMapUnit.-currentPageCategory > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit.-currentPageCategory > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before,
.GNav__siteMapUnit.-active > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit.-active > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	animation-name: GNav__siteMapUnit-orangeLine-fadein;
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit.-currentPageCategory > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit.-currentPageCategory > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	transform: scaleX(1);
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnit.-active > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapLink::before,
.GNav__siteMapUnit.-active > .GNav__siteMapUnitList.-layer1 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle::before {
	transform: scaleX(1);
	@include sp {
	};
	@include pc {
	};
}
// ---▲ グロナビのリンク（1階層目） ▲---

// ---▼ グロナビのリンク（2階層目） ▼---
// グロナビのリンクのまとまり
.GNav__siteMapUnitList.-layer2 {
	@include sp {
	};
	@include pc {
	};
}
.GNav__siteMapUnitList.-layer2 > .GNav__siteMapUnitListItem {
	@include sp {
	};
	@include pc {
	};
}
// グロナビのリンク
.GNav__siteMapUnitList.-layer2 > .GNav__siteMapUnitListItem > .GNav__siteMapLink,
.GNav__siteMapUnitList.-layer2 > .GNav__siteMapUnitListItem > .GNav__siteMapTitle {
	@include sp {
	};
	@include pc {
	};
}
// ---▲ グロナビのリンク（2階層目） ▲---

// -----▲ グロナビメニューのサイトマップ ▲-----

// -----▼ グロナビのCTA部分 ▼-----
// 「お問い合わせ（フォーム）」ページへのボタンリンク
.GNav__ctaBtn {
	@include sp {
	};
	@include pc {
	};
}
.GNav__ctaBtnContainer {
	@include sp {
	};
	@include pc {
	};
}
.GNav__contactBtn {
	@include sp {
	};
	@include pc {
	};
}

// ※「お問い合わせ」ボタンそのもののスタイルは「_button.scss」に記述。

// -----▲ グロナビのCTA部分 ▲-----

// ----------▲▲ グローバルナビゲーション gnavi ▲▲----------
