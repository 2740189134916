@charset "utf-8";

// 「カード」のスタイルのテンプレートです。


// ----------▼▼ 「カード」のスタイル ▼▼----------


// .card-mvSlider：「CDジャケット」のカード
// .card-specialFeature：「特集」のカード
// .card-news：「ニュース」のカード
// .card-mediaInfo：「メディア情報」のカード
// .card-campaignEvent：「キャンペーン＆イベント情報」のカード
// .card-youTube：「最新動画」のカード
// .card-artist：「アーティスト」のカード
// .card-newRelease：「ニューリリース」のカード
// .card-newReleaseRecommend：「ニューリリース」のカード（アーティストの作品一覧）


// -----▼ 「カード」の共通スタイル ▼-----
// カード共通のclass
.card {
	@include sp {
	};
	@include pc {
	};
}
// -----▲ 「カード」の共通スタイル ▲-----

// -----▼ 「CDジャケット」のカード ▼-----
// 「CDジャケット」カード
.card-mvSlider {
	@include sp {
	};
	@include pc {
	};
}
// 「CDジャケット」カードのリスト
.card-mvSlider-list {
	display: flex;
	@include sp {
		gap: 18px;
	};
	@include pc {
		gap: 24px;
	};
}
// 「CDジャケット」カード
.card-mvSlider-list-item {
	@include sp {
		margin-left: 18px;
	};
	@include pc {
		margin-left: 24px;
	};
}
.card-mvSlider-article {
	@include sp {
	};
	@include pc {
	};
}
// 「CDジャケット」の領域
.card-mvSlider-jacket {
	@include sp {
	};
	@include pc {
	};
}
// CDジャケット
.card-mvSlider-jacket-photo {
	@include sp {
		padding-bottom: 16px;
	};
	@include pc {
		padding-bottom: 16px;
	};
}
// CDジャケットのリンク
.card-mvSlider-jacket-photo-link {
	display: block;
	@include sp {
		height: 100%;
	};
	@include pc {
		height: 100%;
	};
}
// ホバー時の効果
.card-mvSlider-jacket-photo-link {
	@include hoverLight;
	@include sp {
	};
	@include pc {
	};
}
.card-mvSlider-jacket-photo-img {
	@include sp {
		border-radius: 12px;
		box-shadow: 0 4px 12px rgba($component-shadow, .12);
		height: 160px;
		width: 160px;
	};
	@include pc {
		border-radius: 12px;
		box-shadow: 0 4px 12px rgba($component-shadow, .12);
		height: 240px;
		width: 240px;
	};
}
// -----▲ 「CDジャケット」のカード ▲-----

// -----▼ 「特集」のカード ▼-----
// 「特集」カード
.card-specialFeature {
	@include sp {
	};
	@include pc {
	};
}
// 「特集」カードのリスト
.card-specialFeature-list {
	@include sp {
	};
	@include pc {
	};
}
// 「特集」カード
.card-specialFeature-list-item {
	@include sp {
	};
	@include pc {
	};
}
.card-specialFeature-article {
	@include sp {
	};
	@include pc {
	};
}
// リンク
.card-specialFeature-link {
	display: block;
	overflow: hidden;
	position: relative;
	@include sp {
		border-radius: 12px;
		height: 100%;
	};
	@include pc {
		border-radius: 12px;
		height: 100%;
	};
}
// ホバー時の効果
.card-specialFeature-link {
	@include hoverLight;
	@include sp {
	};
	@include pc {
	};
}
// 画像
.card-specialFeature-photo {
	@include sp {
	};
	@include pc {
	};
}
.card-specialFeature-photo-img {
	@include sp {
		height: 240px;
		width: 100%;
	};
	@include pc {
		height: 450px;
		width: 100%;
	};
}
// タイトル
.card-specialFeature-title {
	// background: linear-gradient(rgba($black_rgb, 0) 0, rgba($black_rgb, 1) 100px);
	background: linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 100px);
	color: $white;
	font-weight: bold;
	position: absolute;
	@include sp {
		bottom: 0;
		font-size: 1.4rem;
		left: 0;
		line-height: 1.5;
		padding: 14px 12px 14px;
		width: 100%;
	};
	@include pc {
		bottom: 0;
		font-size: 1.8rem;
		left: 0;
		line-height: 1.5;
		padding: 24px 24px 24px;
		width: 100%;
	};
}
// -----▲ 「特集」のカード ▲-----

// -----▼ 「ニュース」のカード ▼-----
// 「ニュース」カード
.card-news {
	@include sp {
	};
	@include pc {
	};
}
// 「ニュース」カードのリスト
.card-news-list {
	@include sp {
		border-bottom: 1px solid $gray_e4;
	};
	@include pc {
		border-bottom: 1px solid $gray_e4;
	};
}
// 「ニュース」カード
.card-news-list-item {
	@include sp {
		padding: 8px 0;
	};
	@include pc {
		padding: 6px 0;
	};
}
.card-news-list-item + .card-news-list-item {
	@include sp {
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-top: 1px solid $gray_e4;
	};
}
.card-news-article {
	@include sp {
	};
	@include pc {
	};
}
.card-news-link {
	@include sp {
		display: block;
	};
	@include pc {
		align-items: center;
		display: flex;
	};
}
// 投稿の公開時間
.card-news-post-time-data {
	color: $black_7;
	@include sp {
		font-size: 1.4rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.5;
		width: 20%;
	};
}
.card-news-post-time-data-text {
	@include sp {
	};
	@include pc {
	};
}
.card-news-post-time-data + .card-news-title {
	@include sp {
		margin-top: 4px;
	};
	@include pc {
	};
}
// 投稿のタイトル
.card-news-title {
	color: $pink;
	@include sp {
		font-size: 1.6rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.8rem;
		line-height: 1.5;
		padding-left: 8px;
		width: 80%;
	};
}

// ホバー時の効果
.card-news-article-info-link {
	display: block;
	@include sp {
		&:active {
			opacity: .7;
			.card-news-recommend-article-title {
				text-decoration: none;
			}
			.card-news-recommend-article-text {
			}
		}
	};
	@include pc {
		&:hover {
			opacity: .7;
			.card-news-recommend-article-title {
				text-decoration: none;
			}
			.card-news-recommend-article-text {
			}
		}
	};
}
// -----▲ 「ニュース」のカード ▲-----

// -----▼ 「キャンペーン＆イベント情報」のカード ▼-----
// 「キャンペーン＆イベント情報」カード
.card-mediaInfo {
	@include sp {
	};
	@include pc {
	};
}
// 「キャンペーン＆イベント情報」カードのリスト
.card-mediaInfo-list {
	@include sp {
	};
	@include pc {
		display: flex;
		flex-wrap: wrap;
		gap: 32px 24px;
	};
}
// 「キャンペーン＆イベント情報」カード
.card-mediaInfo-list-item {
	@include sp {
	};
	@include pc {
		width: flexBox(3, 24px);
	};
}
.card-mediaInfo-list-item + .card-mediaInfo-list-item {
	@include sp {
		margin-top: 24px;
	};
	@include pc {
	};
}
.card-mediaInfo-container {
	@include sp {
	};
	@include pc {
	};
}
// 「アイキャッチ画像」＋「タイトル」＋「カテゴリー」
.card-mediaInfo-header {
	display: flex;
	@include sp {
		gap: 14px;
	};
	@include pc {
		gap: 14px;
	};
}
.card-mediaInfo-thumbnail {
	@include sp {
	};
	@include pc {
	};
}
.card-mediaInfo-thum {
	@include sp {
	};
	@include pc {
	};
}
.card-mediaInfo-thum-img {
	@include sp {
		border-radius: 8px;
		height: 64px;
		width: 64px;
	};
	@include pc {
		border-radius: 8px;
		height: 64px;
		width: 64px;
	};
}
// 「タイトル」＋「カテゴリー」
.card-mediaInfo-title-and-category {
	@include sp {
		width: calc(100% - calc(64px + 14px));
	};
	@include pc {
		width: calc(100% - calc(64px + 14px));
	};
}
// タイトル
.card-mediaInfo-title {
	@include sp {
		font-size: 1.8rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.8rem;
		line-height: 1.5;
	};
}
// カテゴリー
.card-mediaInfo-title + .card-mediaInfo-category {
	@include sp {
		margin-top: 6px;
	};
	@include pc {
		margin-top: 6px;
	};
}
.card-mediaInfo-category {
	@include sp {
	};
	@include pc {
	};
}
.card-mediaInfo-header + .card-mediaInfo-body {
	@include sp {
		margin-top: 14px;
	};
	@include pc {
		margin-top: 14px;
	};
}
// 本文
.card-mediaInfo-body {
	color: $black_7;
	@include sp {
		font-size: 1.4rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.5;
	};
}
// -----▲ 「キャンペーン＆イベント情報」のカード ▲-----

// -----▼ 「メディア情報」のカード ▼-----
// 「メディア情報」カード
.card-campaignEvent {
	@include sp {
	};
	@include pc {
	};
}
// 「メディア情報」カードのリスト
.card-campaignEvent-list {
	@include sp {
		border-bottom: 1px solid $gray_e4;
	};
	@include pc {
		border-bottom: 1px solid $gray_e4;
	};
}
// 「メディア情報」カード
.card-campaignEvent-list-item {
	@include sp {
		padding: 8px 0;
	};
	@include pc {
		padding: 6px 0;
	};
}
.card-campaignEvent-list-item + .card-campaignEvent-list-item {
	@include sp {
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-top: 1px solid $gray_e4;
	};
}
.card-campaignEvent-article {
	@include sp {
	};
	@include pc {
	};
}
.card-campaignEvent-link {
	@include sp {
		display: block;
	};
	@include pc {
		align-items: center;
		display: flex;
	};
}
// 投稿の公開時間
.card-campaignEvent-post-time-data {
	color: $black_7;
	@include sp {
		font-size: 1.4rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.4rem;
		line-height: 1.5;
		width: 20%;
	};
}
.card-campaignEvent-post-time-data-text {
	@include sp {
	};
	@include pc {
	};
}
.card-campaignEvent-post-time-data + .card-campaignEvent-title {
	@include sp {
		margin-top: 4px;
	};
	@include pc {
	};
}
// 投稿のタイトル
.card-campaignEvent-title {
	color: $pink;
	@include sp {
		font-size: 1.6rem;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.8rem;
		line-height: 1.5;
		padding-left: 8px;
		width: 80%;
	};
}

// ホバー時の効果
.card-campaignEvent-article-info-link {
	display: block;
	@include sp {
		&:active {
			opacity: .7;
			.card-campaignEvent-recommend-article-title {
				text-decoration: none;
			}
			.card-campaignEvent-recommend-article-text {
			}
		}
	};
	@include pc {
		&:hover {
			opacity: .7;
			.card-campaignEvent-recommend-article-title {
				text-decoration: none;
			}
			.card-campaignEvent-recommend-article-text {
			}
		}
	};
}
// -----▲ 「メディア情報」のカード ▲-----

// -----▼ 「最新動画」のカード ▼-----
// 「最新動画」カード
.card-youTube {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-list {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-list-item {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-article {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-movie {
	@include sp {
	};
	@include pc {
	};
}
// 「最新動画」カードのリスト
.card-youTube-movie-list {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include sp {
		flex-direction: column;
		gap: 24px;
	};
	@include pc {
		gap: 24px;
	};
}
// 「最新動画」カード
.card-youTube-movie-list-item {
	@include sp {
	};
	@include pc {
		width: flexBox(3, 24px);
	};
}
.card-youTube-movie-container {
	@include sp {
	};
	@include pc {
	};
}
.card-youTube-movie-container iframe {
	@include sp {
		// aspect-ratio: 16 / 9;
		height: 100%;
		width: 100%;
	};
	@include pc {
		// aspect-ratio: 16 / 9;
		height: 100%;
		width: 100%;
	};
}
// -----▲ 「最新動画」のカード ▲-----

// -----▼ 「アーティスト」のカード ▼-----
// 「アーティスト」カード
.card-artist {
	@include sp {
	};
	@include pc {
	};
}
// 「アーティスト」カードのリスト
.card-artist-list {
	@include sp {
	};
	@include pc {
	};
}
// 「アーティスト」カード
.card-artist-list-item {
	@include sp {
	};
	@include pc {
	};
}
.card-artist-list-item + .card-artist-list-item {
	@include sp {
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-top: 1px solid $gray_e4;
	};
}
.card-artist-article {
	@include sp {
	};
	@include pc {
	};
}
.card-artist-link {
	display: block;
	@include sp {
		padding: 20px 0 20px;
	};
	@include pc {
		padding: 24px 0 24px;
	};
}
.card-artist-info {
	align-items: center;
	display: flex;
	@include sp {
		gap: 18px;
	};
	@include pc {
		gap: 24px;
	};
}
// アーティストの顔写真
.card-artist-photo {
	@include sp {
	};
	@include pc {
	};
}
.card-artist-photo-img {
	@include sp {
		border-radius: 8px;
		height: 104px;
		width: 104px;
	};
	@include pc {
		border-radius: 8px;
		height: 80px;
		width: 80px;
	};
}
// アーティスト名
.card-artist-name {
	@include sp {
		width: calc(100% - calc(104px + 16px));
	};
	@include pc {
		width: calc(100% - calc(80px + 24px));
	};
}
.card-artist-name-text {
	color: $black;
	font-weight: bold;
	@include sp {
		font-size: 1.8rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 2.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.card-artist-name-kana {
	color: $gray_7;
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// -----▲ 「アーティスト」のカード ▲-----

// -----▼ 「ニューリリース」のカード ▼-----
// 「ニューリリース」カード
.card-newRelease {
	@include sp {
	};
	@include pc {
	};
}
// 「ニューリリース」カードのリスト
.card-newRelease-list {
	@include sp {
	};
	@include pc {
	};
}
// 「ニューリリース」カード
.card-newRelease-list-item {
	@include sp {
		padding: 24px 0 28px;
	};
	@include pc {
		padding: 24px 0 28px;
	};
}
.card-newRelease-list-item + .card-newRelease-list-item {
	@include sp {
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-top: 1px solid $gray_e4;
	};
}
.card-newRelease-article {
	@include sp {
	};
	@include pc {
		display: flex;
		gap: 24px;
	};
}
// 「CDジャケット」＋「曲名」＋「アーティスト名」
.card-newRelease-jacket-and-title {
	@include sp {
		display: flex;
		gap: 16px;
	};
	@include pc {
	};
}
// 「CDジャケット」の領域
.card-newRelease-jacket {
	@include sp {
		width: 104px;
	};
	@include pc {
		width: 160px;
	};
}
// 「楽曲情報」の領域
.card-newRelease-content {
	@include sp {
	};
	@include pc {
		width: calc(100% - calc(160px + 24px));
	};
}
.card-newRelease-jacket + .card-newRelease-content {
	@include sp {
		margin-left: 16px;
	};
	@include pc {
		margin-left: 24px;
	};
}
// CDジャケット
.card-newRelease-jacket-photo {
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-jacket-photo-img {
	@include sp {
		border-radius: 8px;
		height: 104px;
		width: 104px;
	};
	@include pc {
		border-radius: 8px;
		height: 160px;
		width: 160px;
	};
}
// 「発売」＋「品番」
.card-newRelease-jacket-photo + .card-newRelease-date-and-product-number {
	@include sp {
	};
	@include pc {
		margin-top: 12px;
	};
}
.card-newRelease-date-and-product-number {
	color: $gray_7;
	@include sp {
	};
	@include pc {
	};
}
// 発売
.card-newRelease-date {
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV75;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV75;
		line-height: 1.5;
	};
}
// 品番
.card-newRelease-date + .card-newRelease-product-number {
	@include sp {
		margin-top: 4px;
	};
	@include pc {
		margin-top: 4px;
	};
}
.card-newRelease-product-number {
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV75;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV75;
		line-height: 1.5;
	};
}
// 「曲名」＋「アーティスト名」＋「価格」
.card-newRelease-title-container {
	@include sp {
		width: calc(100% - calc(104px + 16px));
	};
	@include pc {
		align-items: center;
		display: flex;
		gap: 0 12px;
		justify-content: space-between;
	};
}
// 「発売」＋「品番」＋「価格」
.card-newRelease-title-container + .card-newRelease-date-and-product-container {
	@include sp {
		margin-top: 16px;
	};
	@include pc {
	};
}
.card-newRelease-date-and-product-container {
	@include sp {
		align-items: center;
		display: flex;
		justify-content: space-between;
	};
	@include pc {
	};
}
// 「曲名」＋「アーティスト名」
.card-newRelease-title-and-artist {
	@include sp {
	};
	@include pc {
	};
}
// 曲名
.card-newRelease-title {
	font-weight: bold;
	@include sp {
		font-size: 2.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
		padding-top: 12px;
	};
	@include pc {
		font-size: 2.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// アーティスト名
.card-newRelease-title + .card-newRelease-artist-name-link {
	@include sp {
		margin-top: 2px;
	};
	@include pc {
		margin-top: 2px;
	};
}
.card-newRelease-artist-name-link {
	display: inline-block;
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-artist-name-text	{
	color: $pink;
	display: inline-block;
	@include sp {
		font-size: 1.8rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.8rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// 価格
.card-newRelease-price {
	background-color: $pink;
	@include sp {
		border-radius: 4px;
		height: max-content;
		padding: 8px 16px;
	};
	@include pc {
		border-radius: 4px;
		height: max-content;
		padding: 8px 16px;
		min-width: max-content;
	};
}
.card-newRelease-price-text {
	color: $white;
	@include sp {
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.card-newRelease-price-text.-yen {
	@include sp {
		font-size: 1.6rem;
	};
	@include pc {
		font-size: 1.6rem;
	};
}
.card-newRelease-price-text.-price {
	@include sp {
		font-size: 1.6rem;
	};
	@include pc {
		font-size: 1.6rem;
	};
}
.card-newRelease-price-text.-tax {
	@include sp {
		font-size: 1.0rem;
	};
	@include pc {
		font-size: 1.0rem;
	};
}
// 収録曲
.card-newRelease-date-and-product-container + .card-newRelease-recorded-music {
	@include sp {
		margin-top: 12px;
	};
	@include pc {
		margin-top: 12px;
	};
}
.card-newRelease-recorded-music {
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-recorded-music-list {
	counter-reset: card-newRelease-recorded-music-list 0;
	@include sp {
		border-bottom: 1px solid $gray_e4;
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-bottom: 1px solid $gray_e4;
		border-top: 1px solid $gray_e4;
	};
}
.card-newRelease-recorded-music-list-item {
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-recorded-music-list-item + .card-newRelease-recorded-music-list-item {
	@include sp {
		border-top: 1px solid $gray_e4;
	};
	@include pc {
		border-top: 1px solid $gray_e4;
	};
}
.card-newRelease-recorded-music-info {
	align-items: center;
	display: flex;
	position: relative;
	@include sp {
		padding: 8px 0;
	};
	@include pc {
		padding: 8px 0;
	};
}
// 「曲のデータ」の領域
.new-release-recorded-music-song-data-container {
	position: absolute;
	@include sp {
		font-size: 0;

	};
	@include pc {
		font-size: 0;
		left: 0;
		top: calc(50% - calc(24px / 2));
	};
}
// 「曲名」の領域
.new-release-recorded-music-song-data-container + .new-release-recorded-music-song-title-container {
	@include sp {
		padding-left: 32px;
	};
	@include pc {
		padding-left: 32px;
	};
}
.new-release-recorded-music-song-title-container {
	@include sp {
	};
	@include pc {
	};
}
// 曲のデータと再生ボタン
.card-newRelease-recorded-music-song-data {
	@include sp {
	};
	@include pc {
	};
}
// 再生ボタン
.card-newRelease-recorded-music-song-play-btn {
	background-color: $white;
	@include sp {
		border: 1px solid $gray_c;
		border-radius: 50%;
		height: 24px;
		width: 24px;
	};
	@include pc {
		border: 1px solid $gray_c;
		border-radius: 50%;
		cursor: pointer;
		height: 24px;
		width: 24px;
	};
}
// 再生ボタンのアイコン（デフォルト）
.card-newRelease-recorded-music-song-play-btn.icon::after {
	background-color: $gray_8;
	clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
	@include sp {
		height: 10px;
		left: calc(50% - 3px);
		top: calc(50% - 5px);
		width: 8px;
	};
	@include pc {
		height: 10px;
		left: calc(50% - 3px);
		top: calc(50% - 5px);
		width: 8px;
	};
}
// 再生ボタンのアイコン（再生時）
.card-newRelease-recorded-music-song-play-btn.icon.-play::after {
	clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
	@include sp {
		height: 10px;
		left: calc(50% - 3px);
		top: calc(50% - 5px);
		width: 8px;
	};
	@include pc {
		height: 10px;
		left: calc(50% - 3px);
		top: calc(50% - 5px);
		width: 8px;
	};
}
// 再生ボタンのアイコン（一時停止時）
.card-newRelease-recorded-music-song-play-btn.icon.-paused::after {
	clip-path: none;
	@include sp {
		height: 8px;
		left: calc(50% - 4px);
		top: calc(50% - 4px);
		width: 8px;
	};
	@include pc {
		height: 8px;
		left: calc(50% - 4px);
		top: calc(50% - 4px);
		width: 8px;
	};
}
// 曲名
.card-newRelease-recorded-music-song-title {
	font-weight: bold;
	@include sp {
		font-size: 1.4rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.4rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.card-newRelease-recorded-music-song-title::before {
	content: counter(card-newRelease-recorded-music-list, decimal-leading-zero) ".";
	counter-increment: card-newRelease-recorded-music-list;
	@include sp {
		letter-spacing: inherit;
		padding-right: 2px;
	};
	@include pc {
		letter-spacing: inherit;
		padding-right: 2px;
	};
}
// 再生時間
.card-newRelease-recorded-music-tplayback-time + .card-newRelease-recorded-music-song-title {
	@include sp {
		margin-top: 2px;
	};
	@include pc {
		margin-top: 2px;
	};
}
.card-newRelease-recorded-music-tplayback-time {
	color: $gray_7;
	@include sp {
		font-size: 1.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.0rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// 販売元・配信元
.card-newRelease-recorded-music + .card-newRelease-buy {
	@include sp {
		margin-top: 16px;
	};
	@include pc {
		margin-top: 16px;
	};
}
.card-newRelease-buy {
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-buy-list {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		gap: 16px 16px;
	};
	@include pc {
		gap: 16px 16px;
	};
}
.card-newRelease-buy-list-item {
	@include sp {
	};
	@include pc {
	};
}
.card-newRelease-buy-link-btn {
	overflow: hidden;
	@include sp {
		border-radius: 8px;
		height: 40px;
		padding: 0;
		width: 40px;
	};
	@include pc {
		border-radius: 8px;
		height: 40px;
		padding: 0;
		width: 40px;
	};
}
.card-newRelease-buy-link-btn-img {
	@include sp {
	};
	@include pc {
	};
}
// iTunes、Spotify
.btn-itunes,
.btn-spotify {
	@include sp {
		border-radius: 50%;
	};
	@include pc {
		border-radius: 50%;
	};
}
// -----▲ 「ニューリリース」のカード ▲-----

// -----▼ 「ニューリリース」のカード（アーティストの作品一覧） ▼-----
// 「ニューリリース」カード
.card-newReleaseRecommend {
	@include sp {
	};
	@include pc {
	};
}
// 「ニューリリース」カードのリスト
.card-newReleaseRecommend-list {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		gap: 24px 32px;
	};
	@include pc {
		gap: 24px 32px;
	};
}
// 「ニューリリース」カード
.card-newReleaseRecommend-list-item {
	@include sp {
	};
	@include pc {
		width: flexBox(5, 24px);
	};
}
.card-newReleaseRecommend-article {
	@include sp {
	};
	@include pc {
	};
}
// 「CDジャケット」＋「曲名」＋「アーティスト名」
.card-newReleaseRecommend-jacket-and-title {
	@include sp {
		align-items: center;
		display: flex;
		gap: 16px;
	};
	@include pc {
	};
}
// 「CDジャケット」の領域
.card-newReleaseRecommend-jacket {
	@include sp {
	};
	@include pc {
	};
}
.card-newReleaseRecommend-jacket-photo {
	@include sp {
	};
	@include pc {
	};
}
.card-newReleaseRecommend-jacket-photo-img {
	@include sp {
		border-radius: 8px;
		height: 104px;
		width: 104px;
	};
	@include pc {
		border-radius: 8px;
	};
}
// 「曲名」＋「アーティスト名」
.card-newReleaseRecommend-jacket + .card-newReleaseRecommend-title-container {
	@include sp {
	};
	@include pc {
		margin-top: 10px;
	};
}
.card-newReleaseRecommend-title-container {
	@include sp {
		width: calc(100% - calc(104px + 16px));
	};
	@include pc {
	};
}
.card-newReleaseRecommend-title {
	color: $black;
	font-weight: bold;
	@include sp {
		font-size: 1.5rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.5rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
.card-newReleaseRecommend-title + .card-newReleaseRecommend-artist-name-text {
	@include sp {
		margin-top: 2px;
	};
	@include pc {
		margin-top: 2px;
	};
}
.card-newReleaseRecommend-artist-name-text {
	color: $black;
	@include sp {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
	@include pc {
		font-size: 1.2rem;
		letter-spacing: $lsAV50;
		line-height: 1.5;
	};
}
// -----▲ 「ニューリリース」のカード（アーティストの作品一覧） ▲-----

// ---------▲▲ 「カード」のスタイル ▲▲----------
